import { ButtonProps, Flex } from "@radix-ui/themes";
import { FormApi, ReactFormApi } from "@tanstack/react-form";
import { CancelButton } from "./CancelButton";
import { FormError } from "./FormError";
import { SubmitButton } from "./SubmitButton";

export const Footer = ({
  form,
  error,
  isPending,
  submitButtonProps,
  buttonText = "Submit",
  errorPrefix = "Error: ",
}: {
  form: FormApi<any, any> & ReactFormApi<any, any>;
  error: Error | null | undefined;
  isPending: boolean;
  submitButtonProps?: ButtonProps;
  buttonText?: string;
  errorPrefix?: string;
}) => {
  return (
    <form.Subscribe
      selector={(state) => ({
        canSubmit: state.canSubmit,
        isSubmitting: state.isSubmitting,
      })}
      children={({ canSubmit, isSubmitting }) => (
        <>
          {error && <FormError message={`${errorPrefix}${error.message}`} />}
          <Flex gap="3" justify="end">
            <CancelButton form={form} />
            <SubmitButton
              onClick={form.handleSubmit}
              canSubmit={canSubmit}
              isSubmitting={isPending || isSubmitting}
              {...submitButtonProps}
            >
              {buttonText}
            </SubmitButton>
          </Flex>
        </>
      )}
    />
  );
};
