import { useWorkflows } from "@/state/queries/workflows";
import { Action, ActionOperation, ActionOperationType } from "@/types/actions";
import { WorkflowNodeContent } from "@/types/workflows";
import { useMemo } from "react";

export interface ExecutableAction extends Action {
  workflowId: string;
}

export interface UseActionTypes {
  recordTypeId: string;
  status?: string;
  operations: ActionOperationType[];
}
export const useActionTypes = (params: UseActionTypes) => {
  const { data: workflows } = useWorkflows();

  const actionTypes: { [id: string]: ExecutableAction } = useMemo(() => {
    if (!workflows) {
      return {};
    }

    let allActions: { [id: string]: ExecutableAction } = {};
    workflows.forEach((wf) => {
      for (const node of Object.values(wf.workflowDefinition.nodes)) {
        if (WorkflowNodeContent.isAction(node.content)) {
          const action = node.content;
          allActions[action.action.id] = {
            ...action.action,
            workflowId: wf.id,
          };
        }
      }
    });

    return allActions;
  }, [workflows]);

  return Object.values(actionTypes).filter(
    (at) =>
      at.recordTypeId === params.recordTypeId &&
      params.operations.some((operation) =>
        actionMatchesOperationAndStatus(at, operation, params.status)
      )
  );
};

function actionMatchesOperationAndStatus(
  action: ExecutableAction,
  operation: ActionOperationType,
  status?: string
) {
  if (operation === "create") {
    return ActionOperation.isCreate(action.operation);
  } else if (operation === "update") {
    return (
      ActionOperation.isUpdate(action.operation) &&
      (action.operation.update.startStatuses?.includes(status ?? "") ||
        action.operation.update.startStatus === status)
    );
  } else if (operation === "delete") {
    return (
      ActionOperation.isDelete(action.operation) &&
      (action.operation.delete.startStatuses?.includes(status ?? "") ||
        action.operation.delete.startStatus === status)
    );
  }
  return false;
}
