import { THEME_COLOR } from "@/theme";

export interface Status {
  id: string;
  value: string;
  display?: StatusDisplay;
}

export type StatusIconType =
  | "dashed"
  | "circle"
  | "circleFilled"
  | "dashedCheck"
  | "check"
  | "checkFilled"
  | "dashedCross"
  | "cross"
  | "crossFilled"
  | "dashedMinus"
  | "alert"
  | "alertFilled";

export type StatusColorType =
  | "gray"
  | "primary"
  | "blue"
  | "green"
  | "orange"
  | "red";

export interface StatusDisplay {
  icon: StatusIconType;
  color: StatusColorType;
}

const statusColorTypeToRadixType: Record<
  StatusColorType,
  "gray" | "iris" | "cyan" | "grass" | "orange" | "tomato"
> = {
  gray: "gray",
  primary: THEME_COLOR,
  blue: "cyan",
  green: "grass",
  orange: "orange",
  red: "tomato",
};
export const toRadixColor = (color?: StatusColorType) => {
  return color ? statusColorTypeToRadixType[color] : THEME_COLOR;
};
