import { useRecordTypeById } from "@/state/queries/recordLinkTypes";
import { useRecords } from "@/state/queries/records";
import { RecordFieldValues } from "@/types/records";
import { Flex, Select, Text } from "@radix-ui/themes";
import { DeepKeys, useField } from "@tanstack/react-form";
import { PropsWithChildren } from "react";
import { FormField } from "../FormField";
import { hasError } from "../utils";
import { BaseSelectProps } from "./BaseSelect";
import "./RecordSelect.css";
export interface RecordSelectProps<TData> extends BaseSelectProps<TData> {
  recordTypeId: string;
}

export function RecordSelect<TData>(
  props: PropsWithChildren<RecordSelectProps<TData>>
) {
  const { reactFormField, fieldProps, rootProps, triggerProps } = props;

  const field = useField<TData, DeepKeys<TData>>(reactFormField);
  const { data: recordType } = useRecordTypeById(props.recordTypeId);
  const { data: records } = useRecords(props.recordTypeId);

  const titleFieldId = recordType?.titleFieldId;

  const getRecordLabel = (record: RecordFieldValues) => {
    if (titleFieldId) {
      return (
        <>
          <Text truncate style={{ maxWidth: 400 }}>
            {record[titleFieldId]}
          </Text>
          <Text className="record-select-record-id" size="2">
            ({record.id})
          </Text>
        </>
      );
    }
    return record.id;
  };

  const submissionAttempts = reactFormField.form.useStore(
    (form) => form.submissionAttempts
  );
  const error = hasError(field.state.meta, submissionAttempts);
  return (
    <FormField
      reactForm={reactFormField.form}
      reactFormField={field}
      {...fieldProps}
    >
      <Select.Root
        name={field.name as string}
        value={field.state.value as string}
        onValueChange={(value) => {
          field.handleChange(value as any);
        }}
        size={fieldProps.size ?? "3"}
        {...rootProps}
      >
        <Select.Trigger
          style={{
            boxShadow: error ? "inset 0 0 0 2px var(--red-a7)" : undefined,
            width: "100%",
          }}
          {...triggerProps}
        />
        <Select.Content>
          {records?.map((record) => (
            <Select.Item key={record.id} value={record.id}>
              <Flex align="center" gap="2">
                {getRecordLabel(record)}
              </Flex>
            </Select.Item>
          ))}
        </Select.Content>
      </Select.Root>
    </FormField>
  );
}
