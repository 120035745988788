import { useTenantContext } from "@/contexts/TenantContext";
import { supabase } from "@/supabaseClient";
import { WorkflowDefinition } from "@/types/workflows";
import { queryOptions, useQuery } from "@tanstack/react-query";

export interface Workflow {
  id: string;
  tenantId: string;
  processId: string;
  primaryRecordTypeId: string;
  name: string;
  description: string | undefined;
  createdAt: string;
  createdBy: string;
  updatedAt: string;
  updatedBy: string;
  workflowDefinition: WorkflowDefinition;
}

const fetchWorkflows = async (tenantId: string): Promise<Workflow[]> => {
  const { data, error } = await supabase
    .from("workflows")
    .select("*")
    .eq("tenant_id", tenantId);

  if (error) {
    throw error;
  }

  if (!data) {
    return [];
  }

  return data.map((workflow) => ({
    id: workflow.id,
    tenantId: workflow.tenant_id,
    processId: workflow.process_id,
    primaryRecordTypeId: workflow.primary_record_type_id,
    name: workflow.name,
    description: workflow.description ?? undefined,
    createdAt: workflow.created_at,
    createdBy: workflow.created_by,
    updatedAt: workflow.updated_at,
    updatedBy: workflow.updated_by,
    // TODO Validate this
    workflowDefinition: workflow.workflow as unknown as WorkflowDefinition,
  }));
};

export const workflowsQueryOptions = (tenantId: string) =>
  queryOptions({
    queryKey: ["workflows", tenantId],
    queryFn: () => fetchWorkflows(tenantId),
    staleTime: 1000 * 60 * 5,
    retry: false,
  });

export const useWorkflows = () => {
  const tenant = useTenantContext();
  if (!tenant.tenant) {
    throw new Error("Tenant not found");
  }
  return useQuery(workflowsQueryOptions(tenant.tenant.tenantId));
};

export const useWorkflow = (workflowId: string) => {
  const { data: workflows, ...rest } = useWorkflows();

  return {
    ...rest,
    data: workflows?.find((workflow) => workflow.id === workflowId),
  };
};

export const useWorkflowByProcess = (processId: string) => {
  const { data: workflows, ...rest } = useWorkflows();
  return {
    ...rest,
    data: workflows?.filter((workflow) => workflow.processId === processId),
  };
};
