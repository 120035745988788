/* eslint-disable @typescript-eslint/no-redeclare */

import { KeyedTypeUnion, createTypeUnion } from "keyed-type-union";
import { ActionFormField } from "./actionFormField";

/**
 * Action permissions
 */
export type ActionPermissions = KeyedTypeUnion<{
  noFilter: {};
  userFilter: {
    userIds: string[];
  };
  groupFilter: {
    groupIds: string[];
  };
}>;
export type ActionPermissionsType = ActionPermissions["type"];
export const ActionPermissions = createTypeUnion<ActionPermissions>({
  noFilter: undefined,
  userFilter: undefined,
  groupFilter: undefined,
});

export interface ActionFormConfig {
  fields: ActionFormField[];
}

export type ActionOperation = KeyedTypeUnion<{
  create: {
    endStatus?: string;
  };
  update: {
    startStatuses?: string[];
    endStatus?: string;

    /**
     * @deprecated Use startStatuses instead. Keeping this around for backwards compatibility.
     */
    startStatus?: string;
  };
  delete: {
    startStatuses?: string[];

    /**
     * @deprecated Use startStatuses instead. Keeping this around for backwards compatibility.
     */
    startStatus?: string;
  };
}>;
export type ActionOperationType = ActionOperation["type"];
export const ActionOperation = createTypeUnion<ActionOperation>({
  create: undefined,
  update: undefined,
  delete: undefined,
});

export interface Action {
  id: string;
  name: string;
  description?: string;

  recordTypeId: string;
  operation: ActionOperation;

  form: ActionFormConfig;

  permissions: ActionPermissions;
}

// Note: Advanced actions - allow executing over multiple records and record types
//   - One field to indicate which records to act on
//     - Still based on recordTypeId + operation?
//   - Action fields are not mapped to record fields
//   - New "effects" array maps from action fields to effects
//     - recordTypeId + operation is defined in an effect
//   - How do we pass in the record type + record IDs?
//     - Option 1: Require fields in the action form for this. Pre-populate with selected records in FE
//     - Option 2: Pass in as part of the mutation
