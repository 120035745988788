import { useTenantContext } from "@/contexts/TenantContext";
import { supabase } from "@/supabaseClient";
import { useMutation, useQueryClient } from "@tanstack/react-query";

type DeleteViewInput = {
  id: string;
  tenantId: string;
};

const deleteView = async (input: DeleteViewInput) => {
  const { error } = await supabase
    .from("record_views")
    .delete()
    .eq("id", input.id)
    .eq("tenant_id", input.tenantId);

  if (error) {
    throw error;
  }
};

export const useDeleteView = () => {
  const queryClient = useQueryClient();
  const tenant = useTenantContext();

  return useMutation({
    mutationFn: (input: Pick<DeleteViewInput, "id">) => {
      if (!tenant.tenant) {
        throw new Error("Tenant not found");
      }
      return deleteView({ id: input.id, tenantId: tenant.tenant.tenantId });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["recordViews", tenant.tenant?.tenantId],
      });
    },
  });
};
