import { XYPosition } from "@xyflow/react";
import { KeyedTypeUnion, createTypeUnion } from "keyed-type-union";
import { Action } from "./actions";
import { Status } from "./status";

export interface WorkflowDefinition {
  nodes: { [id: string]: WorkflowNode };
  edges: { [id: string]: WorkflowEdge };
}

export type WorkflowNodeContent = KeyedTypeUnion<{
  status: Status;
  action: Action;
}>;

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const WorkflowNodeContent = createTypeUnion<WorkflowNodeContent>({
  status: undefined,
  action: undefined,
});

export interface WorkflowNode {
  id: string;
  position: XYPosition;
  content: WorkflowNodeContent;
}

export interface WorkflowEdge {
  id: string;
  source: string;
  target: string;
}
