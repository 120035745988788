import { THEME_COLOR } from "@/theme";
import { Badge } from "@radix-ui/themes";

export interface DefaultStatusBadgeProps {
  status: string;
  color?: string;
}

/**
 * Generic badge that maps common status names to colors. Should eventually
 * be superseded by custom statuses for most use cases, but useful for built-in
 * concepts like documents and processes.
 */
export const DefaultStatusBadge: React.FC<DefaultStatusBadgeProps> = ({
  status,
  color,
}) => {
  if (!color) {
    if (status === "Approved" || status === "Released" || status === "Active") {
      color = "green";
    } else if (status === "Pending review" || status === "In review") {
      color = THEME_COLOR;
    } else {
      color = "gray";
    }
  }

  return (
    <Badge color={(color as any) ?? THEME_COLOR} size="2" radius="large">
      {status}
    </Badge>
  );
};
