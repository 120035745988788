import { Flex, FlexProps } from "@radix-ui/themes";
import { PropsWithChildren } from "react";

export const FormFields: React.FC<PropsWithChildren<FlexProps>> = ({
  children,
  ...props
}) => {
  return (
    <Flex direction="column" gap="2" {...props}>
      {children}
    </Flex>
  );
};
