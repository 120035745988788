import { DefaultStatusBadge } from "@/components/DefaultStatusBadge";
import DetailCard from "@/components/DetailCard";
import { DocumentPreview } from "@/components/documents/DocumentPreview";
import { EditDocumentMetadataAction } from "@/components/documents/EditDocumentMetadataAction";
import { PermissionedButton } from "@/components/PermissionedButton";
import TabTitle from "@/components/TabTitle";
import User from "@/components/User";
import { useDocumentFile } from "@/state/queries/documentFile";
import { Document, useDocument } from "@/state/queries/documents";
import {
  Code,
  DataList,
  Dialog,
  Grid,
  IconButton,
  ScrollArea,
  Text,
  Tooltip,
} from "@radix-ui/themes";
import {
  IconArrowsMaximize,
  IconDownload,
  IconEye,
  IconId,
  IconPencil,
} from "@tabler/icons-react";
import { createFileRoute, Link } from "@tanstack/react-router";
import { maxBy } from "lodash-es";
import { useState } from "react";
export const Route = createFileRoute(
  "/_app/$tenantSlug/documents/$documentId/"
)({
  // Empty because the parent route sets the title
  beforeLoad: () => ({
    getTitle: () => "",
  }),
  component: DocumentOverview,
});

function DocumentOverview() {
  const { tenantSlug, documentId } = Route.useParams();
  const { data: document, isLoading } = useDocument(documentId);
  const [isFullScreenPreviewOpen, setIsFullScreenPreviewOpen] = useState(false);

  const currentRevision =
    !isLoading && document ? getCurrentRevision(document) : undefined;
  const { data: documentFileUrl } = useDocumentFile(currentRevision?.filePath);

  const fullScreenPreviewDialog = (
    <Dialog.Root
      open={isFullScreenPreviewOpen}
      onOpenChange={setIsFullScreenPreviewOpen}
    >
      <Dialog.Trigger>
        <Tooltip content="Maximize preview">
          <IconButton
            color="gray"
            variant="ghost"
            aria-label="Maximize preview"
            onClick={() => setIsFullScreenPreviewOpen(true)}
          >
            <IconArrowsMaximize />
          </IconButton>
        </Tooltip>
      </Dialog.Trigger>
      <Dialog.Content
        size="2"
        style={{
          maxWidth: "850px",
          height: "80vh",
        }}
      >
        <Dialog.Title>Document Preview</Dialog.Title>
        <ScrollArea style={{ height: "70vh" }}>
          <DocumentPreview documentRevision={currentRevision} width={800} />
        </ScrollArea>
      </Dialog.Content>
    </Dialog.Root>
  );

  return (
    <>
      <TabTitle title="Overview" />
      <Grid columns="2" gap="4" maxWidth="900px">
        <DetailCard
          title="Basic Information"
          icon={<IconId />}
          actions={
            document && (
              <EditDocumentMetadataAction
                document={document}
                enableFileEdit={false}
              >
                <PermissionedButton
                  permission="write.documents"
                  variant="ghost"
                  color="gray"
                  iconButton
                >
                  <IconPencil />
                </PermissionedButton>
              </EditDocumentMetadataAction>
            )
          }
        >
          <DataList.Root>
            <DataList.Item>
              <DataList.Label>Identifier</DataList.Label>
              <DataList.Value>
                <Code>{document?.id}</Code>
              </DataList.Value>
            </DataList.Item>
            <DataList.Item>
              <DataList.Label>Document Title</DataList.Label>
              <DataList.Value>{document?.title}</DataList.Value>
            </DataList.Item>
            <DataList.Item>
              <DataList.Label>Status</DataList.Label>
              <DataList.Value>
                <DefaultStatusBadge status={document?.status ?? ""} />
              </DataList.Value>
            </DataList.Item>
            <DataList.Item>
              <DataList.Label>Type</DataList.Label>
              <DataList.Value>{document?.type}</DataList.Value>
            </DataList.Item>
            <DataList.Item>
              <DataList.Label>Owner</DataList.Label>
              <DataList.Value>
                {document?.owner ? (
                  <User userId={document.owner} />
                ) : (
                  <Text color="gray">No owner</Text>
                )}
              </DataList.Value>
            </DataList.Item>
            <DataList.Item>
              <DataList.Label>Parent Process</DataList.Label>
              <DataList.Value>
                <Link to={`/${tenantSlug}/processes/${document?.processId}`}>
                  {document?.processName}
                </Link>
              </DataList.Value>
            </DataList.Item>
          </DataList.Root>
        </DetailCard>
        <DetailCard
          title="Preview"
          icon={<IconEye />}
          actions={
            <>
              <Tooltip content="Download document">
                <IconButton
                  color="gray"
                  variant="ghost"
                  aria-label="Download document"
                  onClick={() => {
                    window.open(documentFileUrl, "_blank");
                  }}
                >
                  <IconDownload />
                </IconButton>
              </Tooltip>
              {fullScreenPreviewDialog}
            </>
          }
        >
          <ScrollArea style={{ height: "400px" }}>
            <DocumentPreview documentRevision={currentRevision} width={400} />
          </ScrollArea>
        </DetailCard>
      </Grid>
    </>
  );
}

const getCurrentRevision = (document: Document) => {
  const releasedRevisions = document.revisions.filter(
    (revision) => revision.status === "Released"
  );
  const latestReleasedRevision = maxBy(
    releasedRevisions,
    (revision) => revision.revisionNumber ?? -1
  );
  if (latestReleasedRevision) {
    return latestReleasedRevision;
  }
  return maxBy(document.revisions, "updatedAt");
};
