import { Badge, ContextMenu, Flex, Text } from "@radix-ui/themes";
import { Handle, Node, NodeProps, Position } from "@xyflow/react";
import { CSSProperties, PropsWithChildren } from "react";
import { useWorkflowEditorContext } from "../WorkflowEditorContext";

export interface BaseNodeProps<NodeType extends Node>
  extends NodeProps<NodeType> {
  baseNodeProps: {
    title: string;
    icon?: React.ReactNode;
    color?: string;
    backgroundColor?: string;
    borderColor?: string;
    shadowColor?: string;
    hideTopHandle?: boolean;
    hideBottomHandle?: boolean;
  };
}

export default function BaseNode<NodeType extends Node>({
  id,
  selected,
  selectable,
  isConnectable,
  baseNodeProps,
  children,
}: PropsWithChildren<BaseNodeProps<NodeType>>) {
  const { startEditingNode, deleteNodes } = useWorkflowEditorContext();
  const color = baseNodeProps.color ?? "inherit";
  const borderColor = selected
    ? (baseNodeProps.borderColor ?? "var(--accent-9)")
    : "var(--gray-6)";
  const shadowColor1 = baseNodeProps.shadowColor ?? "var(--accent-10)";
  const shadowColor2 = baseNodeProps.shadowColor ?? "var(--accent-a6)";
  const boxShadow = selected
    ? `0 0 0 1px ${shadowColor1}, 0 0 1px 2px ${shadowColor2}`
    : "none";
  const nodeStyle: CSSProperties = {
    color,
    borderRadius: "var(--radius-3)",
    backgroundColor:
      baseNodeProps.backgroundColor ?? "var(--color-panel-solid)",
    border: `1px solid ${borderColor}`,
    boxShadow,
  };
  const handleStyle: CSSProperties = {
    zIndex: 1,
    backgroundColor: "var(--accent-3)",
    border: "1px solid var(--accent-9)",
    width: isConnectable ? "var(--space-4)" : "0px",
    height: isConnectable ? "var(--space-2)" : "0px",
    visibility: selectable ? "visible" : "hidden",
    borderRadius: "var(--radius-1)",
  };

  return (
    <>
      <ContextMenu.Root>
        <ContextMenu.Trigger>
          <Flex direction="column" width="240px" p="1" style={nodeStyle}>
            <Flex direction="row" align="center" justify="between" p="1">
              <Flex direction="row" align="center" gap="2">
                <Badge variant="soft" style={{ padding: "2px", color }}>
                  {baseNodeProps.icon}
                </Badge>
                <Text size="2" weight="bold">
                  {baseNodeProps.title}
                </Text>
              </Flex>
            </Flex>

            {children}

            {!baseNodeProps.hideTopHandle && (
              <Handle
                type="target"
                position={Position.Top}
                style={handleStyle}
              />
            )}
            {!baseNodeProps.hideBottomHandle && (
              <Handle
                type="source"
                position={Position.Bottom}
                style={handleStyle}
              />
            )}
          </Flex>
        </ContextMenu.Trigger>
        <ContextMenu.Content>
          <ContextMenu.Item shortcut="⏎" onClick={() => startEditingNode(id)}>
            Edit
          </ContextMenu.Item>
          <ContextMenu.Item
            shortcut="⌫"
            color="red"
            onClick={() => deleteNodes([id])}
          >
            Delete
          </ContextMenu.Item>
        </ContextMenu.Content>
      </ContextMenu.Root>
    </>
  );
}
