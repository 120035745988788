import EmptyPage from '@/components/EmptyPage'
import { PermissionedButton } from '@/components/PermissionedButton'
import {
  CreateOrUpdateRecordTypeAction,
  RecordTypeDialogOperation,
} from '@/components/record-types/CreateOrUpdateRecordTypeAction'
import TabTitle from '@/components/TabTitle'
import { useProcess } from '@/state/queries/processes'
import { useRecordTypeById } from '@/state/queries/recordLinkTypes'
import { DataList } from "@radix-ui/themes";
import { IconFile, IconPencil } from '@tabler/icons-react'
import { createFileRoute, Link } from '@tanstack/react-router'

export const Route = createFileRoute(
  '/_app/$tenantSlug/settings/organization/record-types/$recordTypeId/general',
)({
  beforeLoad: () => ({ getTitle: () => '' }),
  component: RecordTypeIndex,
})

function RecordTypeIndex() {
  const { recordTypeId } = Route.useParams()
  const { data: recordType } = useRecordTypeById(recordTypeId)

  if (!recordType) {
    return <EmptyPage icon={<IconFile />} title="Record type not found" />
  }

  return (
    <>
      <TabTitle
        title="General"
        description="Manage general information for this record type"
      >
        <CreateOrUpdateRecordTypeAction
          operation={RecordTypeDialogOperation.update({
            recordType,
          })}
        >
          <PermissionedButton permission="write.processes" variant="soft">
            <IconPencil />
            Update info
          </PermissionedButton>
        </CreateOrUpdateRecordTypeAction>
      </TabTitle>
      <RecordTypeInputs />
    </>
  );
}

function RecordTypeInputs() {
  const { tenantSlug, recordTypeId } = Route.useParams()
  const { data: recordType } = useRecordTypeById(recordTypeId)
  const { data: parentProcess } = useProcess(recordType?.processId ?? '')

  return (
    <DataList.Root size="3">
      <DataList.Item>
        <DataList.Label>ID</DataList.Label>
        <DataList.Value>{recordType?.id}</DataList.Value>
      </DataList.Item>
      <DataList.Item>
        <DataList.Label>Key</DataList.Label>
        <DataList.Value>{recordType?.key}</DataList.Value>
      </DataList.Item>
      <DataList.Item>
        <DataList.Label>Plural Name</DataList.Label>
        <DataList.Value>{recordType?.pluralName}</DataList.Value>
      </DataList.Item>
      <DataList.Item>
        <DataList.Label>Singular Name</DataList.Label>
        <DataList.Value>{recordType?.name}</DataList.Value>
      </DataList.Item>
      <DataList.Item>
        <DataList.Label>Description</DataList.Label>
        <DataList.Value>{recordType?.description}</DataList.Value>
      </DataList.Item>
      <DataList.Item>
        <DataList.Label>Parent Process</DataList.Label>
        <DataList.Value>
          <Link
            to="/$tenantSlug/processes/$processId"
            params={{ tenantSlug, processId: parentProcess?.id ?? '' }}
          >
            {parentProcess?.name}
          </Link>
        </DataList.Value>
      </DataList.Item>
    </DataList.Root>
  )
}
