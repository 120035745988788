import { useTenantContext } from "@/contexts/TenantContext";
import { supabase } from "@/supabaseClient";
import { queryOptions, useQuery } from "@tanstack/react-query";
import { keyBy } from "lodash-es";

export interface TenantUser {
  userId: string;
  email: string;
  role: string;
  fullName?: string;
}

const fetchTenantUsers = async (
  tenantId: string
): Promise<Record<string, TenantUser>> => {
  const { data, error } = await supabase
    .schema("tenants")
    .from("user_profiles")
    .select("id, email, full_name, tenant_user_roles(role)")
    .eq("tenant_id", tenantId);

  if (error) {
    throw error;
  }

  if (!data) {
    return {};
  }

  const users = data.map((user) => ({
    userId: user.id ?? "",
    email: user.email ?? "",
    role: user.tenant_user_roles?.role ?? "",
    fullName: user.full_name ?? undefined,
  }));

  return keyBy(users, "userId");
};

export const tenantUsersQueryOptions = (tenantId: string) =>
  queryOptions({
    queryKey: ["tenantUsers", tenantId],
    queryFn: () => fetchTenantUsers(tenantId),
    staleTime: 1000 * 60 * 5, // 5 minutes
    retry: 3,
  });

export const useTenantUsers = () => {
  const tenant = useTenantContext();
  if (!tenant.tenant) {
    throw new Error("Tenant not found");
  }
  return useQuery(tenantUsersQueryOptions(tenant.tenant.tenantId));
};

export const useTenantUser = (userId: string) => {
  const { data: tenantUsers, ...rest } = useTenantUsers();
  return {
    ...rest,
    data: tenantUsers?.[userId],
  };
};
