import { CreateDocumentAction } from "@/components/documents/CreateDocumentAction";
import { DeleteDocumentAction } from "@/components/documents/DeleteDocumentAction";
import PageTitle from "@/components/PageTitle";
import { SearchFilter } from "@/components/SearchFilter";
import { BaseAgGrid } from "@/components/tables/BaseAgGrid";
import { BadgeRenderer, UserRenderer } from "@/components/tables/renderers";
import { useTenantContext } from "@/contexts/TenantContext";
import { Document, useDocuments } from "@/state/queries/documents";
import { Flex } from "@radix-ui/themes";
import { createFileRoute, Link } from "@tanstack/react-router";
import {
  ColDef,
  ICellRendererParams,
  RowSelectedEvent,
  RowSelectionOptions,
} from "ag-grid-community";
import { useState } from "react";

export const Route = createFileRoute("/_app/$tenantSlug/documents/")({
  // Empty because the parent route sets the title
  beforeLoad: () => ({
    getTitle: () => "",
  }),
  component: Documents,
});

function Documents() {
  const [filter, setFilter] = useState("");
  const [selectedDocuments, setSelectedDocuments] = useState<Document[]>([]);

  return (
    <Flex direction="column" gap="2">
      <PageTitle
        title="Documents"
        description="Manage official organization documents while maintaining compliance."
      />
      <Flex justify="between" align="center" py="2">
        <SearchFilter
          filter={filter}
          setFilter={setFilter}
          placeholder="Search documents"
        />
        <DocumentActions
          selectedDocuments={selectedDocuments}
          setSelectedDocuments={setSelectedDocuments}
        />
      </Flex>
      <DocumentsTable
        onSelectionChange={setSelectedDocuments}
        filterText={filter}
      />
    </Flex>
  );
}

const DocumentActions: React.FC<{
  selectedDocuments: Document[];
  setSelectedDocuments: (documents: Document[]) => void;
}> = ({ selectedDocuments, setSelectedDocuments }) => {
  let actions = [];
  if (selectedDocuments.length === 1) {
    actions.push(
      <DeleteDocumentAction
        key="delete"
        document={selectedDocuments[0]}
        onSuccess={() => setSelectedDocuments([])}
      />
    );
  } else {
    actions.push(<CreateDocumentAction key="add" />);
  }
  return <Flex gap="2">{actions}</Flex>;
};

const getColDefs = (tenantSlug: string): ColDef<Document>[] => [
  {
    field: "title",
    headerName: "Title",
    cellRenderer: ({ data, value }: { data: Document; value: string }) => (
      <Link to={`/${tenantSlug}/documents/${data.id}`}>{value}</Link>
    ),
    width: 200,
  },
  {
    field: "status",
    headerName: "Status",
    width: 90,
    cellRenderer: BadgeRenderer,
  },
  {
    field: "id",
    headerName: "ID",
    width: 110,
  },
  {
    field: "owner",
    headerName: "Owner",
    cellRenderer: UserRenderer,
    width: 180,
  },
  {
    field: "processId",
    headerName: "Linked Process",
    cellRenderer: (props: ICellRendererParams) => (
      <Link to={`/${tenantSlug}/processes/${props.data.processId}`}>
        {props.data.processName}
      </Link>
    ),
    flex: 1,
  },
];

const DocumentsTable: React.FC<{
  onSelectionChange: (selectedRows: Document[]) => void;
  filterText: string;
}> = ({ onSelectionChange, filterText }) => {
  const { tenantSlug } = Route.useParams();
  const { tenant } = useTenantContext();
  const canEditDocuments = tenant.userPermissions.has("write.documents");

  const { data: documents, isLoading } = useDocuments();
  const handleRowSelected = (event: RowSelectedEvent<Document>) => {
    const selectedRows = event.api.getSelectedRows();
    onSelectionChange(selectedRows);
  };

  const rowSelection: RowSelectionOptions = {
    mode: "singleRow",
    enableClickSelection: true,
    enableSelectionWithoutKeys: true,
  };

  return (
    <BaseAgGrid<Document>
      loading={isLoading}
      rowData={documents}
      columnDefs={getColDefs(tenantSlug)}
      quickFilterText={filterText}
      rowSelection={canEditDocuments ? rowSelection : undefined}
      onRowSelected={handleRowSelected}
    />
  );
};

export default Documents;
