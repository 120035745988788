import { RecordFieldBaseType } from "@/types/recordFields";
import { Badge, Flex } from "@radix-ui/themes";
import {
  IconCalendar,
  IconCheckbox,
  IconCircleCheck,
  IconClock,
  IconFile,
  IconForms,
  IconId,
  IconLink,
  IconList,
  IconNumber123,
  IconUser,
} from "@tabler/icons-react";

export const RecordFieldTypeLabel: React.FC<{
  type: RecordFieldBaseType;
  badgeClassName?: string;
}> = ({ type, badgeClassName }) => {
  return (
    <Flex align="center" gap="2">
      <Badge
        className={badgeClassName}
        color="gray"
        variant="soft"
        style={{ height: "24px" }}
      >
        {recordFieldTypeToIcon[type]}
      </Badge>
      {recordFieldTypeToLabel[type]}
    </Flex>
  );
};

export const FieldIcon: React.FC<{ type: RecordFieldBaseType }> = ({
  type,
}) => {
  return (
    <Badge color="gray" variant="soft" style={{ height: "24px" }}>
      {recordFieldTypeToIcon[type]}
    </Badge>
  );
};

const recordFieldTypeToIcon: {
  [type in RecordFieldBaseType]: React.ReactElement;
} = {
  text: <IconForms />,
  number: <IconNumber123 />,
  date: <IconCalendar />,
  timestamp: <IconClock />,
  boolean: <IconCheckbox />,
  file: <IconFile />,
  values: <IconList />,
  userId: <IconUser />,
  link: <IconLink />,
  id: <IconId />,
  status: <IconCircleCheck />,
};

const recordFieldTypeToLabel: {
  [type in RecordFieldBaseType]: string;
} = {
  text: "Text",
  number: "Number",
  date: "Date",
  timestamp: "Timestamp",
  boolean: "Boolean",
  file: "Files",
  values: "Value list",
  userId: "User",
  link: "Linked record",
  id: "Identifier",
  status: "Status",
};
