import Form from "@/components/forms";
import { GenericFormApi } from "@/components/forms/types";
import { ActionOperation, ActionPermissions } from "@/types/actions";
import { Box, Dialog, Flex, Tabs, Text, Tooltip } from "@radix-ui/themes";
import { FormApi, ReactFormApi, useForm } from "@tanstack/react-form";
import { useWorkflowEditorContext } from "../WorkflowEditorContext";
import { ActionNodeData, ActionNodeType } from "../graph/ActionNode";
import { ActionFormTab } from "./ActionFormTab";
import { ActionOperationSelect } from "./form/ActionOperationSelect";
import { ActionPermissionsTypeSelect } from "./form/ActionPermissionsTypeSelect";

export interface ActionConfiguration extends ActionNodeData {}

export const EditActionDialogContent = ({ node }: { node: ActionNodeType }) => {
  const {
    clearEditingNode,
    updateNode,
    removeInboundEdges,
    removeOutboundEdges,
  } = useWorkflowEditorContext();

  const form = useForm<ActionConfiguration>({
    defaultValues: {
      ...node.data,
    },
    onSubmit: async ({ value }) => {
      // Ensure we don't have any form fields for delete actions
      const updatedAction = {
        ...value,
        form: {
          ...value.form,
          fields: ActionOperation.isDelete(value.operation)
            ? []
            : value.form.fields,
        },
      };
      updateNode({
        ...node,
        data: updatedAction,
      });
      if (ActionOperation.isCreate(value.operation)) {
        removeInboundEdges(node.id);
      }

      if (ActionOperation.isDelete(value.operation)) {
        removeOutboundEdges(node.id);
      }
      clearEditingNode();
    },
  });

  const isDeleteOperation = form.useStore((state) =>
    ActionOperation.isDelete(state.values.operation)
  );

  const formTabTrigger = (
    <Tabs.Trigger value="form" disabled={isDeleteOperation}>
      Form
    </Tabs.Trigger>
  );
  const maybeWrappedFormTabTrigger = isDeleteOperation ? (
    <Tooltip content="No form is shown for delete operations">
      {formTabTrigger}
    </Tooltip>
  ) : (
    formTabTrigger
  );

  return (
    <Dialog.Content maxWidth="700px" size="2">
      <Dialog.Title>Edit action</Dialog.Title>
      <Form.Root reactForm={form}>
        <Flex direction="column" gap="4">
          <Form.Fields>
            <Tabs.Root defaultValue="general">
              <Tabs.List>
                <Tabs.Trigger value="general">General</Tabs.Trigger>
                {maybeWrappedFormTabTrigger}
                <Tabs.Trigger value="permissions">Permissions</Tabs.Trigger>
              </Tabs.List>

              <Box minHeight="400px">
                <Tabs.Content value="general">
                  <GeneralTab form={form} />
                </Tabs.Content>

                <Tabs.Content value="form">
                  <ActionFormTab form={form} />
                </Tabs.Content>

                <Tabs.Content value="permissions">
                  <PermissionsTab form={form} />
                </Tabs.Content>
              </Box>
            </Tabs.Root>
          </Form.Fields>
          <Form.Footer
            form={form}
            error={null}
            isPending={false}
            buttonText="Save changes"
          />
        </Flex>
      </Form.Root>
    </Dialog.Content>
  );
};

const GeneralTab = ({
  form,
}: {
  form: GenericFormApi<ActionConfiguration>;
}) => {
  const { recordTypeId } = useWorkflowEditorContext();
  return (
    <Form.Fields pt="3">
      <Form.TextField<ActionConfiguration>
        reactFormField={{ form, name: "name" }}
        fieldProps={{ label: "Name", required: true }}
        placeholder="Enter action name"
        required
      />
      <ActionOperationSelect<ActionConfiguration>
        reactFormField={{ form, name: "operation" }}
        fieldProps={{ label: "Operation", required: true }}
        recordTypeId={recordTypeId}
      />
      <Form.TextArea<ActionConfiguration>
        reactFormField={{ form, name: "description" }}
        fieldProps={{ label: "Description" }}
        placeholder="Enter action description"
      />
    </Form.Fields>
  );
};

const PermissionsTab = ({
  form,
}: {
  form: FormApi<ActionConfiguration> & ReactFormApi<ActionConfiguration>;
}) => {
  return (
    <Form.Fields pt="3">
      <ActionPermissionsTypeSelect
        reactFormField={{ form, name: "permissions" }}
        fieldProps={{ label: "Permissions" }}
      />
      <form.Subscribe
        selector={(state) => [state.values.permissions]}
        children={([permissions]) => {
          return ActionPermissions.visit(permissions, {
            noFilter: () => {
              return (
                <Text color="gray">
                  All users in your organization will be permitted to execute
                  this action.
                </Text>
              );
            },
            userFilter: () => {
              return (
                <Form.UserMultiSelect<ActionConfiguration>
                  reactFormField={{
                    form,
                    name: "permissions.userFilter.userIds",
                  }}
                  fieldProps={{ label: "Users", required: true }}
                />
              );
            },
            groupFilter: () => {
              return (
                <Form.GroupMultiSelect<ActionConfiguration>
                  reactFormField={{
                    form,
                    name: "permissions.groupFilter.groupIds",
                  }}
                  fieldProps={{ label: "Groups", required: true }}
                />
              );
            },
          });
        }}
      />
    </Form.Fields>
  );
};
