import { THEME_COLOR } from "@/theme";
import { Status, toRadixColor } from "@/types/status";
import { Badge } from "@radix-ui/themes";
import { StatusIcon } from "./StatusIcon";

export const StatusBadge = ({ status }: { status: Status }) => {
  const radixColor = status.display?.color
    ? toRadixColor(status.display?.color)
    : THEME_COLOR;
  return (
    <Badge color={radixColor} size="2" radius="large">
      <StatusIcon
        icon={status.display?.icon}
        style={{ height: "14px", width: "14px" }}
      />
      {status.value}
    </Badge>
  );
};
