import { FormField } from "@/components/forms/FormField";
import { BaseFieldProps } from "@/components/forms/types";
import {
  StatusColorType,
  StatusDisplay,
  StatusIconType,
  toRadixColor,
} from "@/types/status";
import {
  Box,
  Flex,
  Grid,
  IconButton,
  Inset,
  Popover,
  Separator,
} from "@radix-ui/themes";
import { DeepKeys, useField } from "@tanstack/react-form";
import { useState } from "react";
import { StatusIcon } from "./StatusIcon";
export interface StatusDisplaySelectProps<TData>
  extends BaseFieldProps<TData> {}

const colors: StatusColorType[] = [
  "gray",
  "primary",
  "blue",
  "green",
  "orange",
  "red",
];

const icons: StatusIconType[] = [
  "circle",
  "check",
  "cross",
  "alert",

  "circleFilled",
  "checkFilled",
  "crossFilled",
  "alertFilled",

  "dashed",
  "dashedCheck",
  "dashedCross",
  "dashedMinus",
];

export function StatusDisplaySelect<TData>(
  props: StatusDisplaySelectProps<TData>
) {
  const { reactFormField, fieldProps } = props;
  const [isOpen, setIsOpen] = useState(false);
  const field = useField<TData, DeepKeys<TData>>(reactFormField);

  const value = field.state.value as StatusDisplay | undefined;
  const { icon, color } = value ?? {};
  const radixColor = toRadixColor(color);

  return (
    <FormField
      reactForm={reactFormField.form}
      reactFormField={field}
      {...fieldProps}
    >
      <Popover.Root open={isOpen} onOpenChange={setIsOpen}>
        <Popover.Trigger>
          <IconButton variant="soft" color={radixColor} size="3">
            <StatusIcon icon={icon} />
          </IconButton>
        </Popover.Trigger>
        <Popover.Content size="2">
          <Flex gap="2">
            {colors.map((color) => (
              <IconButton
                variant="solid"
                color={toRadixColor(color)}
                size="1"
                radius="full"
                onClick={() => {
                  field.handleChange({ ...field.state.value, color });
                }}
              >
                <Box width="100%" height="100%" />
              </IconButton>
            ))}
          </Flex>
          <Inset side="x">
            <Separator size="4" my="2" />
          </Inset>
          <Grid columns="4" gap="2" width="100%" align="center">
            {icons.map((icon) => {
              return (
                <Flex justify="center">
                  <IconButton
                    variant="soft"
                    color={radixColor}
                    size="2"
                    onClick={() => {
                      setIsOpen(false);
                      field.handleChange({ ...field.state.value, icon });
                    }}
                  >
                    <StatusIcon icon={icon} />
                  </IconButton>
                </Flex>
              );
            })}
          </Grid>
        </Popover.Content>
      </Popover.Root>
    </FormField>
  );
}
