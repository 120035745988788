import { useTenantContext } from "@/contexts/TenantContext";
import { supabase } from "@/supabaseClient";
import { RecordTypeWithoutFields } from "@/types/recordTypes";
import { maybeConvertApiError } from "@/utils/requests";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export interface UpdateRecordTypeBody {
  tenantId: string;
  recordType: RecordTypeWithoutFields;
}

const updateRecordType = async (input: UpdateRecordTypeBody) => {
  const { error } = await supabase.functions.invoke("record-types", {
    method: "PUT",
    body: input,
  });

  if (error) {
    const apiError = await maybeConvertApiError(error);
    return Promise.reject(apiError);
  }
};

export const useUpdateRecordType = () => {
  const queryClient = useQueryClient();
  const tenant = useTenantContext();

  return useMutation({
    mutationFn: (input: RecordTypeWithoutFields) => {
      if (!tenant.tenant) {
        throw new Error("Tenant not found");
      }
      return updateRecordType({
        recordType: input,
        tenantId: tenant.tenant.tenantId,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["recordAndLinkTypes", tenant.tenant?.tenantId],
      });
    },
  });
};
