import { useTenantUser } from "@/state/queries/tenantUsers";
import { Skeleton, Text, TextProps } from "@radix-ui/themes";
import React from "react";

type UserNameProps = TextProps & {
  userId: string;
};

const UserName: React.FC<UserNameProps> = ({ userId, ...props }) => {
  const { data, isLoading } = useTenantUser(userId);

  const displayName = data?.fullName ?? data?.email ?? "Unknown User";

  return (
    <Skeleton loading={isLoading}>
      <Text {...props}>{displayName}</Text>
    </Skeleton>
  );
};

export default UserName;
