import {
  FunctionsFetchError,
  FunctionsHttpError,
  FunctionsRelayError,
} from "@supabase/functions-js";

interface ApiError {
  message: string;
}

export const maybeConvertApiError = async (error: any) => {
  if (error instanceof FunctionsHttpError) {
    const apiError: ApiError = await error.context.json();
    return new Error(apiError.message);
  } else if (error instanceof FunctionsRelayError) {
    return new Error(
      "Unable to relay the request to the server. Please try again later."
    );
  } else if (error instanceof FunctionsFetchError) {
    return new Error(
      "Unable to send a request to the server. Please try again later."
    );
  }
  return error;
};
