import { useTenantContext } from "@/contexts/TenantContext";
import { supabase } from "@/supabaseClient";
import { queryOptions, useQuery, UseQueryResult } from "@tanstack/react-query";

export interface ComplianceMappedProcess {
  id: string;
  name: string;
  owner: string;
}

export interface ComplianceRequirement {
  clause: string;
  title: string;
  language?: string;
  status: "Not started" | "In progress" | "Completed";
  processes: ComplianceMappedProcess[];
}

export interface ComplianceFramework {
  id: string;
  displayName: string;
  description?: string;
  requirements: ComplianceRequirement[];
}

const fetchComplianceFrameworks = async (
  tenantId: string
): Promise<ComplianceFramework[]> => {
  const result = await supabase
    .from("compliance_frameworks")
    .select(
      `
    id,
    display_name,
    description,
    compliance_framework_requirements (
      clause,
      title,
      language,
      controls (
        title,
        language,
        processes (
          id,
          name,
          owned_by
        )
      )
    )
  `
    )
    .eq("compliance_framework_requirements.controls.tenant_id", tenantId);

  if (result.error) {
    throw result.error;
  }

  if (!result.data) {
    return [];
  }

  return result.data.map((framework) => ({
    id: framework.id,
    displayName: framework.display_name,
    description: framework.description ?? undefined,
    requirements: framework.compliance_framework_requirements.map(
      (requirement) => {
        const processes = requirement.controls
          .filter((control) => !!control.processes)
          .map((control) => ({
            id: control.processes?.id ?? "",
            name: control.processes?.name ?? "",
            owner: control.processes?.owned_by ?? "",
          }));
        return {
          clause: requirement.clause,
          title: requirement.title,
          language: requirement.language ?? undefined,
          status: processes.length > 0 ? "In progress" : "Not started",
          processes,
        };
      }
    ),
  }));
};

export const complianceFrameworksQueryOptions = (tenantId: string) =>
  queryOptions({
    queryKey: ["complianceFrameworks", tenantId],
    queryFn: () => fetchComplianceFrameworks(tenantId),
    staleTime: 1000 * 60 * 5, // 5 minutes
    retry: false,
  });

export const useComplianceFrameworks = (): UseQueryResult<
  ComplianceFramework[]
> => {
  const tenant = useTenantContext();
  if (!tenant.tenant) {
    throw new Error("Tenant not found");
  }
  return useQuery(complianceFrameworksQueryOptions(tenant.tenant.tenantId));
};

export const useComplianceFramework = (frameworkId: string) => {
  const { data: frameworks, ...rest } = useComplianceFrameworks();
  return {
    ...rest,
    data: frameworks?.find((framework) => framework.id === frameworkId),
  };
};
