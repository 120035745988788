import Form from "@/components/forms";
import { useCreateView } from "@/state/mutations/views/createView";
import { RecordType } from "@/types/recordTypes";
import { Dialog } from "@radix-ui/themes";
import { useForm } from "@tanstack/react-form";
import { zodValidator } from "@tanstack/zod-form-adapter";
import { z } from "zod";

interface CreateViewInputs {
  name: string;
}

export const CreateViewDialogContent: React.FC<{
  open: boolean;
  onOpenChange: (open: boolean) => void;
  recordType: RecordType;
  onSuccess: (id: string) => void;
}> = ({ open, onOpenChange, recordType, onSuccess }) => {
  const { mutate: createView, isPending, error, reset } = useCreateView();

  const form = useForm<CreateViewInputs, any>({
    defaultValues: {
      name: "",
    },
    validatorAdapter: zodValidator(),
    validators: {
      onChange: z.object({
        name: z.string().min(2, { message: "View name is required" }),
      }),
    },
    onSubmit: ({ value }) => {
      createView(
        {
          name: value.name,
          recordType,
        },
        {
          onSuccess: (data) => {
            onSuccess(data.id);
          },
        }
      );
    },
  });

  const handleOpenChange = (open: boolean) => {
    onOpenChange(open);
    if (!open) {
      form.reset();
      reset();
    }
  };

  return (
    <Dialog.Root open={open} onOpenChange={handleOpenChange}>
      <Dialog.Content maxWidth="450px">
        <Dialog.Title>Create view</Dialog.Title>

        <Form.Root reactForm={form}>
          <Form.TextField<CreateViewInputs>
            reactFormField={{ form, name: "name" }}
            fieldProps={{ label: "View name", required: true }}
            placeholder="Enter view name"
          />

          <Form.Footer
            form={form}
            error={error}
            isPending={isPending}
            buttonText="Create view"
            errorPrefix="Failed to create view: "
          />
        </Form.Root>
      </Dialog.Content>
    </Dialog.Root>
  );
};
