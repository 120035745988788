import { useTenantUser } from "@/state/queries/tenantUsers";
import {
  Avatar,
  Flex,
  HoverCard,
  Link,
  Skeleton,
  Text,
} from "@radix-ui/themes";
import React from "react";

const User: React.FC<{ userId: string; hoverOpenDelay?: number }> = ({
  userId,
  hoverOpenDelay = 600,
}) => {
  const { data, isLoading } = useTenantUser(userId);

  const displayName = data?.fullName ?? data?.email ?? "Unknown User";
  const fallback = data?.fullName?.[0] ?? data?.email?.[0] ?? "U";

  return (
    <Flex align="center" gap="2" style={{ cursor: "pointer" }}>
      <Skeleton loading={isLoading}>
        <HoverCard.Root openDelay={hoverOpenDelay}>
          <HoverCard.Trigger>
            <Flex align="center" gap="2">
              <Avatar fallback={fallback} size="1" />
              {displayName}
            </Flex>
          </HoverCard.Trigger>
          <HoverCard.Content>
            <Flex direction="column" gap="2">
              <Avatar fallback={fallback} size="2" />
              {data?.fullName && <Text>{data.fullName}</Text>}
              {data?.email && (
                <Link size="2" href={`mailto:${data.email}`}>
                  {data.email}
                </Link>
              )}
            </Flex>
          </HoverCard.Content>
        </HoverCard.Root>
      </Skeleton>
    </Flex>
  );
};

export default User;
