import { Flex, Heading, Skeleton, Text } from "@radix-ui/themes";

interface PageTitleProps {
  title: React.ReactNode;
  titleSize?: "4" | "5" | "6" | "7";
  description?: React.ReactNode;
  tag?: string;
  children?: React.ReactNode;
  isLoading?: boolean;
}

/**
 * Full-width title for a page. Children are rendered on the right side, meant to
 * be used for page-level actions.
 */
export const PageTitle: React.FC<PageTitleProps> = ({
  title,
  titleSize = "7",
  description,
  tag,
  children,
  isLoading,
}) => {
  const maybeTag = tag && (
    <Text
      color="gray"
      size="2"
      weight="bold"
      style={{ textTransform: "uppercase", color: "var(--gray-10)" }}
    >
      {tag}
    </Text>
  );

  const titleElement = isLoading ? <Skeleton>Title for item</Skeleton> : title;
  const descriptionElement =
    description && isLoading ? (
      <Skeleton>This is a description of the item</Skeleton>
    ) : (
      description
    );

  return (
    <Flex align="center" justify="between">
      <Flex direction="column" gap="1" maxWidth="600px">
        {maybeTag}
        <Heading size={titleSize} wrap="balance">
          {titleElement}
        </Heading>
        {descriptionElement && (
          <Text color="gray" size="4">
            {descriptionElement}
          </Text>
        )}
      </Flex>
      {children}
    </Flex>
  );
};

export default PageTitle;
