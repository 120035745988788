/* eslint-disable @typescript-eslint/no-redeclare */

import { KeyedTypeUnion, createTypeUnion } from "keyed-type-union";

// TODO: Figure out how to support composing filters (andFilter/orFilter?)
// TODO: Figure out view visibility (private, org-wide, or something in between)
// TODO: Allow users to filter which workflows should be shown
export interface RecordViewConfig {
  /**
   * ID of this view.
   */
  id: string;

  /**
   * The display name of this view.
   */
  name: string;

  /**
   * Record type ID displayed in this view.
   */
  recordTypeId: string;

  /**
   * Default filter for records in this view.
   */
  defaultFilter?: RecordFilter;

  /**
   * Display configuration for this view.
   */
  viewType: ViewType;
}

/*
 * View types
 */
export type ViewType = KeyedTypeUnion<{
  table: TableViewConfig;
}>;
export const ViewType = createTypeUnion<ViewType>({
  table: undefined,
});

// TODO: Add sort options
export interface TableViewConfig {
  /**
   * Ordered list of columns that should be shown in the table for the view.
   */
  columns: TableViewColumnConfig[];
}

export interface TableViewColumnConfig {
  /**
   * Record field ID that should be shown as a column.
   */
  fieldId: string;

  /**
   * Width of the column in pixels.
   */
  width?: number;
}

/*
 * Record filters
 */
export type RecordFilter = KeyedTypeUnion<{
  statusFilter: StatusFilter;
}>;
export const RecordFilter = createTypeUnion<RecordFilter>({
  statusFilter: undefined,
});

/**
 * Filters records by status.
 */
interface StatusFilter {
  statuses: string[];
}

export const DRAFT_DESIGN_PLANS: RecordViewConfig = {
  id: "1234",
  name: "Draft design plans",
  recordTypeId: "design_plans",
  defaultFilter: {
    type: "statusFilter",
    statusFilter: {
      statuses: ["Draft"],
    },
  },
  viewType: {
    type: "table",
    table: {
      columns: [
        {
          fieldId: "name",
        },
        {
          fieldId: "description",
        },
      ],
    },
  },
};

export const APPROVED_DESIGN_PLANS: RecordViewConfig = {
  id: "2345",
  name: "Pending design plans",
  recordTypeId: "design_plans",
  defaultFilter: {
    type: "statusFilter",
    statusFilter: {
      statuses: ["Pending approval"],
    },
  },
  viewType: {
    type: "table",
    table: {
      columns: [
        {
          fieldId: "name",
          width: 200,
        },
        {
          fieldId: "requirements",
        },
        {
          fieldId: "requirements_added_at",
        },
        {
          fieldId: "approved_at",
        },
      ],
    },
  },
};
