import { useTenantContext } from "@/contexts/TenantContext";
import { ExecutableAction } from "@/hooks/useActionTypes";
import { supabase } from "@/supabaseClient";
import { ActionFormFieldTypeData } from "@/types/actionFormField";
import { RecordFieldType } from "@/types/recordFields";
import { RecordType } from "@/types/recordTypes";
import { meridianNanoid } from "@/utils/meridianNanoid";
import { maybeConvertApiError } from "@/utils/requests";
import { useMutation, useQueryClient } from "@tanstack/react-query";

/**
 * This type is shared with the backend. All changes must be reflected there.
 */
export interface ExecuteActionRequest {
  tenantId: string;
  workflowId: string;
  actionId: string;
  recordId: string;
  fieldValues: { [fieldId: string]: any };
}

interface ExecuteActionParams {
  tenantId: string;
  action: ExecutableAction;
  recordType: RecordType;
  recordId?: string;
  fieldValues: { [fieldId: string]: any };
}

const uploadFile = async (
  file: File,
  tenantId: string,
  recordTypeId: string,
  recordId: string,
  fieldId: string
) => {
  const fileId = meridianNanoid();
  const filePath = `${tenantId}/${fileId}/${file.name}`;

  // Upload file to storage using generated ID
  const { error: storageError } = await supabase.storage
    .from("record_files")
    .upload(filePath, file);

  if (storageError) {
    throw new Error(storageError.message);
  }

  // Create record_files entry with same ID
  const { error } = await supabase.from("record_files").insert({
    id: fileId,
    tenant_id: tenantId,
    record_type_id: recordTypeId,
    record_id: recordId ?? "",
    field_id: fieldId,
    original_filename: file.name,
    content_type: file.type,
    file_path: filePath,
  });

  if (error) {
    throw new Error(error.message);
  }

  return filePath;
};

const executeAction = async (input: ExecuteActionParams) => {
  // Handle file upload fields
  const fileFieldUploadPaths: { [fieldId: string]: string } = {};
  for (const [fieldId, value] of Object.entries(input.fieldValues)) {
    const field = input.action.form.fields.find((f) => f.id === fieldId);
    if (field && ActionFormFieldTypeData.isFile(field.type)) {
      // TODO: Figure out how to handle file uploads for create actions
      if (!input.recordId) {
        // https://linear.app/meridian-tech/issue/MER-74
        throw new Error(
          "Uploading files while creating a record is not currently supported"
        );
      }

      const recordField = input.recordType.fields.find((f) => f.id === fieldId);
      if (!recordField) {
        throw new Error(
          `Field ${fieldId} not found in record type ${input.recordType.id}`
        );
      }
      if (!RecordFieldType.isFile(recordField.type)) {
        throw new Error(
          `Field ${fieldId} is not a file field in record type ${input.recordType.id}`
        );
      }

      const files = value as File[];
      const allowMultiple = recordField.type.file.allowMultiple;
      if (allowMultiple) {
        const filePaths: string[] = [];
        for (const file of files) {
          const filePath = await uploadFile(
            file,
            input.tenantId,
            input.recordType.id,
            input.recordId ?? "",
            fieldId
          );
          filePaths.push(filePath);
        }
        input.fieldValues[fieldId] = filePaths;
      } else {
        if (files.length !== 1) {
          throw new Error(
            `Expected 1 file, got ${files.length} for field ${fieldId}`
          );
        }
        const filePath = await uploadFile(
          files[0],
          input.tenantId,
          input.recordType.id,
          input.recordId ?? "",
          fieldId
        );
        input.fieldValues[fieldId] = filePath;
      }
    }
  }

  // Merge file uploads into input field values
  const updatedFieldValues = { ...input.fieldValues, ...fileFieldUploadPaths };

  // Create request shape for backend
  const executeActionRequest: ExecuteActionRequest = {
    tenantId: input.tenantId,
    workflowId: input.action.workflowId,
    actionId: input.action.id,
    recordId: input.recordId ?? "",
    fieldValues: updatedFieldValues,
  };

  const { error } = await supabase.functions.invoke("actions/execute", {
    method: "POST",
    body: executeActionRequest,
  });

  if (error) {
    const apiError = await maybeConvertApiError(error);
    return Promise.reject(apiError);
  }
};

export const useExecuteAction = () => {
  const queryClient = useQueryClient();
  const tenant = useTenantContext();

  return useMutation({
    mutationFn: (input: Omit<ExecuteActionParams, "tenantId">) => {
      if (!tenant.tenant) {
        throw new Error("Tenant not found");
      }
      return executeAction({ ...input, tenantId: tenant.tenant?.tenantId });
    },
    onSuccess: (_, params) => {
      queryClient.invalidateQueries({
        queryKey: ["records", params.action.recordTypeId],
      });
    },
  });
};
