import { useTenantContext } from "@/contexts/TenantContext";
import { supabase } from "@/supabaseClient";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export interface AddProcessControlInput {
  processId: string;
  frameworkId: string;
  clause: string;
  title: string;
  language: string;
}

const addControl = async (input: AddProcessControlInput, tenantId: string) => {
  const { error } = await supabase.from("controls").insert({
    tenant_id: tenantId,
    process_id: input.processId,
    framework_id: input.frameworkId,
    clause: input.clause,
    language: input.language,
    title: input.title,
  });

  if (error) {
    throw error;
  }
};

export const useAddControl = () => {
  const queryClient = useQueryClient();
  const tenant = useTenantContext();

  return useMutation({
    mutationFn: (input: AddProcessControlInput) => {
      if (!tenant.tenant) {
        throw new Error("Tenant not found");
      }
      return addControl(input, tenant.tenant.tenantId);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["processes", tenant.tenant?.tenantId],
      });
    },
  });
};
