import MeridianTabNav from "@/components/MeridianTabNav";
import PageTitle from "@/components/PageTitle";
import { useDocumentsByProcess } from "@/state/queries/documents";
import { processesQueryOptions, useProcess } from "@/state/queries/processes";
import { Box, Flex } from "@radix-ui/themes";
import {
  IconArrowRightRhombus,
  IconChecklist,
  IconFileText,
  IconListDetails,
} from "@tabler/icons-react";
import { createFileRoute, Outlet } from "@tanstack/react-router";

export const Route = createFileRoute("/_app/$tenantSlug/processes/$processId")({
  beforeLoad: async ({ context, params }) => {
    if (!context.tenant) {
      return { getTitle: () => "Process Details" };
    }
    const processes = await context.queryClient.ensureQueryData(
      processesQueryOptions(context.tenant.tenantId)
    );
    const process = processes.find((p) => p.id === params.processId);
    return {
      getTitle: () => process?.name ?? "Process Details",
    };
  },
  component: Process,
});

function Process() {
  const { tenantSlug, processId } = Route.useParams();
  const { data: process, isLoading } = useProcess(processId);
  const { data: documents } = useDocumentsByProcess(processId);

  return (
    <Flex direction="column" gap="5">
      <PageTitle
        title={process?.name ?? "Process"}
        description={process?.description}
        tag={process?.id ?? "Process"}
        isLoading={isLoading}
      />
      <Box>
        <MeridianTabNav<"/$tenantSlug/processes/$processId">
          childRouteParams={{ tenantSlug, processId }}
          links={[
            {
              to: "/$tenantSlug/processes/$processId/controls",
              label: "Controls",
              icon: <IconChecklist />,
            },
            {
              to: "/$tenantSlug/processes/$processId/workflows",
              label: "Workflows",
              icon: <IconArrowRightRhombus />,
            },
            {
              to: "/$tenantSlug/processes/$processId/record-types",
              label: "Record Types",
              icon: <IconListDetails />,
            },
            {
              to: "/$tenantSlug/processes/$processId/documents",
              label: "Documents",
              icon: <IconFileText />,
              badge: documents?.length.toString() ?? undefined,
            },
          ]}
        />
        <Outlet />
      </Box>
    </Flex>
  );
}

export default Process;
