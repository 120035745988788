import { ApproveRevisionAction } from "@/components/documents/ApproveRevisionAction";
import { DeleteDocumentAction } from "@/components/documents/DeleteDocumentAction";
import { EditDocumentMetadataAction } from "@/components/documents/EditDocumentMetadataAction";
import { RejectRevisionAction } from "@/components/documents/RejectRevisionAction";
import { SendRevisionForReviewAction } from "@/components/documents/SendRevisionForReviewAction";
import MeridianTabNav from "@/components/MeridianTabNav";
import PageTitle from "@/components/PageTitle";
import { PermissionedButton } from "@/components/PermissionedButton";
import {
  Document,
  documentsQueryOptions,
  useDocument,
} from "@/state/queries/documents";
import { Box, Flex } from "@radix-ui/themes";
import { IconFileText, IconHistory, IconPencil } from "@tabler/icons-react";
import { createFileRoute, Outlet } from "@tanstack/react-router";

export const Route = createFileRoute("/_app/$tenantSlug/documents/$documentId")(
  {
    beforeLoad: async ({ context, params }) => {
      if (!context.tenant) {
        return { getTitle: () => "Document" };
      }
      const documents = await context.queryClient.fetchQuery(
        documentsQueryOptions(context.tenant.tenantId)
      );
      const document = documents.find((doc) => doc.id === params.documentId);
      return {
        getTitle: () => document?.title || "Document",
      };
    },
    component: DocumentPage,
  }
);

function DocumentActions({ document }: { document: Document }) {
  const navigate = Route.useNavigate();
  const onDeleteSuccess = () => {
    navigate({ to: "/$tenantSlug/documents" });
  };

  let actions = [];
  if (document.status === "Draft") {
    actions.push(
      <EditDocumentMetadataAction
        key="edit"
        document={document}
        enableFileEdit={document.status === "Draft"}
      >
        <PermissionedButton
          permission="write.documents"
          variant="soft"
          color="gray"
        >
          <IconPencil />
          Edit
        </PermissionedButton>
      </EditDocumentMetadataAction>
    );
    actions.push(
      <DeleteDocumentAction
        key="delete"
        document={document}
        onSuccess={onDeleteSuccess}
      />
    );
    actions.push(
      <SendRevisionForReviewAction
        key="send"
        document={document}
        revisionId={document.revisions[0].id}
        isInitialRevision={true}
      />
    );
  } else if (document.status === "In review") {
    actions.push(
      <RejectRevisionAction
        key="reject"
        document={document}
        revisionId={document.revisions[0].id}
        isInitialRevision={true}
      />
    );
    actions.push(
      <ApproveRevisionAction
        key="approve"
        document={document}
        revisionId={document.revisions[0].id}
        isInitialRevision={true}
      />
    );
  }
  return <Flex gap="2">{actions}</Flex>;
}

function DocumentPage() {
  const { tenantSlug, documentId } = Route.useParams();
  const { data: document, isLoading } = useDocument(documentId);

  return (
    <Flex direction="column" gap="5">
      <PageTitle
        isLoading={isLoading}
        title={document?.title}
        description={document?.description}
        tag={document?.id ?? "Document"}
      >
        {document && <DocumentActions document={document} />}
      </PageTitle>
      <Box>
        <MeridianTabNav<"/$tenantSlug/documents/$documentId">
          childRouteParams={{ tenantSlug, documentId }}
          links={[
            {
              to: "/$tenantSlug/documents/$documentId/",
              label: "Overview",
              icon: <IconFileText />,
            },
            {
              to: "/$tenantSlug/documents/$documentId/revisions",
              label: "Revisions",
              icon: <IconHistory />,
            },
          ]}
        />
        <Outlet />
      </Box>
    </Flex>
  );
}
