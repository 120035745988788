import { useRecordTypeById } from "@/state/queries/recordLinkTypes";
import { Text } from "@radix-ui/themes";

export const RecordTypeLabel: React.FC<{ recordTypeId: string }> = ({
  recordTypeId,
}) => {
  const { data } = useRecordTypeById(recordTypeId);

  if (!data) {
    return null;
  }

  return <Text>{data.pluralName}</Text>;
};
