import { ActionNodeData } from "@/components/workflows/graph/ActionNode";
import { StatusNodeType } from "@/components/workflows/graph/StatusNode";
import { Action, ActionOperation } from "@/types/actions";
import { Status } from "@/types/status";
import { keyBy, mapValues } from "lodash";
import { EdgeType, NodeType } from "../components/workflows/graph/types";
import {
  WorkflowDefinition,
  WorkflowNode,
  WorkflowNodeContent,
} from "../types/workflows";

export function graphToWorkflowDefinition(
  nodes: NodeType[],
  edges: EdgeType[]
): WorkflowDefinition {
  // TODO: Populate action operations based on graph
  const nodeMap = keyBy(nodes, "id");
  return {
    nodes: mapValues(nodeMap, (node) => ({
      id: node.id,
      position: node.position,
      content: nodeToContent(node, nodeMap, edges),
    })),
    edges: keyBy(edges, "id"),
  };
}

export function workflowDefinitionToGraph(workflow: WorkflowDefinition): {
  nodes: NodeType[];
  edges: EdgeType[];
} {
  return {
    nodes: Object.values(workflow.nodes).map(workflowNodeToNode),
    edges: Object.values(workflow.edges).map((edge) => ({
      ...edge,
      type: "default",
    })),
  };
}

function nodeToContent(
  node: NodeType,
  nodeMap: Record<string, NodeType>,
  edges: EdgeType[]
): WorkflowNodeContent {
  if (node.type === "status") {
    return WorkflowNodeContent.status({ id: node.id, ...node.data });
  }
  if (node.type === "action") {
    return WorkflowNodeContent.action({
      ...node.data,
      id: node.id,
      operation: getActionOperation(node.id, node.data, nodeMap, edges),
    });
  }

  throw new Error(`Unknown node type: ${node.type}`);
}

function getActionOperation(
  actionId: string,
  action: ActionNodeData,
  nodeMap: Record<string, NodeType>,
  edges: EdgeType[]
): ActionOperation {
  return ActionOperation.visit<ActionOperation>(action.operation, {
    create: () => {
      return ActionOperation.create({
        endStatus: getOutboundStatus(actionId, nodeMap, edges),
      });
    },
    update: () => {
      return ActionOperation.update({
        startStatuses: getInboundStatuses(actionId, nodeMap, edges),
        endStatus: getOutboundStatus(actionId, nodeMap, edges),
      });
    },
    delete: () => {
      return ActionOperation.delete({
        startStatuses: getInboundStatuses(actionId, nodeMap, edges),
      });
    },
  });
}

function getInboundStatuses(
  actionId: string,
  nodeMap: Record<string, NodeType>,
  edges: EdgeType[]
): string[] {
  const inboundEdges = edges.filter((edge) => edge.target === actionId);
  const upstreamNodes = inboundEdges.map((edge) => nodeMap[edge.source]);

  const statusNodes = upstreamNodes.filter(
    (node) => node.type === "status"
  ) as StatusNodeType[];
  return statusNodes.map((node) => node.data.value);
}

function getInboundStatus(
  actionId: string,
  nodeMap: Record<string, NodeType>,
  edges: EdgeType[]
): string | undefined {
  const inboundStatuses = getInboundStatuses(actionId, nodeMap, edges);
  if (inboundStatuses.length > 0) {
    return inboundStatuses[0];
  }
}

function getOutboundStatus(
  actionId: string,
  nodeMap: Record<string, NodeType>,
  edges: EdgeType[]
): string | undefined {
  const outboundEdges = edges.filter((edge) => edge.source === actionId);
  const downstreamNodes = outboundEdges.map((edge) => nodeMap[edge.target]);

  const statusNodes = downstreamNodes.filter(
    (node) => node.type === "status"
  ) as StatusNodeType[];
  const statuses = statusNodes.map((node) => node.data.value);
  return statuses.length > 0 ? statuses[0] : undefined;
}

function workflowNodeToNode(workflowNode: WorkflowNode): NodeType {
  return WorkflowNodeContent.visit<NodeType>(workflowNode.content, {
    status: (data: Status) => ({
      id: workflowNode.id,
      type: "status",
      position: workflowNode.position,
      data,
    }),
    action: (data: Action) => ({
      id: workflowNode.id,
      type: "action",
      position: workflowNode.position,
      data,
    }),
  });
}
