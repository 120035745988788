import Form from "@/components/forms";
import { PermissionedButton } from "@/components/PermissionedButton";
import { useAddControl } from "@/state/mutations/processes/addControl";
import { useComplianceFrameworks } from "@/state/queries/complianceFrameworks";
import { Process } from "@/state/queries/processes";
import { compareClauses } from "@/utils/compareClauses";
import { Dialog, Grid, Select } from "@radix-ui/themes";
import { IconPlus } from "@tabler/icons-react";
import { useForm } from "@tanstack/react-form";
import { useEffect, useMemo, useState } from "react";

interface AddControlActionProps {
  process: Process;
}

interface AddControlFormInputs {
  frameworkId: string;
  clause: string;
  title: string;
  language: string;
}

export const AddControlAction: React.FC<AddControlActionProps> = ({
  process,
}) => {
  const [open, setOpen] = useState(false);
  const { mutate: addControl, error, reset, isPending } = useAddControl();
  const { data: frameworks } = useComplianceFrameworks();

  const handleOpenChange = (open: boolean) => {
    setOpen(open);
    form.reset();
    reset();
  };

  const form = useForm({
    defaultValues: {
      frameworkId: "AS9100D",
      clause: "",
      title: "",
      language: "",
    },
    onSubmit: ({ value }) => {
      addControl(
        {
          processId: process.id,
          frameworkId: value.frameworkId,
          clause: value.clause,
          title: value.title,
          language: value.language,
        },
        { onSuccess: () => setOpen(false) }
      );
    },
  });

  const clausesByFramework = useMemo(
    () =>
      frameworks?.reduce(
        (acc, framework) => {
          acc[framework.id] = framework.requirements.map((requirement) => ({
            id: requirement.clause,
            title: requirement.title,
          }));
          return acc;
        },
        {} as Record<string, { id: string; title: string }[]>
      ),
    [frameworks]
  );

  const clause = form.useStore((state) => state.values.clause);
  useEffect(() => {
    const clauses = clausesByFramework?.[form.state.values.frameworkId];
    // TODO: Should optimize this
    const selectedClause = clauses?.find((c) => c.id === clause);
    const title = selectedClause?.title;
    if (form.getFieldMeta("title")?.isPristine && title) {
      form.setFieldValue("title", title);
    }
  }, [form, clause, clausesByFramework]);

  return (
    <Dialog.Root open={open} onOpenChange={handleOpenChange}>
      <Dialog.Trigger>
        <PermissionedButton permission="write.processes">
          <IconPlus />
          Add control
        </PermissionedButton>
      </Dialog.Trigger>

      <Dialog.Content maxWidth="600px">
        <Dialog.Title>Add control</Dialog.Title>
        <Form.Root reactForm={form}>
          <Form.Fields>
            <Grid columns="2" gap="4">
              <Form.Select<AddControlFormInputs>
                reactFormField={{ form, name: "frameworkId" }}
                fieldProps={{
                  label: "Framework",
                }}
              >
                <Select.Content>
                  <Select.Item value="AS9100D">AS9100D</Select.Item>
                </Select.Content>
              </Form.Select>

              <Form.Select<AddControlFormInputs>
                reactFormField={{ form, name: "clause" }}
                fieldProps={{
                  label: "Clause",
                }}
              >
                <form.Subscribe
                  selector={(state) => [state.values.frameworkId]}
                  children={([frameworkId]) => {
                    const clauses = clausesByFramework?.[frameworkId];
                    const selectedClauseIds = process.controls.map(
                      (c) => c.clause
                    );
                    const sortedClauses = clauses
                      ?.filter((c) => !selectedClauseIds.includes(c.id))
                      .sort((a, b) => compareClauses(a.id, b.id));
                    return (
                      <Select.Content>
                        {sortedClauses?.map((clause) => (
                          <Select.Item value={clause.id}>
                            {clause.id} - {clause.title}
                          </Select.Item>
                        ))}
                      </Select.Content>
                    );
                  }}
                />
              </Form.Select>
            </Grid>

            <Form.TextField<AddControlFormInputs>
              reactFormField={{ form, name: "title" }}
              fieldProps={{
                label: "Title",
                required: true,
              }}
            />

            <Form.TextArea<AddControlFormInputs>
              reactFormField={{ form, name: "language" }}
              fieldProps={{
                label: "Language",
                required: true,
              }}
              rows={12}
            />
          </Form.Fields>

          <Form.Footer
            form={form}
            error={error}
            isPending={isPending}
            buttonText="Add control"
            errorPrefix="Failed to add control: "
          />
        </Form.Root>
      </Dialog.Content>
    </Dialog.Root>
  );
};
