import { BaseFileUpload, BaseFileUploadProps } from "./BaseFileUpload";
import { BaseOptions } from "./BaseOptions";
import { BaseSegmentedControl } from "./BaseSegmentedControl";
import { BaseSwitch, BaseSwitchProps } from "./BaseSwitch";
import { BaseTextArea, BaseTextAreaProps } from "./BaseTextArea";
import { BaseTextField, BaseTextFieldProps } from "./BaseTextField";
import { BaseFileDropzone } from "./FileDropzone";
import { Footer } from "./Footer";
import { FormField } from "./FormField";
import { FormFields } from "./FormFields";
import { FormRoot, FormRootProps } from "./FormRoot";
import { BaseMultiSelect } from "./multiselect/BaseMultiSelect";
import { GroupMultiSelect } from "./multiselect/GroupMultiSelect";
import { RecordMultiSelect } from "./multiselect/RecordMultiSelect";
import { UserMultiSelect } from "./multiselect/UserMultiSelect";
import { BaseSelect, BaseSelectProps } from "./select/BaseSelect";
import { FieldTypeSelect } from "./select/FieldTypeSelect";
import { ProcessSelect } from "./select/ProcessSelect";
import { RecordSelect } from "./select/RecordSelect";
import { RecordTypeSelect } from "./select/RecordTypeSelect";
import { UserSelect, UserSelectProps } from "./select/UserSelect";
import { FormFieldProps } from "./types";

const Form = {
  Root: FormRoot,
  Fields: FormFields,
  Field: FormField,
  TextField: BaseTextField,
  TextArea: BaseTextArea,
  Switch: BaseSwitch,
  SegmentedControl: BaseSegmentedControl,
  FileUpload: BaseFileUpload,
  FileDropzone: BaseFileDropzone,

  Select: BaseSelect,
  UserSelect: UserSelect,
  ProcessSelect: ProcessSelect,
  FieldTypeSelect: FieldTypeSelect,
  RecordSelect: RecordSelect,
  RecordMultiSelect: RecordMultiSelect,
  RecordTypeSelect: RecordTypeSelect,
  MultiSelect: BaseMultiSelect,
  UserMultiSelect: UserMultiSelect,
  GroupMultiSelect: GroupMultiSelect,

  Options: BaseOptions,

  Footer: Footer,
};
export type {
  FormFieldProps as FieldProps,
  BaseFileUploadProps as FileUploadProps,
  FormRootProps as RootProps,
  BaseSelectProps as SelectProps,
  BaseSwitchProps as SwitchProps,
  BaseTextAreaProps as TextAreaProps,
  BaseTextFieldProps as TextFieldProps,
  UserSelectProps,
};

export default Form;
