import { StatusIcon } from "@/components/statuses/StatusIcon";
import { ActionOperation } from "@/types/actions";
import { Status, toRadixColor } from "@/types/status";
import { meridianNanoid } from "@/utils/meridianNanoid";
import { IconButton } from "@radix-ui/themes";
import { IconPlus } from "@tabler/icons-react";
import {
  Node,
  NodeProps,
  NodeToolbar,
  Position,
  XYPosition,
} from "@xyflow/react";
import { useWorkflowEditorContext } from "../WorkflowEditorContext";
import { createActionNode } from "./ActionNode";
import BaseNode from "./BaseNode";

export type StatusNodeData = Omit<Status, "id">;
export type StatusNodeType = Node<StatusNodeData, "status">;

export const createStatusNode = (position?: XYPosition): StatusNodeType => {
  const id = meridianNanoid();
  return {
    id,
    type: "status",
    data: {
      value: "New status",
      display: {
        icon: "circle",
        color: "blue",
      },
    },
    position: {
      x: 0,
      y: 0,
      ...position,
    },
  };
};

export default function StatusNode(props: NodeProps<StatusNodeType>) {
  const { id, data, positionAbsoluteX, positionAbsoluteY } = props;
  const { recordTypeId, addNode, addEdge } = useWorkflowEditorContext();
  const color = data.display?.color;
  const radixColor = toRadixColor(color);

  const backgroundColor = `var(--${radixColor}-9)`;
  const borderColor = `var(--${radixColor}-9)`;
  const shadowColor = `var(--${radixColor}-a6)`;
  return (
    <>
      <BaseNode
        {...props}
        baseNodeProps={{
          title: data.value,
          icon: <StatusIcon icon={data.display?.icon} />,
          color: "var(--accent-contrast)",
          backgroundColor,
          borderColor,
          shadowColor,
        }}
      />
      <NodeToolbar position={Position.Bottom}>
        <IconButton
          onClick={() => {
            const actionNode = createActionNode({
              recordTypeId,
              operation: ActionOperation.update({}),
              position: { x: positionAbsoluteX, y: positionAbsoluteY + 100 },
            });
            addNode(actionNode, true);
            addEdge({
              id: meridianNanoid(),
              source: id,
              target: actionNode.id,
            });
          }}
        >
          <IconPlus />
        </IconButton>
      </NodeToolbar>
    </>
  );
}

