import Form from "@/components/forms";
import { PermissionedButton } from "@/components/PermissionedButton";
import { useCreateProcess } from "@/state/mutations/processes/createProcess";
import { useUser } from "@/state/queries/user";
import { Dialog, Text, TextField } from "@radix-ui/themes";
import { IconPlus } from "@tabler/icons-react";
import { useForm } from "@tanstack/react-form";
import { zodValidator } from "@tanstack/zod-form-adapter";
import { useState } from "react";
import { z } from "zod";

interface AddProcessFormInputs {
  id: string;
  name: string;
  description: string;
  owner: string;
}

export const AddProcessAction: React.FC = () => {
  const [open, setOpen] = useState(false);
  const { data: user } = useUser();
  const { mutate: createProcess, error, reset, isPending } = useCreateProcess();

  const handleOpenChange = (open: boolean) => {
    setOpen(open);
    form.reset();
    reset();
  };

  const form = useForm({
    defaultValues: {
      id: "",
      name: "",
      description: "",
      owner: user?.id ?? "",
    },
    validatorAdapter: zodValidator(),
    validators: {
      onChange: z.object({
        id: z.coerce
          .number()
          .int()
          .positive({ message: "Must be a positive integer number" }),
        name: z.string().min(1, { message: "Process name is required" }),
      }),
    },
    onSubmit: ({ value }) => {
      createProcess(
        {
          id: `P-${value.id}`,
          name: value.name,
          description: value.description,
          owner: value.owner,
        },
        { onSuccess: () => setOpen(false) }
      );
    },
  });

  return (
    <Dialog.Root open={open} onOpenChange={handleOpenChange}>
      <Dialog.Trigger>
        <PermissionedButton permission="write.processes">
          <IconPlus />
          New process
        </PermissionedButton>
      </Dialog.Trigger>
      <Dialog.Content maxWidth="500px">
        <Dialog.Title>Create process</Dialog.Title>
        <Form.Root reactForm={form}>
          <Form.Fields>
            <Form.TextField<AddProcessFormInputs>
              reactFormField={{ form, name: "id" }}
              fieldProps={{
                label: "Compliance identifier",
                required: true,
              }}
              type="number"
              placeholder="830"
            >
              <TextField.Slot>
                <Text>P-</Text>
              </TextField.Slot>
            </Form.TextField>
            <Form.TextField<AddProcessFormInputs>
              reactFormField={{ form, name: "name" }}
              fieldProps={{ label: "Process name", required: true }}
              placeholder="Enter process name"
            />
            <Form.TextArea<AddProcessFormInputs>
              reactFormField={{ form, name: "description" }}
              fieldProps={{ label: "Description" }}
              placeholder="Enter description"
            />
            <Form.UserSelect<AddProcessFormInputs>
              reactFormField={{ form, name: "owner" }}
              fieldProps={{ label: "Owner", required: true }}
            />
          </Form.Fields>
          <Form.Footer
            form={form}
            error={error}
            isPending={isPending}
            buttonText="Create process"
            errorPrefix="Failed to create process: "
          />
        </Form.Root>
      </Dialog.Content>
    </Dialog.Root>
  );
};
