import { RecordFieldTypeLabel } from "@/components/record-types/RecordFieldTypeLabel";
import {
  RecordFieldBaseType,
  recordFieldBaseTypeToFieldType,
  RecordFieldType,
} from "@/types/recordFields";
import { Select } from "@radix-ui/themes";
import { DeepKeys, useField } from "@tanstack/react-form";
import { PropsWithChildren } from "react";
import { FormField } from "../FormField";
import { hasError } from "../utils";
import { BaseSelectProps } from "./BaseSelect";
import "./FieldTypeSelect.css";
export interface FieldTypeSelectProps<TData> extends BaseSelectProps<TData> {}

export function FieldTypeSelect<TData>(
  props: PropsWithChildren<FieldTypeSelectProps<TData>>
) {
  const { reactFormField, fieldProps, rootProps, triggerProps } = props;

  const field = useField<TData, DeepKeys<TData>>(reactFormField);

  const value = field.state.value as RecordFieldType;

  const submissionAttempts = reactFormField.form.useStore(
    (form) => form.submissionAttempts
  );
  const error = hasError(field.state.meta, submissionAttempts);
  return (
    <FormField
      reactForm={reactFormField.form}
      reactFormField={field}
      {...fieldProps}
    >
      <Select.Root
        name={field.name as string}
        value={value.type}
        onValueChange={(value) => {
          field.handleChange(
            recordFieldBaseTypeToFieldType[value as RecordFieldBaseType] as any
          );
        }}
        size={fieldProps.size ?? "3"}
        {...rootProps}
      >
        <Select.Trigger
          style={{
            boxShadow: error ? "inset 0 0 0 2px var(--red-a7)" : undefined,
            width: "100%",
          }}
          {...triggerProps}
        />
        <Select.Content>
          {Object.keys(recordFieldBaseTypeToFieldType)
            // Disallow selecting field types that are only for system use
            .filter((fieldType) => fieldType !== "id" && fieldType !== "status")
            .map((fieldType) => (
              <Select.Item key={fieldType} value={fieldType}>
                <RecordFieldTypeLabel
                  type={fieldType as RecordFieldBaseType}
                  badgeClassName="field-type-select-field-type-icon"
                />
              </Select.Item>
            ))}
        </Select.Content>
      </Select.Root>
    </FormField>
  );
}
