import { useTenantContext } from "@/contexts/TenantContext";
import { supabase } from "@/supabaseClient";
import { queryOptions, useQuery } from "@tanstack/react-query";

/**
 * Fetches a list of groupIds that the user is a member of within the given tenant.
 */
const fetchUserGroups = async (
  userId: string,
  tenantId: string
): Promise<string[]> => {
  const { data, error } = await supabase
    .schema("tenants")
    .from("tenant_group_members")
    .select("group_id")
    .eq("user_id", userId)
    .eq("tenant_id", tenantId);

  if (error) {
    throw error;
  }

  if (!data) {
    return [];
  }

  return data.map((membership) => membership.group_id);
};

export const useUserGroupsQueryOptions = (userId: string, tenantId: string) =>
  queryOptions({
    queryKey: ["userGroups", userId, tenantId],
    queryFn: () => fetchUserGroups(userId, tenantId),
    staleTime: 1000 * 60 * 5, // 5 minutes
    retry: false,
  });

export const useUserGroups = (userId: string) => {
  const { tenant } = useTenantContext();
  if (!tenant) {
    throw new Error("Tenant not found");
  }
  return useQuery(useUserGroupsQueryOptions(userId, tenant.tenantId));
};
