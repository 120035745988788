import { useUser } from "@/state/queries/user";
import posthog from "posthog-js";
import { useEffect } from "react";

/**
 * Identifies the user with PostHog for analytics.
 */
export function usePosthogIdentify() {
  const { data: user } = useUser();

  useEffect(() => {
    if (user?.id) {
      posthog.identify(user.id, {
        email: user.email,
        name: user.name,
      });
    }
  }, [user?.id, user?.email, user?.name]);
}
