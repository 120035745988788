import { Select } from "@radix-ui/themes";
import { DeepKeys, useField } from "@tanstack/react-form";
import { PropsWithChildren } from "react";
import { FormField } from "../FormField";
import { BaseFieldProps } from "../types";
import { hasError } from "../utils";

export interface BaseSelectProps<TData> extends BaseFieldProps<TData> {
  rootProps?: Select.RootProps;
  triggerProps?: Select.TriggerProps;
}

export function BaseSelect<TData>(
  props: PropsWithChildren<BaseSelectProps<TData>>
) {
  const { reactFormField, fieldProps, children, rootProps, triggerProps } =
    props;

  const field = useField<TData, DeepKeys<TData>>(reactFormField);

  const submissionAttempts = reactFormField.form.useStore(
    (form) => form.submissionAttempts
  );
  const error = hasError(field.state.meta, submissionAttempts);
  return (
    <FormField
      reactForm={reactFormField.form}
      reactFormField={field}
      {...fieldProps}
    >
      <Select.Root
        name={field.name as string}
        value={field.state.value as string}
        onValueChange={(value) => field.handleChange(value as any)}
        size={fieldProps.size ?? "3"}
        {...rootProps}
      >
        <Select.Trigger
          style={{
            boxShadow: error ? "inset 0 0 0 2px var(--red-a7)" : undefined,
            width: "100%",
          }}
          {...triggerProps}
        />
        {children}
      </Select.Root>
    </FormField>
  );
}
