import EmptyPage from "@/components/EmptyPage";
import { IconBell } from "@tabler/icons-react";
import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute(
  "/_app/$tenantSlug/settings/account/notifications"
)({
  beforeLoad: () => ({ getTitle: () => "Notifications" }),
  component: () => <EmptyPage icon={<IconBell />} title="Notifications" />,
});
