import { MultiSelect } from "@/components/multiselect/MultiSelect";
import { TenantUser, useTenantUsers } from "@/state/queries/tenantUsers";
import { Text } from "@radix-ui/themes";
import { IconUserFilled } from "@tabler/icons-react";
import { DeepKeys, useField } from "@tanstack/react-form";
import { PropsWithChildren } from "react";
import { FormField } from "../FormField";
import { BaseFieldProps } from "../types";

export interface UserMultiSelectProps<TData> extends BaseFieldProps<TData> {}

export function UserMultiSelect<TData>(
  props: PropsWithChildren<UserMultiSelectProps<TData>>
) {
  const { reactFormField, fieldProps } = props;
  const field = useField<TData, DeepKeys<TData>>(reactFormField);
  const { data: users } = useTenantUsers();

  const options = Object.values(users ?? {}).map((user) => ({
    label: user.fullName ?? user.email,
    value: user.userId,
    data: user,
  }));

  const value = (field.state.value as string[]) ?? [];

  return (
    <FormField
      reactForm={reactFormField.form}
      reactFormField={field}
      {...fieldProps}
    >
      <MultiSelect<TenantUser>
        selectedValues={value}
        onChange={(value) => field.setValue(value as any)}
        options={options}
        getOptionLabel={(option) => (
          <>
            <IconUserFilled />
            <Text truncate style={{ maxWidth: 400 }}>
              {option.label}
            </Text>
          </>
        )}
      />
    </FormField>
  );
}
