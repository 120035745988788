import { useTenantContext } from "@/contexts/TenantContext";
import { supabase } from "@/supabaseClient";
import { queryOptions, useQuery, UseQueryResult } from "@tanstack/react-query";

export interface ProcessControl {
  frameworkId: string;
  clause: string;
  title: string;
  language: string;
}

export interface Process {
  id: string;
  createdAt: string;
  createdBy: string;
  description?: string;
  name: string;
  ownedBy: string;
  tenantId: string;
  updatedAt: string;
  updatedBy: string;
  controls: ProcessControl[];
}

const fetchProcesses = async (tenantId: string): Promise<Process[]> => {
  const { data, error } = await supabase
    .from("processes")
    .select(
      `
      *,
      controls (
        title,
        language,
        compliance_framework_requirements (
          framework_id,
          clause,
          title
        )
      )
    `
    )
    .eq("tenant_id", tenantId);

  if (error) {
    throw error;
  }

  if (!data) {
    return [];
  }

  return data.map((process) => ({
    id: process.id,
    createdAt: process.created_at,
    createdBy: process.created_by,
    description: process.description ?? undefined,
    name: process.name,
    ownedBy: process.owned_by,
    tenantId: process.tenant_id,
    updatedAt: process.updated_at,
    updatedBy: process.updated_by,
    controls: process.controls.map((control) => ({
      frameworkId:
        control?.compliance_framework_requirements?.framework_id ?? "",
      clause: control?.compliance_framework_requirements?.clause ?? "",
      title: control?.title ?? "",
      language: control?.language ?? "",
    })),
  }));
};

export const processesQueryOptions = (tenantId: string) =>
  queryOptions({
    queryKey: ["processes", tenantId],
    queryFn: () => fetchProcesses(tenantId),
    staleTime: 1000 * 60 * 5,
    retry: false,
  });

export const useProcesses = (): UseQueryResult<Process[]> => {
  const tenant = useTenantContext();
  if (!tenant.tenant) {
    throw new Error("Tenant not found");
  }
  return useQuery(processesQueryOptions(tenant.tenant.tenantId));
};

export const useProcess = (processId: string) => {
  const { data: processes, ...rest } = useProcesses();

  return {
    ...rest,
    data: processes?.find((process) => process.id === processId),
  };
};
