import NonIdealState from "@/components/NonIdealState";
import { RecordFieldsDataList } from "@/components/records/RecordFieldsDataList";
import TabTitle from "@/components/TabTitle";
import { useRecordTypeById } from "@/state/queries/recordLinkTypes";
import { useRecord } from "@/state/queries/records";
import { IconListDetails } from "@tabler/icons-react";
import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute(
  "/_app/$tenantSlug/records/$recordTypeId/$recordId/fields"
)({
  beforeLoad: () => ({ getTitle: () => "" }),
  component: RecordFields,
});

function RecordFields() {
  const { recordTypeId, recordId } = Route.useParams();
  const { data: recordType } = useRecordTypeById(recordTypeId);
  const { data: record } = useRecord(recordTypeId, recordId);

  if (!recordType || !record) {
    return (
      <NonIdealState
        title="Record fields not found"
        icon={<IconListDetails />}
      />
    );
  }

  return (
    <>
      <TabTitle title="Fields" />
      <RecordFieldsDataList
        recordType={recordType}
        record={record}
        displayOptions={{ fieldsToShow: "all" }}
      />
    </>
  );
}
