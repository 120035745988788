import { supabaseRecords } from "@/supabaseClient";
import { queryOptions, skipToken, useQuery } from "@tanstack/react-query";
import { keyBy } from "lodash-es";
import { useMemo } from "react";

async function fetchRecords(recordTypeId: string) {
  const { data, error } = await supabaseRecords
    // TODO make this schema dynamic based on the tenant
    .schema("records")
    // TODO see if we can make this more robust
    .from(recordTypeId as any)
    .select("*");

  if (error) {
    throw error;
  }

  return data || [];
}

export const recordsQueryOptions = (recordTypeId: string | undefined) =>
  queryOptions({
    queryKey: ["records", recordTypeId],
    queryFn: recordTypeId ? () => fetchRecords(recordTypeId) : skipToken,
    enabled: !!recordTypeId,
    retry: false,
    staleTime: 1000 * 60 * 1,
  });

export function useRecords(recordTypeId: string | undefined) {
  return useQuery(recordsQueryOptions(recordTypeId));
}

export function useRecord(recordTypeId: string | undefined, recordId: string) {
  const { data: records, ...rest } = useRecords(recordTypeId);

  const recordsById = useMemo(() => {
    return keyBy(records || [], "id");
  }, [records]);

  return {
    ...rest,
    data: recordsById[recordId],
  };
}
