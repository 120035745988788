import { useTenantContext } from "@/contexts/TenantContext";
import { supabase } from "@/supabaseClient";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export interface UpdateGroupMember {
  userId: string;
  action: "add" | "remove";
}

interface UpdateGroupInput {
  name: string;
  membersAdded: string[];
  membersRemoved: string[];
}

const updateGroup = async (
  input: UpdateGroupInput,
  groupId: string,
  tenantId: string
) => {
  const { error } = await supabase
    .schema("tenants")
    .from("tenant_groups")
    .update({
      name: input.name,
    })
    .eq("id", groupId)
    .eq("tenant_id", tenantId);

  if (error) {
    throw error;
  }

  if (input.membersAdded.length > 0) {
    await supabase
      .schema("tenants")
      .from("tenant_group_members")
      .insert(
        input.membersAdded.map((userId) => ({
          group_id: groupId,
          tenant_id: tenantId,
          user_id: userId,
        }))
      );
  }

  if (input.membersRemoved.length > 0) {
    await supabase
      .schema("tenants")
      .from("tenant_group_members")
      .delete()
      .eq("group_id", groupId)
      .eq("tenant_id", tenantId)
      .in("user_id", input.membersRemoved);
  }
};

export const useUpdateGroup = () => {
  const queryClient = useQueryClient();
  const tenant = useTenantContext();

  return useMutation({
    mutationFn: ({
      input,
      groupId,
    }: {
      input: UpdateGroupInput;
      groupId: string;
    }) => {
      if (!tenant.tenant) {
        throw new Error("Tenant not found");
      }
      return updateGroup(input, groupId, tenant.tenant.tenantId);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["tenantGroups", tenant.tenant?.tenantId],
      });
      queryClient.invalidateQueries({
        queryKey: ["userGroups", tenant.tenant?.tenantId],
      });
    },
  });
};
