import { supabase } from "@/supabaseClient";
import { skipToken, useQuery } from "@tanstack/react-query";

const FILE_URL_DURATION = 3600; // 1 hour
const FILE_URL_DURATION_MS = FILE_URL_DURATION * 1000;

export const fetchRecordFile = async (filePath: string) => {
  const { data, error } = await supabase.storage
    .from("record_files")
    .createSignedUrl(filePath, FILE_URL_DURATION);

  if (error) {
    throw error;
  }

  return data.signedUrl;
};

export const useRecordFile = (filePath: string | undefined) => {
  return useQuery({
    queryKey: ["recordFile", filePath],
    queryFn: filePath ? () => fetchRecordFile(filePath) : skipToken,
    staleTime: FILE_URL_DURATION_MS,
  });
};
