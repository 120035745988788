import { Button, ButtonProps } from "@radix-ui/themes";
import { PropsWithChildren } from "react";

export interface SubmitButtonProps extends ButtonProps {
  canSubmit: boolean;
  isSubmitting: boolean;
}

export const SubmitButton: React.FC<PropsWithChildren<SubmitButtonProps>> = ({
  canSubmit,
  isSubmitting,
  children,
  ...props
}) => {
  return (
    <Button
      type="submit"
      loading={isSubmitting}
      disabled={isSubmitting}
      size="3"
      {...props}
    >
      {children}
    </Button>
  );
};
