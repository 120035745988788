import { Link } from "@radix-ui/themes";
import { IconExternalLink } from "@tabler/icons-react";
import { EmptyValue } from "./EmptyValue";

export const StorageFileLink = ({
  filePath,
  fetchFile,
}: {
  filePath?: string;
  fetchFile: (filePath: string) => Promise<string | undefined>;
}) => {
  // Creates signed URL on the fly to avoid having to create URLs for every link
  const handleClick = async () => {
    if (!filePath) {
      return;
    }

    const signedUrl = await fetchFile(filePath);

    if (signedUrl) {
      window.open(signedUrl);
    }
  };

  if (!filePath) {
    return <EmptyValue />;
  }
  const displayName = filePath.split("/").pop();
  return (
    <Link
      onClick={handleClick}
      style={{
        display: "inline-flex",
        alignItems: "center",
        gap: "var(--space-1)",
        cursor: "pointer",
      }}
    >
      {displayName}
      <IconExternalLink />
    </Link>
  );
};
