import NonIdealState from "@/components/NonIdealState";
import { RecordFieldsDataList } from "@/components/records/RecordFieldsDataList";
import RelativeTime from "@/components/RelativeTime";
import User from "@/components/User";
import {
  RecordHistoryItem,
  useRecordHistory,
} from "@/state/queries/recordHistory";
import { useRecordTypeById } from "@/state/queries/recordLinkTypes";
import * as Collapsible from "@radix-ui/react-collapsible";
import { Button, Flex, Heading, Spinner, Text } from "@radix-ui/themes";
import {
  IconChevronDown,
  IconChevronUp,
  IconListDetails,
} from "@tabler/icons-react";
import { createFileRoute } from "@tanstack/react-router";
import dayjs from "dayjs";
import { useMemo, useState } from "react";

export const Route = createFileRoute(
  "/_app/$tenantSlug/records/$recordTypeId/$recordId/history"
)({
  beforeLoad: () => ({ getTitle: () => "History" }),
  component: RecordHistory,
});

function RecordHistory() {
  const { recordId } = Route.useParams();
  const { data: recordHistory } = useRecordHistory(recordId);

  const sortedRecordHistory = useMemo(() => {
    return (
      recordHistory?.sort((a, b) => {
        if (dayjs(a.timestamp).isBefore(dayjs(b.timestamp))) {
          return 1;
        }
        return -1;
      }) ?? []
    );
  }, [recordHistory]);

  if (!recordHistory) {
    return <NonIdealState icon={<Spinner />} title="Loading history" />;
  }

  return (
    <Flex direction="column" gap="4" mt="4">
      <Heading size="4">History</Heading>

      {recordHistory.length === 0 && (
        <NonIdealState icon={<IconListDetails />} title="No history" />
      )}
      {sortedRecordHistory.map((item) => (
        <RecordHistoryItemComponent key={item.eventId} item={item} />
      ))}
    </Flex>
  );
}

function RecordHistoryItemComponent({ item }: { item: RecordHistoryItem }) {
  const [isOpen, setIsOpen] = useState(false);
  const actionDescription =
    item.operation === "create"
      ? "created"
      : item.operation === "update"
        ? "updated"
        : "deleted";
  return (
    <Flex direction="column" gap="1">
      <Flex align="center" gap="1">
        <Text weight="bold">
          <User userId={item.userId} hoverOpenDelay={800} />
        </Text>
        <Text>{actionDescription} record</Text>
        <Text weight="bold">
          <RelativeTime timestamp={item.timestamp} />
        </Text>
      </Flex>
      <Flex direction="column" ml="7" pt="2">
        <Collapsible.Root open={isOpen} onOpenChange={setIsOpen}>
          <Collapsible.Trigger asChild>
            <Button variant="ghost" color="gray" size="1">
              Show changes
              {isOpen ? <IconChevronUp /> : <IconChevronDown />}
            </Button>
          </Collapsible.Trigger>
          <Collapsible.Content>
            <RecordHistoryItemFields item={item} />
          </Collapsible.Content>
        </Collapsible.Root>
      </Flex>
    </Flex>
  );
}

function RecordHistoryItemFields({ item }: { item: RecordHistoryItem }) {
  const { data: recordType } = useRecordTypeById(item.recordTypeId);
  if (!recordType) {
    return null;
  }

  if (item.operation === "update") {
    return (
      <Flex gap="4">
        <RecordFieldsDataList
          recordType={recordType}
          record={item.previousFieldValues}
          dataListProps={{ orientation: "vertical" }}
          displayOptions={{
            fieldsToShow: Object.keys(item.updatedFieldValues),
            itemDisplayStyle: "old",
          }}
        />
        <RecordFieldsDataList
          recordType={recordType}
          record={item.updatedFieldValues}
          dataListProps={{ orientation: "vertical" }}
          displayOptions={{ itemDisplayStyle: "new" }}
        />
      </Flex>
    );
  }

  return (
    <RecordFieldsDataList
      recordType={recordType}
      record={item.updatedFieldValues}
      dataListProps={{ orientation: "vertical" }}
      displayOptions={{ itemDisplayStyle: "new" }}
    />
  );
}
