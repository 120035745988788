import { HoverCard, Text } from "@radix-ui/themes";
import dayjs from "dayjs";
import React from "react";

const FormattedDate: React.FC<{ dateString: string }> = ({ dateString }) => {
  const humanReadable = dayjs(dateString).format("MMM D, YYYY");
  const rawTimestamp = dayjs(dateString).format("YYYY-MM-DD");

  return (
    <HoverCard.Root openDelay={600}>
      <HoverCard.Trigger>
        <Text style={{ cursor: "pointer" }}>{humanReadable}</Text>
      </HoverCard.Trigger>
      <HoverCard.Content>
        <Text>{rawTimestamp}</Text>
      </HoverCard.Content>
    </HoverCard.Root>
  );
};

export default FormattedDate;
