import { useTenantContext } from "@/contexts/TenantContext";
import { supabase } from "@/supabaseClient";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export interface EditProcessControlInput {
  processId: string;
  frameworkId: string;
  clause: string;
  title: string;
  language: string;
}

const editControl = async (
  input: EditProcessControlInput,
  tenantId: string
) => {
  const { error } = await supabase
    .from("controls")
    .update({
      language: input.language,
      title: input.title,
    })
    .eq("tenant_id", tenantId)
    .eq("process_id", input.processId)
    .eq("framework_id", input.frameworkId)
    .eq("clause", input.clause);

  if (error) {
    throw error;
  }
};

export const useEditControl = () => {
  const queryClient = useQueryClient();
  const tenant = useTenantContext();

  return useMutation({
    mutationFn: (input: EditProcessControlInput) => {
      if (!tenant.tenant) {
        throw new Error("Tenant not found");
      }
      return editControl(input, tenant.tenant.tenantId);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["processes", tenant.tenant?.tenantId],
      });
    },
  });
};
