import { SegmentedControl } from "@radix-ui/themes";
import { DeepKeys, useField } from "@tanstack/react-form";
import { PropsWithChildren } from "react";
import { FormField } from "./FormField";
import { BaseFieldProps } from "./types";
import { hasError } from "./utils";

export interface BaseSegmentedControlProps<TData>
  extends BaseFieldProps<TData> {
  rootProps?: SegmentedControl.RootProps;
}

export function BaseSegmentedControl<TData>(
  props: PropsWithChildren<BaseSegmentedControlProps<TData>>
) {
  const { reactFormField, fieldProps, children, rootProps } = props;

  const field = useField<TData, DeepKeys<TData>>(reactFormField);

  const submissionAttempts = reactFormField.form.useStore(
    (form) => form.submissionAttempts
  );
  const error = hasError(field.state.meta, submissionAttempts);
  hasError(field.state.meta, submissionAttempts);
  return (
    <FormField
      reactForm={reactFormField.form}
      reactFormField={field}
      {...fieldProps}
    >
      <SegmentedControl.Root
        value={field.state.value as string}
        onValueChange={(value) => field.handleChange(value as any)}
        size={fieldProps.size ?? "2"}
        style={{
          boxShadow: error ? "inset 0 0 0 2px var(--red-a7)" : undefined,
          width: "100%",
        }}
        {...rootProps}
      >
        {children}
      </SegmentedControl.Root>
    </FormField>
  );
}
