import { useTenantContext } from "@/contexts/TenantContext";
import { supabase } from "@/supabaseClient";
import { useMutation, useQueryClient } from "@tanstack/react-query";

interface UpdateOrgNameInput {
  name: string;
}

const updateOrgName = async (input: UpdateOrgNameInput, tenantId: string) => {
  const { error } = await supabase
    .schema("tenants")
    .from("tenants")
    .update({ name: input.name })
    .eq("id", tenantId);

  if (error) {
    throw error;
  }
};

export const useUpdateOrgName = () => {
  const queryClient = useQueryClient();
  const tenant = useTenantContext();

  return useMutation({
    mutationFn: (input: UpdateOrgNameInput) => {
      if (!tenant.tenant) {
        throw new Error("Tenant not found");
      }
      return updateOrgName(input, tenant.tenant.tenantId);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["tenants"] });
    },
  });
};
