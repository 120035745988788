import { RecordFieldType, type RecordField } from "./recordFields";

export const ID_RECORD_FIELD: RecordField = {
  id: "id",
  name: "ID",
  description: "The unique identifier for the record",
  type: RecordFieldType.id({}),
  origin: "system",
};

export const STATUS_RECORD_FIELD: RecordField = {
  id: "status",
  name: "Status",
  description: "The status of the record",
  type: RecordFieldType.status({ statuses: [] }),
  origin: "system",
};

export const CREATED_AT_RECORD_FIELD: RecordField = {
  id: "created_at",
  name: "Created at",
  description: "When the record was created",
  type: RecordFieldType.timestamp({}),
  origin: "system",
};

export const CREATED_BY_RECORD_FIELD: RecordField = {
  id: "created_by",
  name: "Created by",
  description: "The user who created the record",
  type: RecordFieldType.userId({}),
  origin: "system",
};

export const LAST_UPDATED_AT_RECORD_FIELD: RecordField = {
  id: "last_updated_at",
  name: "Last updated at",
  description: "When the record was last updated",
  type: RecordFieldType.timestamp({}),
  origin: "system",
};

export const LAST_UPDATED_BY_RECORD_FIELD: RecordField = {
  id: "last_updated_by",
  name: "Last updated by",
  description: "The user who last updated the record",
  type: RecordFieldType.userId({}),
  origin: "system",
};

export const SYSTEM_RECORD_FIELDS: RecordField[] = [
  ID_RECORD_FIELD,
  STATUS_RECORD_FIELD,
  CREATED_AT_RECORD_FIELD,
  CREATED_BY_RECORD_FIELD,
  LAST_UPDATED_AT_RECORD_FIELD,
  LAST_UPDATED_BY_RECORD_FIELD,
];

export const SYSTEM_RECORD_FIELD_IDS = SYSTEM_RECORD_FIELDS.map(
  (field) => field.id
);
