import { EmptyValue } from "@/components/EmptyValue";
import RelativeTime from "@/components/RelativeTime";
import { StorageFileLink } from "@/components/StorageFileLink";
import User from "@/components/User";
import { useStatuses } from "@/hooks/useStatuses";
import { fetchRecordFile } from "@/state/queries/recordFile";
import { RecordFieldType } from "@/types/recordFields";
import { RecordFieldValues } from "@/types/records";
import { RecordType } from "@/types/recordTypes";
import { Badge, Code, DataList, Flex } from "@radix-ui/themes";
import { CSSProperties } from "react";
import FormattedDate from "../FormattedDate";
import { MultilineTextValue } from "../MultilineTextValue";
import { StatusBadge } from "../statuses/StatusBadge";
import { RecordLink } from "./RecordLink";

interface RecordFieldsDataListDisplayOptions {
  fieldsToShow?: "all" | string[];
  itemDisplayStyle?: "new" | "old";
}

export function RecordFieldsDataList({
  recordType,
  record,
  displayOptions,
  dataListProps,
}: {
  recordType: RecordType;
  record: RecordFieldValues;
  displayOptions?: RecordFieldsDataListDisplayOptions;
  dataListProps?: DataList.RootProps;
}) {
  let fields;
  if (displayOptions?.fieldsToShow === "all") {
    fields = recordType.fields;
  } else if (displayOptions?.fieldsToShow) {
    fields = recordType.fields.filter((field) =>
      displayOptions?.fieldsToShow?.includes(field.id)
    );
  } else {
    fields = recordType.fields.filter((field) => field.id in record);
  }

  return (
    <DataList.Root {...dataListProps}>
      {fields.map((field) => {
        const rawValue = record[field.id];
        let dataValue = (
          <RecordFieldDataValue
            recordType={recordType}
            rawValue={rawValue}
            fieldType={field.type}
          />
        );
        if (displayOptions?.itemDisplayStyle) {
          dataValue = (
            <span
              style={{
                backgroundColor:
                  displayOptions.itemDisplayStyle === "old"
                    ? "var(--red-3)"
                    : "var(--green-3)",
                borderRadius: "var(--radius-1)",
              }}
            >
              {dataValue}
            </span>
          );
        }
        if (displayOptions?.itemDisplayStyle === "old") {
          dataValue = <s>{dataValue}</s>;
        }
        const valueStyle: CSSProperties | undefined = RecordFieldType.isText(
          field.type
        )
          ? { flexDirection: "column" }
          : undefined;
        return (
          <DataList.Item key={field.id}>
            <DataList.Label>{field.name}</DataList.Label>
            <DataList.Value style={valueStyle}>{dataValue}</DataList.Value>
          </DataList.Item>
        );
      })}
    </DataList.Root>
  );
}

function RecordFieldDataValue({
  recordType,
  fieldType,
  rawValue,
}: {
  recordType: RecordType;
  fieldType: RecordFieldType;
  rawValue: any;
}) {
  const statuses = useStatuses(recordType.id);
  if (!rawValue) {
    return <EmptyValue />;
  }

  return RecordFieldType.visit<React.ReactNode>(fieldType, {
    id: () => rawValue,
    status: () => {
      const status = statuses.find((s) => s.value === rawValue);
      if (!status) {
        return <Badge color="gray">{rawValue}</Badge>;
      }
      return <StatusBadge status={status} />;
    },
    text: () => <MultilineTextValue value={rawValue} />,
    number: () => <Code color="gray">{rawValue}</Code>,
    date: () => <FormattedDate dateString={rawValue} />,
    timestamp: () => <RelativeTime timestamp={rawValue} />,
    boolean: () => rawValue,

    values: (values) => {
      if (values.allowMultiple) {
        return (
          <Flex gap="2">
            {rawValue.map((value: string) => (
              <Badge key={value} color="gray">
                {value}
              </Badge>
            ))}
          </Flex>
        );
      }

      return <Badge color="gray">{rawValue}</Badge>;
    },
    userId: (userId) => {
      if (userId && userId.allowMultiple) {
        return (
          <Flex gap="2">
            {rawValue.map((userId: string) => (
              <User userId={userId} />
            ))}
          </Flex>
        );
      }

      return <User userId={rawValue} />;
    },
    file: ({ allowMultiple }) => {
      if (allowMultiple) {
        return (
          <Flex gap="2">
            {rawValue.map((filePath: string) => (
              <StorageFileLink
                key={filePath}
                filePath={filePath}
                fetchFile={fetchRecordFile}
              />
            ))}
          </Flex>
        );
      }
      return (
        <StorageFileLink filePath={rawValue} fetchFile={fetchRecordFile} />
      );
    },
    link: ({ allowMultiple, linkedRecordTypeId }) => {
      if (allowMultiple) {
        return (
          <Flex gap="2">
            {rawValue.map((recordId: string) => (
              <RecordLink
                key={recordId}
                recordTypeId={linkedRecordTypeId}
                recordId={recordId}
              />
            ))}
          </Flex>
        );
      }

      return (
        <RecordLink recordTypeId={linkedRecordTypeId} recordId={rawValue} />
      );
    },
  });
}

