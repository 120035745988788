import { MultiSelect } from "@/components/multiselect/MultiSelect";
import {
  TenantGroup,
  useTenantGroupsWithUserData,
} from "@/state/queries/tenantGroups";
import { Text } from "@radix-ui/themes";
import { IconUsersGroup } from "@tabler/icons-react";
import { DeepKeys, useField } from "@tanstack/react-form";
import { PropsWithChildren } from "react";
import { FormField } from "../FormField";
import { BaseFieldProps } from "../types";

export interface GroupMultiSelectProps<TData> extends BaseFieldProps<TData> {}

export function GroupMultiSelect<TData>(
  props: PropsWithChildren<GroupMultiSelectProps<TData>>
) {
  const { reactFormField, fieldProps } = props;
  const field = useField<TData, DeepKeys<TData>>(reactFormField);
  const { data: groups } = useTenantGroupsWithUserData();

  const options = Object.values(groups ?? {}).map((group) => ({
    label: group.name,
    value: group.id,
    data: group,
  }));

  const value = (field.state.value as string[]) ?? [];

  return (
    <FormField
      reactForm={reactFormField.form}
      reactFormField={field}
      {...fieldProps}
    >
      <MultiSelect<TenantGroup>
        selectedValues={value}
        onChange={(value) => field.setValue(value as any)}
        options={options}
        getOptionLabel={(option) => (
          <>
            <IconUsersGroup />
            <Text truncate style={{ maxWidth: 400 }}>
              {option.label}
            </Text>
          </>
        )}
      />
    </FormField>
  );
}
