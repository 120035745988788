import { Outlet, createFileRoute } from "@tanstack/react-router";

import EmptyPage from "@/components/EmptyPage";
import { TenantProvider } from "@/contexts/TenantContext";
import { useIsInitialLoading } from "@/hooks/useIsInitialLoading";
import { usePosthogIdentify } from "@/hooks/usePosthogIdentify";
import { useNoTenantRedirect } from "@/hooks/useTenantRedirect";
import { useUnauthenticatedRedirect } from "@/hooks/useUnauthenticatedRedirect";
import { AppLayout } from "@/layouts/AppLayout";
import { processesQueryOptions } from "@/state/queries/processes";
import { Tenant, useTenants } from "@/state/queries/tenants";
import { tenantUsersQueryOptions } from "@/state/queries/tenantUsers";
import { Spinner } from "@radix-ui/themes";

export const Route = createFileRoute("/_app/$tenantSlug")({
  component: Layout,
  // Add tenant data to router context for use in child routes
  beforeLoad: ({ context, params }): { tenant: Tenant | undefined } => {
    const tenants = context.tenants.data;
    return {
      tenant:
        tenants && tenants[params.tenantSlug]
          ? tenants[params.tenantSlug]
          : undefined,
    };
  },
  loader: ({ context }) => {
    if (!context.tenant) {
      return;
    }
    context.queryClient.ensureQueryData(
      processesQueryOptions(context.tenant.tenantId)
    );
    context.queryClient.ensureQueryData(
      tenantUsersQueryOptions(context.tenant.tenantId)
    );
  },
});

function Layout() {
  const { tenantSlug } = Route.useParams();
  const tenants = useTenants();
  const isLoading = useIsInitialLoading();
  useUnauthenticatedRedirect();
  useNoTenantRedirect();
  usePosthogIdentify();

  if (isLoading || tenants.isLoading || !tenants.data) {
    return <EmptyPage icon={<Spinner />} />;
  }

  const tenant = tenants.data[tenantSlug];

  return (
    <TenantProvider tenant={tenant}>
      <AppLayout>
        <Outlet />
      </AppLayout>
    </TenantProvider>
  );
}
