import { useTenantContext } from "@/contexts/TenantContext";
import { supabase } from "@/supabaseClient";
import { maybeConvertApiError } from "@/utils/requests";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export interface DeleteRecordTypeFieldRequest {
  tenantId: string;
  recordTypeId: string;
  fieldIds: string[];
}

const deleteRecordTypeFields = async (input: DeleteRecordTypeFieldRequest) => {
  const { error } = await supabase.functions.invoke("record-types/fields", {
    method: "DELETE",
    body: input,
  });

  if (error) {
    const apiError = await maybeConvertApiError(error);
    return Promise.reject(apiError);
  }
};
export const useDeleteRecordFields = () => {
  const queryClient = useQueryClient();
  const tenant = useTenantContext();

  return useMutation({
    mutationFn: ({
      recordTypeId,
      fieldIds,
    }: {
      recordTypeId: string;
      fieldIds: string[];
    }) => {
      if (!tenant.tenant) {
        throw new Error("Tenant not found");
      }
      return deleteRecordTypeFields({
        recordTypeId,
        fieldIds,
        tenantId: tenant.tenant.tenantId,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["recordAndLinkTypes", tenant.tenant?.tenantId],
      });
    },
  });
};
