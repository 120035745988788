/* eslint-disable @typescript-eslint/no-redeclare */
import { KeyedTypeUnion, createTypeUnion } from "keyed-type-union";

/**
 * Text fields
 */
export type TextDefaultValue = KeyedTypeUnion<{
  static: {
    value: string;
  };
  currentValue: {};
}>;
export const TextDefaultValue = createTypeUnion<TextDefaultValue>({
  static: undefined,
  currentValue: undefined,
});
export interface TextFormField {
  defaultValue: TextDefaultValue | undefined;
  multiline: boolean;
}

/**
 * Number fields
 */
export type NumberDefaultValue = KeyedTypeUnion<{
  static: {
    value: number;
  };
  currentValue: {};
}>;
export const NumberDefaultValue = createTypeUnion<NumberDefaultValue>({
  static: undefined,
  currentValue: undefined,
});
export interface NumberFormField {
  defaultValue: NumberDefaultValue | undefined;
}

/**
 * Date fields
 */
export type DateDefaultValue = KeyedTypeUnion<{
  static: {
    value: string;
  };
  currentDate: {};
  currentValue: {};
}>;
export const DateDefaultValue = createTypeUnion<DateDefaultValue>({
  static: undefined,
  currentDate: undefined,
  currentValue: undefined,
});
export interface DateFormField {
  defaultValue: DateDefaultValue | undefined;
}

/**
 * Timestamp fields
 */
export type TimestampDefaultValue = KeyedTypeUnion<{
  static: {
    value: string;
  };
  currentValue: {};
  currentTimestamp: {};
}>;
export const TimestampDefaultValue = createTypeUnion<TimestampDefaultValue>({
  static: undefined,
  currentValue: undefined,
  currentTimestamp: undefined,
});
export interface TimestampFormField {
  defaultValue: TimestampDefaultValue | undefined;
}

/**
 * Boolean fields
 */
export type BooleanDefaultValue = KeyedTypeUnion<{
  static: {
    value: boolean;
  };
  currentValue: {};
}>;
export const BooleanDefaultValue = createTypeUnion<BooleanDefaultValue>({
  static: undefined,
  currentValue: undefined,
});
export interface BooleanFormField {
  defaultValue: BooleanDefaultValue | undefined;
}

/**
 * Values fields
 */
export type ValuesDefaultValue = KeyedTypeUnion<{
  static: {
    value: string[];
  };
  currentValue: {};
}>;
export const ValuesDefaultValue = createTypeUnion<ValuesDefaultValue>({
  static: undefined,
  currentValue: undefined,
});
// TODO: Something is weird here with options - we'll always have to grab them from the record field
// Should we have a separate type for a values field that's unmapped?
export interface ValuesFormField {
  defaultValue: ValuesDefaultValue | undefined;
}

/**
 * Link fields
 */
// TODO: Need to handle multiple link values
export type LinkDefaultValue = KeyedTypeUnion<{
  static: {
    recordTypeId: string;
    recordId: string;
  };
  currentValue: {};
}>;
export const LinkDefaultValue = createTypeUnion<LinkDefaultValue>({
  static: undefined,
  currentValue: undefined,
});
export interface LinkFormField {
  defaultValue: LinkDefaultValue | undefined;
}

/**
 * File fields
 */
export type FileDefaultValue = KeyedTypeUnion<{
  currentValue: {};
}>;
export const FileDefaultValue = createTypeUnion<FileDefaultValue>({
  currentValue: undefined,
});
export interface FileFormField {
  defaultValue: FileDefaultValue | undefined;
}

/**
 * User ID fields
 */
export type UserIdDefaultValue = KeyedTypeUnion<{
  static: {
    value: string[];
  };
  currentUser: {};
  currentValue: {};
}>;
export const UserIdDefaultValue = createTypeUnion<UserIdDefaultValue>({
  static: undefined,
  currentUser: undefined,
  currentValue: undefined,
});
export interface UserIdFormField {
  defaultValue: UserIdDefaultValue | undefined;
}

export type ActionFormFieldTypeData = KeyedTypeUnion<{
  text: TextFormField;
  number: NumberFormField;
  date: DateFormField;
  timestamp: TimestampFormField;
  boolean: BooleanFormField;
  values: ValuesFormField;
  link: LinkFormField;
  file: FileFormField;
  userId: UserIdFormField;
}>;
export const ActionFormFieldTypeData = createTypeUnion<ActionFormFieldTypeData>(
  {
    text: undefined,
    number: undefined,
    values: undefined,
    link: undefined,
    date: undefined,
    timestamp: undefined,
    boolean: undefined,
    file: undefined,
    userId: undefined,
  }
);

export interface ActionFormField {
  /**
   * The ID of the form field.
   */
  id: string;

  /**
   * The name of the form field. Shown to end users in the form.
   */
  name: string;

  /**
   * A description of the form field.
   */
  description: string;

  /**
   * The ID of the record field that this form field is mapped to.
   * In the future, we may want to support unmapped form fields
   */
  recordFieldId: string;

  /**
   * Whether an input for this form field is required.
   */
  required: boolean;

  /**
   * Data type dependent configuration for the form field.
   */
  type: ActionFormFieldTypeData;
}
