import { createFileRoute, redirect } from "@tanstack/react-router";

export const Route = createFileRoute(
  "/_app/$tenantSlug/settings/organization/"
)({
  beforeLoad: ({ params }) => {
    throw redirect({
      to: `/${params.tenantSlug}/settings/organization/general`,
      replace: true,
    });
  },
});
