import { useTenantContext } from "@/contexts/TenantContext";
import { supabase } from "@/supabaseClient";
import { RecordFieldValues } from "@/types/records";
import { queryOptions, useQuery } from "@tanstack/react-query";
export interface RecordHistoryItem {
  eventId: string;
  recordTypeId: string;
  actionId: string;
  workflowId: string;
  recordId: string;
  operation: string;
  timestamp: string;
  userId: string;
  previousFieldValues: RecordFieldValues;
  updatedFieldValues: RecordFieldValues;
}

async function fetchRecordHistory(
  tenantId: string,
  recordId: string
): Promise<RecordHistoryItem[]> {
  const { data, error } = await supabase
    .from("record_history")
    .select("*")
    .eq("tenant_id", tenantId)
    .eq("record_id", recordId);

  if (error) {
    throw error;
  }

  if (!data) {
    return [];
  }

  return data.map((item) => ({
    eventId: item.event_id,
    recordTypeId: item.record_type_id,
    actionId: item.action_id ?? "",
    operation: item.operation,
    workflowId: item.workflow_id ?? "",
    recordId: item.record_id,
    timestamp: item.timestamp,
    userId: item.user_id,
    // TODO: Validate the shape of the field values
    previousFieldValues: (item.previous_field_values ??
      {}) as RecordFieldValues,
    updatedFieldValues: (item.updated_field_values ?? {}) as RecordFieldValues,
  }));
}

export const recordHistoryQueryOptions = (tenantId: string, recordId: string) =>
  queryOptions({
    queryKey: ["recordHistory", tenantId, recordId],
    queryFn: () => fetchRecordHistory(tenantId, recordId),
    enabled: !!tenantId && !!recordId,
    retry: false,
    staleTime: 0,
  });

export function useRecordHistory(recordId: string) {
  const tenant = useTenantContext();
  if (!tenant.tenant) {
    throw new Error("Tenant not found");
  }
  return useQuery(recordHistoryQueryOptions(tenant.tenant.tenantId, recordId));
}
