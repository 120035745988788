import AppToolbar from "@/components/AppToolbar";
import Sidebar from "@/components/sidebar/Sidebar";
import { useTenantContext } from "@/contexts/TenantContext";
import { Container, Flex } from "@radix-ui/themes";
import { useLocation } from "@tanstack/react-router";

export const AppLayout: React.FC<React.PropsWithChildren> = (props) => {
  const { tenant } = useTenantContext();
  const location = useLocation();
  const isRecords = location.pathname.startsWith(`/${tenant.slug}/records/`);
  return (
    <Flex direction="row" height="100vh">
      <Sidebar />
      <Flex direction="column" flexGrow="1" overflowY="scroll">
        <AppToolbar />
        {isRecords ? (
          props.children
        ) : (
          <Container
            p="5"
            align={{ sm: "left", md: "center" }}
            size={isRecords ? "4" : { md: "2", lg: "3", xl: "4" }}
          >
            {props.children}
          </Container>
        )}
      </Flex>
    </Flex>
  );
};
