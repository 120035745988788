import { Badge, IconButton, Popover, Text } from "@radix-ui/themes";
import { IconX } from "@tabler/icons-react";
import { Command } from "cmdk";

import isEqual from "lodash-es/isEqual";
import { useMemo } from "react";
import css from "./MultiSelect.module.scss";

export interface MultiSelectOption<TData> {
  label: string;
  value: string;
  data: TData;
}

interface MultiSelectProps<TData> {
  /** Currently selected values */
  selectedValues: string[];
  /** Callback when selection changes */
  onChange: (value: string[]) => void;
  /** Available options to select from */
  options: MultiSelectOption<TData>[];
  /** Custom function to get the display label for an option */
  getOptionLabel?: (option: MultiSelectOption<TData>) => React.ReactNode;
  /** Custom comparison function for checking if two values are equal */
  compareValues?: (a: string, b: string) => boolean;
  /** Custom render function for an option in the dropdown */
  renderOption?: (option: MultiSelectOption<TData>) => React.ReactNode;
  /** Custom render function for a selected item */
  renderSelectedItem?: (
    option: MultiSelectOption<TData>,
    onRemove: () => void
  ) => React.ReactNode;
}

export function MultiSelect<TData>(props: MultiSelectProps<TData>) {
  const {
    selectedValues,
    onChange,
    options,
    getOptionLabel = (option) => (
      <Text truncate style={{ maxWidth: 400 }}>
        {option.label}
      </Text>
    ),
    compareValues = isEqual,
    renderOption,
    renderSelectedItem,
  } = props;

  const availableOptions = options.filter(
    (option) => !selectedValues.some((v) => compareValues(v, option.value))
  );

  const optionsMap = useMemo(
    () => new Map(options.map((opt) => [opt.value, opt])),
    [options]
  );

  const handleSelectItem = (selectedValue: string) => {
    const option = optionsMap.get(selectedValue);
    if (option) {
      onChange([...selectedValues, option.value]);
    }
  };

  const handleRemoveItem = (itemValue: string) => {
    onChange(selectedValues.filter((v) => !compareValues(v, itemValue)));
  };

  const handleClearAll = () => {
    onChange([]);
  };

  const defaultRenderOption = (option: MultiSelectOption<TData>) => (
    <Command.Item
      key={option.value}
      value={option.value}
      onSelect={() => handleSelectItem(option.value)}
    >
      {getOptionLabel(option)}
    </Command.Item>
  );

  const defaultRenderSelectedItem = (
    option: MultiSelectOption<TData>,
    onRemove: () => void
  ) => (
    <Badge key={option.value} size="1">
      {getOptionLabel(option)}
      <IconButton
        size="1"
        variant="ghost"
        onClick={(e) => {
          e.stopPropagation();
          onRemove();
        }}
      >
        <IconX />
      </IconButton>
    </Badge>
  );

  return (
    <Popover.Root>
      <Popover.Trigger>
        <div className={css.trigger}>
          {selectedValues.length === 0 && (
            <Text className={css.placeholder}>Search...</Text>
          )}
          {selectedValues.length > 0 && (
            <div className={css.selectedContent}>
              <div className={css.badges}>
                {selectedValues.map((val) => {
                  const option = options.find((opt) =>
                    compareValues(opt.value, val)
                  );
                  if (!option) return null;

                  return renderSelectedItem
                    ? renderSelectedItem(option, () => handleRemoveItem(val))
                    : defaultRenderSelectedItem(option, () =>
                        handleRemoveItem(val)
                      );
                })}
              </div>
              <IconButton
                size="1"
                variant="ghost"
                color="gray"
                onClick={(e) => {
                  e.stopPropagation();
                  handleClearAll();
                }}
              >
                <IconX />
              </IconButton>
            </div>
          )}
        </div>
      </Popover.Trigger>

      <Popover.Content style={{ padding: 0 }}>
        <Command className={css.command}>
          <Command.Input placeholder="Search..." />
          <Command.List>
            {availableOptions.map((option) =>
              renderOption ? renderOption(option) : defaultRenderOption(option)
            )}
            <Command.Empty>No items found</Command.Empty>
          </Command.List>
        </Command>
      </Popover.Content>
    </Popover.Root>
  );
}
