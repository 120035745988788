import MeridianTabNav from "@/components/MeridianTabNav";
import PageTitle from "@/components/PageTitle";
import { Flex } from "@radix-ui/themes";
import { IconUser, IconUsersGroup } from "@tabler/icons-react";
import { createFileRoute, Outlet } from "@tanstack/react-router";

export const Route = createFileRoute(
  "/_app/$tenantSlug/settings/organization/members"
)({
  beforeLoad: () => ({ getTitle: () => "Members" }),
  component: Members,
});

function Members() {
  const { tenantSlug } = Route.useParams();

  return (
    <Flex direction="column" gap="2">
      <PageTitle
        title="Members"
        description="Manage and invite team members, define organizational groups, and set access levels."
      />
      <MeridianTabNav
        childRouteParams={{ tenantSlug }}
        links={[
          {
            to: "/$tenantSlug/settings/organization/members/users",
            label: "Users",
            icon: <IconUser />,
          },
          {
            to: "/$tenantSlug/settings/organization/members/groups",
            label: "Groups",
            icon: <IconUsersGroup />,
          },
        ]}
      />
      <Outlet />
    </Flex>
  );
}
