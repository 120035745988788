import { AddOrEditFieldAction } from "@/components/record-types/AddOrEditFieldAction";
import { DeleteFieldsAction } from "@/components/record-types/DeleteFieldsAction";
import { RecordFieldTypeLabel } from "@/components/record-types/RecordFieldTypeLabel";
import { BaseAgGrid } from "@/components/tables/BaseAgGrid";
import TabTitle from "@/components/TabTitle";
import { useTenantContext } from "@/contexts/TenantContext";
import { useRecordTypeById } from "@/state/queries/recordLinkTypes";
import { RecordField, RecordFieldType } from "@/types/recordFields";
import { Badge, Flex } from "@radix-ui/themes";
import { createFileRoute } from "@tanstack/react-router";
import {
  ColDef,
  IRowNode,
  RowSelectionOptions,
  SelectionChangedEvent,
} from "ag-grid-community";
import { capitalize } from "lodash-es";
import { useState } from "react";

export const Route = createFileRoute(
  "/_app/$tenantSlug/settings/organization/record-types/$recordTypeId/"
)({
  beforeLoad: () => ({ getTitle: () => "" }),
  component: RecordTypeFields,
});

function RecordTypeFields() {
  const { recordTypeId } = Route.useParams();
  const { data: recordType } = useRecordTypeById(recordTypeId);
  const { tenant } = useTenantContext();
  const canEditFields = tenant.userPermissions.has("write.processes");
  const [selectedRows, setSelectedRows] = useState<any[]>([]);

  const columnDefs: ColDef[] = [
    { field: "name", headerName: "Name", width: 140 },
    {
      field: "origin",
      headerName: "Origin",
      width: 100,
      valueGetter: ({ data }) => data.origin ?? "custom",
      cellRenderer: ({ value }: { value: string }) => (
        <Badge color={value === "system" ? "gray" : undefined}>
          {capitalize(value)}
        </Badge>
      ),
    },
    {
      field: "type",
      headerName: "Type",
      cellRenderer: ({ value }: { value: RecordFieldType }) => {
        return <RecordFieldTypeLabel type={value.type} />;
      },
      width: 160,
    },
    { field: "description", headerName: "Description", flex: 1 },
  ];

  const handleSelectionChanged = (
    event: SelectionChangedEvent<RecordField>
  ) => {
    setSelectedRows(event.api.getSelectedRows());
  };

  const rowSelection: RowSelectionOptions = {
    mode: "multiRow",
    enableClickSelection: true,
    enableSelectionWithoutKeys: true,
    hideDisabledCheckboxes: true,
    isRowSelectable: (rowNode: IRowNode<RecordField>) => {
      return rowNode.data?.origin !== "system";
    },
  };

  return (
    <>
      <TabTitle
        title="Fields"
        description="Manage fields associated with this record type"
      >
        <Flex gap="2">
          {selectedRows.length === 1 && (
            <AddOrEditFieldAction
              recordTypeId={recordTypeId}
              recordField={selectedRows[0]}
              mode="edit"
            />
          )}
          {selectedRows.length > 0 && (
            <DeleteFieldsAction
              recordTypeId={recordTypeId}
              fields={selectedRows}
            />
          )}
          {selectedRows.length === 0 && (
            <AddOrEditFieldAction mode="add" recordTypeId={recordTypeId} />
          )}
        </Flex>
      </TabTitle>
      <BaseAgGrid<RecordField>
        rowData={recordType?.fields || []}
        columnDefs={columnDefs}
        suppressCellFocus
        rowSelection={canEditFields ? rowSelection : undefined}
        onSelectionChanged={handleSelectionChanged}
      />
    </>
  );
}
