import { useWorkflows } from "@/state/queries/workflows";
import { Status } from "@/types/status";
import { WorkflowNodeContent } from "@/types/workflows";
import { useMemo } from "react";

/**
 * Returns all statuses for a given record type. Traverses all related workflows
 * and grabs the statuses from each workflow.
 *
 * @param recordTypeId - The ID of the record type to get statuses for.
 * @returns An array of statuses.
 */
export const useStatuses = (recordTypeId?: string): Status[] => {
  const { data: workflows } = useWorkflows();

  return useMemo(() => {
    if (!workflows || !recordTypeId) {
      return [];
    }

    const relevantWorkflows = workflows.filter(
      (wf) => wf.primaryRecordTypeId === recordTypeId
    );

    const statusNodes = relevantWorkflows.flatMap((wf) => {
      return Object.values(wf.workflowDefinition.nodes).filter((node) =>
        WorkflowNodeContent.isStatus(node.content)
      );
    });

    return statusNodes
      .map((node) =>
        WorkflowNodeContent.visit(node.content, {
          action: () => undefined,
          status: (status) => status,
        })
      )
      .filter((item) => !!item);
  }, [workflows, recordTypeId]);
};
