import {
  MultiSelect,
  MultiSelectOption,
} from "@/components/multiselect/MultiSelect";
import { DeepKeys, useField } from "@tanstack/react-form";
import { FormField } from "../FormField";
import { BaseFieldProps } from "../types";

export interface BaseMultiSelectProps<TData, TOption>
  extends BaseFieldProps<TData> {
  options: MultiSelectOption<TOption>[];
}

export function BaseMultiSelect<TData, TOption>(
  props: BaseMultiSelectProps<TData, TOption>
) {
  const { reactFormField, fieldProps, options } = props;
  const field = useField<TData, DeepKeys<TData>>(reactFormField);

  const value = (field.state.value as string[]) ?? [];

  return (
    <FormField
      reactForm={reactFormField.form}
      reactFormField={field}
      {...fieldProps}
    >
      <MultiSelect<TOption>
        selectedValues={value}
        onChange={(value) => field.setValue(value as any)}
        options={options}
      />
    </FormField>
  );
}
