import { useTenantContext } from "@/contexts/TenantContext";
import { supabase } from "@/supabaseClient";
import { useMutation, useQueryClient } from "@tanstack/react-query";

interface RejectRevisionInput {
  documentId: string;
  revisionId: string;
  tenantId: string;
}

const rejectRevision = async (
  input: RejectRevisionInput,
  isInitialRevision: boolean
) => {
  const { error: revisionError } = await supabase
    .from("document_revisions")
    .update({
      status: "Draft",
    })
    .eq("id", input.revisionId)
    .eq("tenant_id", input.tenantId);

  if (revisionError) {
    throw revisionError;
  }

  if (isInitialRevision) {
    const { error: documentError } = await supabase
      .from("documents")
      .update({ status: "Draft" })
      .eq("id", input.documentId)
      .eq("tenant_id", input.tenantId);

    if (documentError) {
      throw documentError;
    }
  }
};

export const useRejectRevision = (isInitialRevision: boolean) => {
  const queryClient = useQueryClient();
  const tenant = useTenantContext();

  return useMutation({
    mutationFn: (input: RejectRevisionInput) => {
      if (!tenant.tenant) {
        throw new Error("Tenant not found");
      }
      return rejectRevision(input, isInitialRevision);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["documents", tenant.tenant?.tenantId],
      });
    },
  });
};
