import { ExecutableAction } from "@/hooks/useActionTypes";
import { useCanExecuteAction } from "@/hooks/useCanExecuteAction";
import { ActionOperation } from "@/types/actions";
import { DropdownMenu, Tooltip } from "@radix-ui/themes";
import { IconPencil, IconPlus, IconTrash } from "@tabler/icons-react";

export interface ActionDropdownItemProps {
  action: ExecutableAction;
  onClick: () => void;
}

export const ActionDropdownItem: React.FC<ActionDropdownItemProps> = ({
  action,
  onClick,
}) => {
  const { hasExecutePermission, message } = useCanExecuteAction(action);

  const dropdownItemProps: DropdownMenu.ItemProps = {
    onClick,
    disabled: !hasExecutePermission,
  };
  let icon: React.ReactNode | undefined;

  ActionOperation.visit(action.operation, {
    create: () => {
      icon = <IconPlus />;
    },
    update: () => {
      icon = <IconPencil />;
    },
    delete: () => {
      dropdownItemProps["color"] = "red";
      icon = <IconTrash />;
    },
  });

  let button = (
    <DropdownMenu.Item {...dropdownItemProps}>
      {icon}
      {action.name}
    </DropdownMenu.Item>
  );

  if (!hasExecutePermission) {
    button = (
      <Tooltip side="left" content={message} maxWidth="250px">
        {button}
      </Tooltip>
    );
  }

  return button;
};
