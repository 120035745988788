import { useTenantContext } from "@/contexts/TenantContext";
import { supabase } from "@/supabaseClient";
import { queryOptions, useQuery } from "@tanstack/react-query";
import { TenantUser, useTenantUsers } from "./tenantUsers";

interface TenantGroupMember {
  userId: string;
  updatedAt: string;
  updatedBy: string;
}

interface TenantGroupInitial {
  id: string;
  name: string;
  members: TenantGroupMember[];
}

export interface TenantGroup extends TenantGroupInitial {
  users: TenantUser[];
}

const fetchTenantGroups = async (
  tenantId: string
): Promise<TenantGroupInitial[]> => {
  const { data, error } = await supabase
    .schema("tenants")
    .from("tenant_groups")
    .select(
      `
        id,
        name,
        created_at,
        created_by,
        updated_at,
        updated_by,
        tenant_group_members (
          user_id,
          updated_at,
          updated_by
        )
      `
    )
    .eq("tenant_id", tenantId);

  if (error) {
    throw error;
  }

  if (!data) {
    return [];
  }

  return data.map((group) => ({
    id: group.id,
    name: group.name,
    members: group.tenant_group_members.map((member) => ({
      userId: member.user_id,
      updatedAt: member.updated_at,
      updatedBy: member.updated_by,
    })),
  }));
};

export const tenantGroupsQueryOptions = (tenantId: string) =>
  queryOptions({
    queryKey: ["tenantGroups", tenantId],
    queryFn: () => fetchTenantGroups(tenantId),
    staleTime: 1000 * 60 * 5, // 5 minutes
    retry: false,
  });

export const useTenantGroups = () => {
  const tenant = useTenantContext();
  if (!tenant.tenant) {
    throw new Error("Tenant not found");
  }
  return useQuery(tenantGroupsQueryOptions(tenant.tenant.tenantId));
};

export const useTenantGroupsWithUserData = () => {
  const { data: tenantGroups, ...rest } = useTenantGroups();
  const { data: tenantUsers } = useTenantUsers();

  return {
    ...rest,
    data: Object.values(tenantGroups ?? {}).map((group) => ({
      ...group,
      users: tenantUsers
        ? group.members.map((member) => tenantUsers[member.userId])
        : [],
    })),
  };
};

export const useTenantGroupWithUserData = (groupId: string) => {
  const { data: tenantGroups, ...rest } = useTenantGroupsWithUserData();
  return {
    ...rest,
    data: tenantGroups?.find((g) => g.id === groupId),
  };
};
