import EmptyPage from "@/components/EmptyPage";
import PageTitle from "@/components/PageTitle";
import { PermissionedButton } from "@/components/PermissionedButton";
import { DeleteWorkflowAction } from "@/components/workflows/DeleteWorkflowAction";
import { WorkflowEditor } from "@/components/workflows/WorkflowEditor";
import {
  useWorkflowEditorContext,
  WorkflowEditorProvider,
} from "@/components/workflows/WorkflowEditorContext";
import { useUpdateWorkflowDefinition } from "@/state/mutations/workflows/updateWorkflowDefinition";
import { useWorkflow, workflowsQueryOptions } from "@/state/queries/workflows";
import { graphToWorkflowDefinition } from "@/utils/workflows";
import { Flex, Spinner } from "@radix-ui/themes";
import { IconPencil } from "@tabler/icons-react";
import { createFileRoute } from "@tanstack/react-router";
import { z } from "zod";

export const Route = createFileRoute(
  "/_app/$tenantSlug/settings/organization/workflows/$workflowId"
)({
  validateSearch: z.object({
    isEditing: z.boolean().optional(),
  }),
  beforeLoad: async ({ context, params }) => {
    if (!context.tenant) {
      return { getTitle: () => "Record Type" };
    }
    const workflows = await context.queryClient.ensureQueryData(
      workflowsQueryOptions(context.tenant.tenantId)
    );
    const workflow = workflows.find((w) => w.id === params.workflowId);
    return {
      getTitle: () => workflow?.name ?? "Workflow",
    };
  },
  component: Workflow,
});

function Workflow() {
  const { workflowId } = Route.useParams();
  const { isEditing } = Route.useSearch();
  const { data: workflow, isPending } = useWorkflow(workflowId);

  if (isPending || !workflow) {
    return <EmptyPage icon={<Spinner />} />;
  }

  return (
    <Flex direction="column" gap="2">
      <WorkflowEditorProvider
        workflowDefinition={workflow.workflowDefinition}
        recordTypeId={workflow.primaryRecordTypeId}
        isEditing={isEditing ?? false}
      >
        <PageTitle
          tag="Workflow"
          title={workflow.name}
          description={workflow.description}
        >
          <WorkflowActions />
        </PageTitle>
        <WorkflowEditor />
      </WorkflowEditorProvider>
    </Flex>
  );
}

const WorkflowActions = () => {
  const navigate = Route.useNavigate();
  const { tenantSlug, workflowId } = Route.useParams();
  const { isEditing } = Route.useSearch();

  const actions = [];
  if (isEditing) {
    actions.push(<CancelEditingButton />);
    actions.push(<SaveWorkflowButton />);
  } else {
    actions.push(
      <DeleteWorkflowAction
        key="delete"
        workflowId={workflowId}
        onDelete={() => {
          navigate({
            to: "/$tenantSlug/settings/organization/workflows",
            params: {
              tenantSlug,
            },
          });
        }}
      />
    );
    actions.push(
      <PermissionedButton
        key="edit"
        variant="soft"
        permission="write.processes"
        onClick={() =>
          navigate({
            search: { isEditing: true },
          })
        }
      >
        <IconPencil />
        Edit workflow
      </PermissionedButton>
    );
  }

  return (
    <Flex align="center" gap="2">
      {actions}
    </Flex>
  );
};

const CancelEditingButton = () => {
  const navigate = Route.useNavigate();
  const { resetWorkflowEditor } = useWorkflowEditorContext();
  const handleCancel = () => {
    resetWorkflowEditor();
    navigate({
      search: {},
    });
  };
  return (
    <PermissionedButton
      key="cancel"
      permission="write.processes"
      variant="soft"
      color="gray"
      onClick={handleCancel}
    >
      Cancel
    </PermissionedButton>
  );
};

const SaveWorkflowButton = () => {
  const { workflowId } = Route.useParams();
  const navigate = Route.useNavigate();
  const { mutate: updateWorkflowDefinition, isPending } =
    useUpdateWorkflowDefinition();
  const { nodes, edges, deselectAllNodes, hasChanges } =
    useWorkflowEditorContext();

  const handleSave = () => {
    updateWorkflowDefinition({
      workflowId,
      workflowDefinition: graphToWorkflowDefinition(nodes, edges),
    });
    deselectAllNodes();
    navigate({
      search: {},
    });
  };

  return (
    <PermissionedButton
      key="save"
      permission="write.processes"
      loading={isPending}
      onClick={handleSave}
      disabled={!hasChanges}
    >
      Save changes
    </PermissionedButton>
  );
};
