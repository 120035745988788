import { useTenantContext } from "@/contexts/TenantContext";
import { useTenantLogoUrl } from "@/state/queries/tenantLogo";
import { useTenants } from "@/state/queries/tenants";
import { Button, DropdownMenu, Skeleton, Text } from "@radix-ui/themes";
import { IconChevronDown, IconSettings } from "@tabler/icons-react";
import { Link, useNavigate } from "@tanstack/react-router";
import React, { useState } from "react";

const WorkspaceMenu: React.FC = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();
  const { tenant } = useTenantContext();
  const { data: tenants } = useTenants();
  const { data: logoUrl, isLoading: isLogoLoading } = useTenantLogoUrl();

  const otherTenants = Object.values(tenants ?? {}).filter(
    (t) => t.slug !== tenant.slug
  );

  const maybeOtherTenantsSubMenu =
    otherTenants.length > 0 ? (
      <>
        <DropdownMenu.Separator />
        <DropdownMenu.Sub>
          <DropdownMenu.SubTrigger>Switch Organization</DropdownMenu.SubTrigger>
          <DropdownMenu.SubContent>
            {otherTenants.map((t) => (
              <DropdownMenu.Item
                key={t.slug}
                onClick={() => {
                  setMenuOpen(false);
                  navigate({
                    to: "/$tenantSlug",
                    params: { tenantSlug: t.slug },
                  });
                }}
              >
                <Link
                  to="/$tenantSlug"
                  params={{ tenantSlug: t.slug }}
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  {t.name}
                </Link>
              </DropdownMenu.Item>
            ))}
          </DropdownMenu.SubContent>
        </DropdownMenu.Sub>
      </>
    ) : null;

  return (
    <DropdownMenu.Root open={menuOpen} onOpenChange={setMenuOpen}>
      <DropdownMenu.Trigger>
        <Button variant="ghost" color="gray" style={{ gap: "6px" }}>
          {isLogoLoading ? (
            <Skeleton style={{ width: "18px", height: "18px" }} />
          ) : (
            <img
              src={logoUrl ?? "/Logo.svg"}
              alt="Logo"
              style={{
                width: "18px",
                height: "18px",
                borderRadius: "var(--radius-1)",
              }}
            />
          )}
          <Text size="3" weight="bold">
            {tenant.name}
          </Text>
          <IconChevronDown />
        </Button>
      </DropdownMenu.Trigger>
      <DropdownMenu.Content>
        <DropdownMenu.Item asChild>
          <Link to="/$tenantSlug/settings" params={{ tenantSlug: tenant.slug }}>
            <IconSettings /> Organization Settings
          </Link>
        </DropdownMenu.Item>
        {maybeOtherTenantsSubMenu}
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
};

export default WorkspaceMenu;
