import EmptyPage from "@/components/EmptyPage";
import { CreateViewDialogContent } from "@/components/records/CreateViewDialogContent";
import { useRecordTypeById } from "@/state/queries/recordLinkTypes";
import { useRecordViewsByRecordType } from "@/state/queries/recordViews";
import { Button, Spinner } from "@radix-ui/themes";
import { IconPlus, IconTable } from "@tabler/icons-react";
import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { useEffect, useState } from "react";

export const Route = createFileRoute(
  "/_app/$tenantSlug/records/$recordTypeId/"
)({
  beforeLoad: async ({ context, params }) => {
    return {
      getTitle: () => "",
    };
  },
  component: Records,
});

function Records() {
  const { tenantSlug, recordTypeId } = Route.useParams();
  const { data: recordViews, isLoading } =
    useRecordViewsByRecordType(recordTypeId);
  const { data: recordType } = useRecordTypeById(recordTypeId);
  const navigate = useNavigate();
  const [createDialogOpen, setCreateDialogOpen] = useState(false);

  // Redirect to first available view if it exists
  useEffect(() => {
    if (!isLoading && recordViews?.length && recordViews.length > 0) {
      navigate({
        to: `/${tenantSlug}/records/${recordTypeId}/views/${recordViews[0].id}`,
        replace: true,
      });
    }
  }, [isLoading, recordViews, navigate, tenantSlug, recordTypeId]);

  if (isLoading || !recordType) {
    return <EmptyPage icon={<Spinner />} />;
  }

  return (
    <>
      <EmptyPage
        icon={<IconTable />}
        title="No views yet"
        description="Create a view to start exploring your records"
        action={
          <Button onClick={() => setCreateDialogOpen(true)}>
            <IconPlus />
            Create view
          </Button>
        }
      />
      <CreateViewDialogContent
        open={createDialogOpen}
        onOpenChange={setCreateDialogOpen}
        recordType={recordType}
        onSuccess={(id) => {
          navigate({
            to: `/${tenantSlug}/records/${recordTypeId}/views/${id}`,
            replace: true,
          });
        }}
      />
    </>
  );
}
