import { useTenantGroups } from "@/state/queries/tenantGroups";
import { useTenantUsers } from "@/state/queries/tenantUsers";
import { useUser } from "@/state/queries/user";
import { useUserGroups } from "@/state/queries/userGroups";
import { ActionPermissions } from "@/types/actions";
import { intersection, keyBy, mapValues } from "lodash-es";
import { ExecutableAction } from "./useActionTypes";

export interface CanExecuteActionResult {
  hasExecutePermission: boolean;
  message?: string;
}

export const useCanExecuteAction = (
  action: ExecutableAction
): CanExecuteActionResult => {
  const { data: currentUser } = useUser();
  const { data: userGroups } = useUserGroups(currentUser?.id ?? "");
  const { data: tenantGroups } = useTenantGroups();
  const { data: tenantUsers } = useTenantUsers();

  const groupIdsToNames = tenantGroups
    ? mapValues(keyBy(tenantGroups, "id"), "name")
    : {};

  const userIdsToEmails = tenantUsers ? mapValues(tenantUsers, "email") : {};
  const userIdsToNames = tenantUsers ? mapValues(tenantUsers, "fullName") : {};

  let hasPermission = false;
  let message: string | undefined;
  ActionPermissions.visit(action.permissions, {
    noFilter: () => {
      hasPermission = true;
    },
    userFilter: ({ userIds }) => {
      if (currentUser?.id && userIds.includes(currentUser?.id)) {
        hasPermission = true;
      } else {
        const userNames = userIds
          .map((id) => userIdsToNames[id] ?? userIdsToEmails[id])
          .filter((userName) => !!userName);
        if (userNames.length > 0) {
          message = `Only these users can execute this action: ${userNames.join(", ")}`;
        } else {
          message = `You do not have permission to execute this action.`;
        }
      }
    },
    groupFilter: ({ groupIds }) => {
      if (userGroups && intersection(userGroups, groupIds).length > 0) {
        hasPermission = true;
      } else {
        hasPermission = false;
        const groupNames = groupIds
          .map((id) => groupIdsToNames[id])
          .filter((groupName) => !!groupName);
        if (groupNames.length > 0) {
          message = `Only these user groups can execute this action: ${groupNames.join(", ")}`;
        } else {
          message = `You do not have permission to execute this action.`;
        }
      }
    },
  });

  return {
    hasExecutePermission: hasPermission,
    message,
  };
};
