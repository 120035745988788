import React from "react";
import ReactMarkdown from "react-markdown";
import styles from "./MultilineTextValue.module.scss";
interface MultilineTextValueProps {
  value: string;
}

export const MultilineTextValue: React.FC<MultilineTextValueProps> = ({
  value,
}) => {
  const formattedValue = value.replace(/\n/g, "  \n");

  return (
    <ReactMarkdown
      components={{
        p: ({ children }) => {
          return <p className={styles["multiline-paragraph"]}>{children}</p>;
        },
      }}
    >
      {formattedValue}
    </ReactMarkdown>
  );
};
