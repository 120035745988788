import { WorkflowDefinition } from "@/types/workflows";
import {
  graphToWorkflowDefinition,
  workflowDefinitionToGraph,
} from "@/utils/workflows";
import {
  Node,
  ReactFlowProvider,
  useEdgesState,
  useNodesState,
} from "@xyflow/react";
import { isEqual } from "lodash-es";
import React, { useCallback, useMemo, useState } from "react";
import { EdgeType, NodeType } from "./graph/types";

const WorkflowEditorContext = React.createContext<{
  isEditing: boolean;
  recordTypeId: string;

  nodes: NodeType[];
  setNodes: React.Dispatch<React.SetStateAction<NodeType[]>>;
  onNodesChange: (changes: any) => void;
  edges: EdgeType[];
  setEdges: React.Dispatch<React.SetStateAction<EdgeType[]>>;
  onEdgesChange: (changes: any) => void;

  hasChanges: boolean;
  resetWorkflowEditor: () => void;

  selectedNodes: Node[];
  setSelectedNodes: React.Dispatch<React.SetStateAction<Node[]>>;
  editingNode: NodeType | undefined;
  startEditingNode: (nodeId: string) => void;
  clearEditingNode: () => void;
  addNode: (node: NodeType, selectNewNode?: boolean) => void;
  updateNode: (node: NodeType) => void;
  addEdge: (edge: EdgeType) => void;
  getInboundEdges: (nodeId: string) => EdgeType[];
  getOutboundEdges: (nodeId: string) => EdgeType[];
  removeInboundEdges: (nodeId: string) => void;
  removeOutboundEdges: (nodeId: string) => void;
  deleteNodes: (nodeIds: string[]) => void;
  selectNodes: (nodeIds: string[]) => void;
  deselectAllNodes: () => void;
}>({
  isEditing: false,
  recordTypeId: "",

  nodes: [],
  setNodes: () => {},
  onNodesChange: () => {},
  edges: [],
  setEdges: () => {},
  onEdgesChange: () => {},

  hasChanges: false,
  resetWorkflowEditor: () => {},

  selectedNodes: [],
  setSelectedNodes: () => {},
  editingNode: undefined,
  startEditingNode: () => {},
  clearEditingNode: () => {},
  addNode: () => {},
  updateNode: () => {},
  addEdge: () => {},
  getInboundEdges: () => [],
  getOutboundEdges: () => [],
  removeInboundEdges: () => {},
  removeOutboundEdges: () => {},
  deleteNodes: () => {},
  selectNodes: () => {},
  deselectAllNodes: () => {},
});

interface WorkflowEditorProviderProps {
  workflowDefinition: WorkflowDefinition;
  isEditing: boolean;
  recordTypeId: string;
  children: React.ReactNode;
}

export const WorkflowEditorProvider: React.FC<WorkflowEditorProviderProps> = (
  props
) => {
  return (
    <ReactFlowProvider>
      <WorkflowEditorProviderInner {...props} />
    </ReactFlowProvider>
  );
};

const WorkflowEditorProviderInner: React.FC<WorkflowEditorProviderProps> = ({
  workflowDefinition,
  isEditing,
  recordTypeId,
  children,
}) => {
  const { nodes: currentNodes, edges: currentEdges } = useMemo(
    () => workflowDefinitionToGraph(workflowDefinition),
    [workflowDefinition]
  );
  const [nodes, setNodes, onNodesChange] =
    useNodesState<NodeType>(currentNodes);
  const [edges, setEdges, onEdgesChange] =
    useEdgesState<EdgeType>(currentEdges);
  const [selectedNodes, setSelectedNodes] = useState<Node[]>([]);
  const [editingNodeId, setEditingNodeId] = useState<string | undefined>(
    undefined
  );

  // TODO This is probably not the best way to do this.
  const hasChanges = useMemo(() => {
    const newWorkflowDefinition = graphToWorkflowDefinition(nodes, edges);
    return !isEqual(workflowDefinition, newWorkflowDefinition);
  }, [workflowDefinition, nodes, edges]);

  const resetWorkflowEditor = useCallback(() => {
    setNodes(currentNodes);
    setEdges(currentEdges);
  }, [setNodes, setEdges, currentNodes, currentEdges]);

  const addNode = useCallback(
    async (node: NodeType, selectNewNode?: boolean) => {
      setNodes((currentNodes) => {
        const deselectedCurrentNodes = currentNodes.map((n) => ({
          ...n,
          selected: false,
        }));
        const selectedNewNode = {
          ...node,
          selected: selectNewNode,
        };
        return [...deselectedCurrentNodes, selectedNewNode];
      });
    },
    [setNodes]
  );

  const addEdge = useCallback(
    (edge: EdgeType) => {
      setEdges((currentEdges) => [...currentEdges, edge]);
    },
    [setEdges]
  );

  const getInboundEdges = useCallback(
    (nodeId: string) => {
      return edges.filter((edge) => edge.target === nodeId);
    },
    [edges]
  );

  const getOutboundEdges = useCallback(
    (nodeId: string) => {
      return edges.filter((edge) => edge.source === nodeId);
    },
    [edges]
  );

  const removeInboundEdges = useCallback(
    (nodeId: string) => {
      setEdges((currentEdges) =>
        currentEdges.filter((edge) => edge.target !== nodeId)
      );
    },
    [setEdges]
  );

  const removeOutboundEdges = useCallback(
    (nodeId: string) => {
      setEdges((currentEdges) =>
        currentEdges.filter((edge) => edge.source !== nodeId)
      );
    },
    [setEdges]
  );

  const deleteNodes = useCallback(
    (nodeIds: string[]) => {
      setNodes((currentNodes) =>
        currentNodes.filter((node) => !nodeIds.includes(node.id))
      );
      setEdges((currentEdges) =>
        currentEdges.filter(
          (edge) =>
            !nodeIds.includes(edge.source) && !nodeIds.includes(edge.target)
        )
      );
    },
    [setNodes, setEdges]
  );

  const editingNode = useMemo(() => {
    return nodes.find((node) => node.id === editingNodeId);
  }, [nodes, editingNodeId]);

  const startEditingNode = useCallback(
    (nodeId: string) => {
      setEditingNodeId(nodeId);
    },
    [setEditingNodeId]
  );

  const updateNode = useCallback(
    (node: NodeType) => {
      setNodes((currentNodes) =>
        currentNodes.map((n) => (n.id === node.id ? node : n))
      );
    },
    [setNodes]
  );

  const clearEditingNode = useCallback(() => {
    setEditingNodeId(undefined);
  }, [setEditingNodeId]);

  const selectNodes = useCallback(
    (nodeIds: string[]) => {
      setNodes((currentNodes) =>
        currentNodes.map((node) => ({
          ...node,
          selected: nodeIds.includes(node.id),
        }))
      );
    },
    [setNodes]
  );

  const deselectAllNodes = useCallback(() => {
    setNodes((currentNodes) =>
      currentNodes.map((node) => ({ ...node, selected: false }))
    );
  }, [setNodes]);

  return (
    <WorkflowEditorContext.Provider
      value={{
        isEditing,
        recordTypeId,

        nodes,
        setNodes,
        onNodesChange,
        edges,
        setEdges,
        onEdgesChange,

        hasChanges,
        resetWorkflowEditor,

        selectedNodes,
        setSelectedNodes,
        editingNode,
        startEditingNode,
        clearEditingNode,
        addNode,
        updateNode,
        addEdge,
        getInboundEdges,
        getOutboundEdges,
        removeInboundEdges,
        removeOutboundEdges,
        deleteNodes,
        selectNodes,
        deselectAllNodes,
      }}
    >
      {children}
    </WorkflowEditorContext.Provider>
  );
};

export const useWorkflowEditorContext = () => {
  const context = React.useContext(WorkflowEditorContext);
  if (!context) {
    throw new Error("useWorkflow must be used within a WorkflowProvider");
  }
  return context;
};
