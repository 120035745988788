import { createFileRoute, redirect } from "@tanstack/react-router";

export const Route = createFileRoute(
  "/_app/$tenantSlug/records/$recordTypeId/$recordId/"
)({
  beforeLoad: ({ params }) => {
    throw redirect({
      to: `/${params.tenantSlug}/records/${params.recordTypeId}/${params.recordId}/fields`,
      replace: true,
    });
  },
});
