import { recordAndLinkTypesQueryOptions } from "@/state/queries/recordLinkTypes";
import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute("/_app/$tenantSlug/records/$recordTypeId")(
  {
    beforeLoad: async ({ context, params }) => {
      if (!context.tenant) {
        return { getTitle: () => "Record Type" };
      }
      const recordTypes = await context.queryClient.ensureQueryData(
        recordAndLinkTypesQueryOptions(context.tenant.tenantId)
      );
      const recordType = recordTypes.recordTypes.find(
        (rt) => rt.id === params.recordTypeId
      );
      return {
        getTitle: () => recordType?.pluralName ?? "Record Type",
      };
    },
  }
);
