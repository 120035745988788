import { useRecordViews } from "@/state/queries/recordViews";
import { Button, Flex, Separator, Text } from "@radix-ui/themes";
import {
  GetPropDefTypes,
  layoutPropDefs,
  paddingPropDefs,
} from "@radix-ui/themes/props";
import {
  IconArrowRightRhombus,
  IconBuilding,
  IconChevronLeft,
  IconCube,
  IconFileText,
  IconListDetails,
  IconPlane,
  IconSitemap,
  IconTable,
  IconUserCircle,
  IconUsers,
} from "@tabler/icons-react";
import { Link, useMatch } from "@tanstack/react-router";
import React, { PropsWithChildren } from "react";
import Search from "./Search";
import SidebarButton from "./SidebarButton";
import UserMenu from "./UserMenu";
import WorkspaceMenu from "./WorkspaceMenu";

const Sidebar: React.FC = () => {
  const settingsMatch = useMatch({
    from: "/_app/$tenantSlug/settings",
    shouldThrow: false,
  });
  const isInSettings = !!settingsMatch;

  return (
    <Flex
      direction="column"
      justify="between"
      width="250px"
      height="100%"
      style={{
        backgroundColor: "var(--gray-2)",
        borderRight: "var(--line-border)",
      }}
    >
      <Flex
        py="2"
        px="4"
        align="center"
        justify="between"
        style={{ borderBottom: "var(--line-border)" }}
      >
        {!isInSettings && <WorkspaceMenu />}
        {isInSettings && (
          <Flex align="center" gap="3">
            <Button variant="ghost" color="gray" asChild>
              <Link
                to="/$tenantSlug"
                params={{ tenantSlug: settingsMatch.params.tenantSlug }}
              >
                <IconChevronLeft />
                <Text size="3" weight="bold">
                  Settings
                </Text>
              </Link>
            </Button>
          </Flex>
        )}
      </Flex>
      {!isInSettings && <StandardSidebarContents />}
      {isInSettings && <SettingsSidebarContents />}
    </Flex>
  );
};

function SettingsSidebarContents() {
  return (
    <Flex direction="column" flexGrow="1">
      <SidebarSection
        header={{
          label: "Organization",
        }}
      >
        <SidebarButton
          to="/settings/organization/general"
          label="General"
          icon={<IconBuilding />}
        />
        <SidebarButton
          to="/settings/organization/members"
          label="Members"
          icon={<IconUsers />}
        />
        <SidebarButton
          to="/settings/organization/record-types"
          label="Record Types"
          icon={<IconCube />}
        />
        <SidebarButton
          to="/settings/organization/workflows"
          label="Workflows"
          icon={<IconArrowRightRhombus />}
        />
      </SidebarSection>

      <SidebarSection
        header={{
          label: "Account",
        }}
      >
        <SidebarButton
          to="/settings/account/profile"
          label="Profile"
          icon={<IconUserCircle />}
        />
        {/* <SidebarButton
          to="/settings/account/notifications"
          label="Notifications"
          icon={<IconBell />}
        /> */}
      </SidebarSection>
    </Flex>
  );
}

function StandardSidebarContents() {
  const { data: recordViews } = useRecordViews();

  return (
    <>
      <Flex direction="column" flexGrow="1">
        <SidebarSection px="4" py="2">
          <Search />
        </SidebarSection>

        <SidebarSection
          header={{
            label: "Compliance",
          }}
        >
          <SidebarButton
            to="/processes"
            label="Processes"
            icon={<IconSitemap />}
          />
          <SidebarButton
            to="/frameworks/AS9100D"
            label="AS9100D"
            icon={<IconPlane />}
          />
          <SidebarButton
            to="/documents"
            label="Documents"
            icon={<IconFileText />}
          />
          <SidebarButton
            to="/records"
            label="Records"
            icon={<IconListDetails />}
            exactMatchUrl
          />
        </SidebarSection>

        {/* <SidebarSection
          header={{
            label: "Records",
          }}
        >
          {recordAndLinkTypes?.recordTypes.map((recordType) => (
            <SidebarButton
              key={recordType.id}
              to={`/records/${recordType.id}`}
              label={recordType.pluralName}
              icon={<IconClipboardCheck />}
            />
          ))}
        </SidebarSection> */}

        <SidebarSection
          header={{
            label: "Views",
          }}
        >
          {recordViews?.map((view) => (
            <SidebarButton
              key={view.id}
              to={`/records/${view.recordTypeId}/views/${view.id}`}
              label={view.name}
              icon={<IconTable />}
            />
          ))}
        </SidebarSection>
      </Flex>

      <SidebarSection py="2">
        {/* <Support /> */}
        <UserMenu />
      </SidebarSection>
    </>
  );
}

// function Support() {
//   return (
//     <Dialog.Root>
//       <Dialog.Trigger>
//         <SidebarButton icon={<IconHelp />} label="Help and Support" />
//       </Dialog.Trigger>
//       <Dialog.Content size="2">
//         <Dialog.Title>Submit a Help Request</Dialog.Title>
//         <Dialog.Description size="2" mb="4">
//           Describe your issue and we'll get back to you as soon as possible.
//         </Dialog.Description>
//         <Flex direction="column" gap="3">
//           <label>
//             <Text as="div" size="2" mb="1" weight="bold">
//               Subject
//             </Text>
//             <TextField.Root placeholder="Issue title" size="2" />
//           </label>
//           <label>
//             <Text as="div" size="2" mb="1" weight="bold">
//               Description
//             </Text>
//             <TextArea
//               placeholder="Provide more details about your issue"
//               size="2"
//             />
//           </label>
//           <label>
//             <Text as="div" size="2" mb="1" weight="bold">
//               Priority
//             </Text>
//             <Select.Root defaultValue="medium">
//               <Select.Trigger />
//               <Select.Content>
//                 <Select.Item value="low">Low</Select.Item>
//                 <Select.Item value="medium">Medium</Select.Item>
//                 <Select.Item value="high">High</Select.Item>
//               </Select.Content>
//             </Select.Root>
//           </label>
//           <Flex gap="3" mt="4" justify="end">
//             <Button size="2">Submit Request</Button>
//           </Flex>
//         </Flex>
//       </Dialog.Content>
//     </Dialog.Root>
//   );
// }

interface SidebarSectionProps
  extends PropsWithChildren,
    GetPropDefTypes<typeof paddingPropDefs & typeof layoutPropDefs> {
  header?: {
    icon?: React.ReactElement;
    label: string;
  };
  topBorder?: boolean;
  bottomBorder?: boolean;
}

function SidebarSection({
  children,
  header,
  topBorder = false,
  bottomBorder = false,
  ...props
}: SidebarSectionProps) {
  let maybeHeaderElement: React.ReactNode;
  if (header) {
    maybeHeaderElement = (
      <Flex align="center" gap="1" mx="1" style={{ color: "var(--gray-10)" }}>
        {header.icon}
        <Text
          size="1"
          weight="bold"
          style={{ textTransform: "uppercase", color: "var(--gray-10)" }}
        >
          {header.label}
        </Text>
      </Flex>
    );
  }

  return (
    <>
      {topBorder && <Separator size="4" />}
      <Flex direction="column" gap="2" p="4" {...props}>
        {maybeHeaderElement}
        <Flex direction="column" gap="3" mt="2">
          {children}
        </Flex>
      </Flex>
      {bottomBorder && <Separator size="4" />}
    </>
  );
}

export default Sidebar;
