import { useTenantContext } from "@/contexts/TenantContext";
import { Json } from "@/database/generated.types";
import { supabase } from "@/supabaseClient";
import { WorkflowDefinition } from "@/types/workflows";
import { useMutation, useQueryClient } from "@tanstack/react-query";

interface CreateWorkflowInput {
  tenantId: string;
  processId: string;
  primaryRecordTypeId: string;
  name: string;
  description?: string;
}

const createWorkflow = async (input: CreateWorkflowInput) => {
  const workflowDefinition: WorkflowDefinition = {
    nodes: {},
    edges: {},
  };
  const { error } = await supabase.from("workflows").insert({
    tenant_id: input.tenantId,
    process_id: input.processId,
    primary_record_type_id: input.primaryRecordTypeId,
    name: input.name,
    description: input.description,
    // Supabase typings suck: https://github.com/supabase/postgres-meta/issues/676
    workflow: workflowDefinition as unknown as Json,
  });

  if (error) {
    throw error;
  }
};

export const useCreateWorkflow = () => {
  const queryClient = useQueryClient();
  const tenant = useTenantContext();

  return useMutation({
    mutationFn: (input: Omit<CreateWorkflowInput, "tenantId">) => {
      if (!tenant.tenant) {
        throw new Error("Tenant not found");
      }
      return createWorkflow({
        ...input,
        tenantId: tenant.tenant.tenantId,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["workflows", tenant.tenant?.tenantId],
      });
    },
  });
};
