import {
  FormFieldLabel,
  FormFieldWrapper,
  FormHelperText,
} from "@/components/forms/FormField";
import { PermissionedButton } from "@/components/PermissionedButton";
import { UpdateRecordTypeTitleFieldAction } from "@/components/record-types/UpdateRecordTypeTitleFieldAction";
import { TabTitle } from "@/components/TabTitle";
import { useRecordTypeById } from "@/state/queries/recordLinkTypes";
import { Flex, TextField } from "@radix-ui/themes";
import { IconForms, IconPencil } from "@tabler/icons-react";
import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute(
  "/_app/$tenantSlug/settings/organization/record-types/$recordTypeId/appearance"
)({
  beforeLoad: () => ({ getTitle: () => "" }),
  component: () => <RecordTypeAppearance />,
});

function RecordTypeAppearance() {
  return (
    <>
      <TabTitle
        title="Appearance"
        description="Configure how this record type appears in the system"
      />
      <Flex direction="column" gap="4" maxWidth="500px">
        <RecordTypeTitleField />
      </Flex>
    </>
  );
}

function RecordTypeTitleField() {
  const { recordTypeId } = Route.useParams();
  const { data: recordType } = useRecordTypeById(recordTypeId);

  const titleFieldName = recordType?.titleFieldId
    ? recordType.fields.find((field) => field.id === recordType.titleFieldId)
        ?.name
    : "(None)";

  return (
    <FormFieldWrapper>
      <FormFieldLabel name="slug" label="Title Field">
        {recordType && (
          <UpdateRecordTypeTitleFieldAction recordType={recordType}>
            <PermissionedButton
              iconButton
              variant="ghost"
              color="gray"
              permission="write.processes"
            >
              <IconPencil />
            </PermissionedButton>
          </UpdateRecordTypeTitleFieldAction>
        )}
      </FormFieldLabel>
      <FormHelperText>
        Used as the title of the record throughout the system, including on the
        record page and in record selectors.
      </FormHelperText>
      <TextField.Root value={titleFieldName} disabled>
        <TextField.Slot>
          <IconForms />
        </TextField.Slot>
      </TextField.Root>
    </FormFieldWrapper>
  );
}
