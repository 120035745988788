import { supabase } from "@/supabaseClient";
import { useMutation } from "@tanstack/react-query";

interface ChangePasswordParams {
  password: string;
  currentPassword: string;
}

export const changePassword = async ({
  password,
  currentPassword,
}: ChangePasswordParams) => {
  const { data, error: verifyError } = await supabase.rpc(
    "verify_user_password",
    {
      password: currentPassword,
    }
  );

  if (verifyError) {
    throw new Error("Unable to verify current password");
  }

  if (!data) {
    throw new Error("Current password is incorrect");
  }

  const { error } = await supabase.auth.updateUser({
    password: password,
  });

  if (error) {
    throw error;
  }
};

export const useChangePassword = () => {
  return useMutation({
    mutationFn: (params: ChangePasswordParams) => changePassword(params),
  });
};
