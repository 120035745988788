import { PermissionedButton } from "@/components/PermissionedButton";
import { useDeleteProcess } from "@/state/mutations/processes/deleteProcess";
import { Process } from "@/state/queries/processes";
import { AlertDialog, Button, Flex, Text } from "@radix-ui/themes";
import { IconTrash } from "@tabler/icons-react";
import { useState } from "react";
import { FormError } from "../forms/FormError";

export const DeleteProcessAction: React.FC<{
  process: Process;
  resetSelectedProcesses: () => void;
}> = ({ process, resetSelectedProcesses }) => {
  const [open, setOpen] = useState(false);
  const { mutate: deleteProcess, isPending, error, reset } = useDeleteProcess();

  const onSubmit = () => {
    deleteProcess(process, {
      onSuccess: () => {
        setOpen(false);
        resetSelectedProcesses();
      },
    });
  };

  const onOpenChange = (open: boolean) => {
    setOpen(open);
    if (!open) {
      reset();
    }
  };

  return (
    <AlertDialog.Root open={open} onOpenChange={onOpenChange}>
      <AlertDialog.Trigger>
        <PermissionedButton
          permission="write.processes"
          variant="soft"
          color="gray"
        >
          <IconTrash />
          Delete
        </PermissionedButton>
      </AlertDialog.Trigger>
      <AlertDialog.Content maxWidth="450px">
        <AlertDialog.Title>Delete process</AlertDialog.Title>
        <AlertDialog.Description>
          <Flex direction="column" gap="2">
            <Text>
              Are you sure? This process will no longer be available and any
              associated data will be deleted.
            </Text>
            {error && <FormError message={error.message} />}
          </Flex>
        </AlertDialog.Description>

        <Flex gap="3" mt="4" justify="end">
          <AlertDialog.Cancel>
            <Button variant="soft" color="gray">
              Cancel
            </Button>
          </AlertDialog.Cancel>
          <Button color="red" loading={isPending} onClick={onSubmit}>
            Delete process
          </Button>
        </Flex>
      </AlertDialog.Content>
    </AlertDialog.Root>
  );
};
