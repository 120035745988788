import { ActionOperation } from "@/types/actions";
import { Box, Button, Dialog, DropdownMenu, Flex } from "@radix-ui/themes";
import {
  IconCircle,
  IconPencil,
  IconPlus,
  IconStatusChange,
  IconTrash,
} from "@tabler/icons-react";
import {
  addEdge,
  Background,
  BackgroundVariant,
  Connection,
  Controls,
  MarkerType,
  Node,
  ReactFlow,
} from "@xyflow/react";
import "@xyflow/react/dist/style.css";
import classNames from "classnames";
import { useCallback, useEffect } from "react";
import DetailCard from "../DetailCard";
import { EditActionDialogContent } from "./dialogs/EditActionDialogContent";
import { EditStatusDialogContent } from "./dialogs/EditStatusDialogContent";
import ActionNode, { createActionNode } from "./graph/ActionNode";
import StatusNode, { createStatusNode } from "./graph/StatusNode";
import { EdgeType, NodeType } from "./graph/types";
import css from "./WorkflowEditor.module.scss";
import { useWorkflowEditorContext } from "./WorkflowEditorContext";

const nodeTypes = {
  status: StatusNode,
  action: ActionNode,
};

export const WorkflowEditor: React.FC = () => {
  const {
    isEditing,
    nodes,
    onNodesChange,
    edges,
    setEdges,
    onEdgesChange,
    selectedNodes,
    setSelectedNodes,
    editingNode,
    startEditingNode,
  } = useWorkflowEditorContext();

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === "Enter" && !editingNode && selectedNodes.length === 1) {
        startEditingNode(selectedNodes[0].id);
        event.preventDefault();
      }
    };

    document.addEventListener("keydown", handleKeyPress);
    return () => document.removeEventListener("keydown", handleKeyPress);
  }, [selectedNodes, editingNode, startEditingNode]);

  const onSelectionChange = useCallback(
    ({ nodes }: { nodes: Node[] }) => {
      setSelectedNodes(nodes);
    },
    [setSelectedNodes]
  );

  const onConnect = (connection: Connection) => {
    setEdges((currentEdges) => addEdge(connection, currentEdges));
  };

  const onNodeDoubleClick = (_event: React.MouseEvent, node: Node) => {
    startEditingNode(node.id);
  };

  return (
    <Flex direction="column" gap="2" mt="2">
      <DetailCard
        title="Workflow Definition"
        actions={<WorkflowEditorActions />}
      >
        <Box style={{ width: "100%", height: "450px" }}>
          <ReactFlow<NodeType, EdgeType>
            className={classNames(css.workflow, {
              [css.editable]: isEditing,
            })}
            nodeTypes={nodeTypes}
            nodes={nodes}
            edges={edges}
            elementsSelectable={isEditing}
            nodesDraggable={isEditing}
            nodesConnectable={isEditing}
            nodesFocusable={isEditing}
            edgesFocusable={isEditing}
            zoomOnPinch={isEditing}
            zoomOnDoubleClick={isEditing}
            onNodeDoubleClick={onNodeDoubleClick}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onConnect={onConnect}
            onSelectionChange={onSelectionChange}
            fitView
            snapToGrid
            proOptions={{ hideAttribution: true }}
            defaultEdgeOptions={{
              interactionWidth: 20,
              markerEnd: {
                type: MarkerType.ArrowClosed,
                color: "var(--accent-10)",
                strokeWidth: 1,
                width: 15,
                height: 15,
              },
            }}
            connectionLineStyle={{
              strokeWidth: 2,
              stroke: "var(--accent-10)",
            }}
          >
            <Background variant={BackgroundVariant.Dots} />
            <Controls showInteractive={false} />
          </ReactFlow>
        </Box>
      </DetailCard>
      <WorkflowEditorDialog />
    </Flex>
  );
};

const WorkflowEditorDialog = () => {
  const { editingNode, clearEditingNode } = useWorkflowEditorContext();
  const onOpenChange = (open: boolean) => {
    if (!open) {
      clearEditingNode();
    }
  };

  return (
    <Dialog.Root open={!!editingNode} onOpenChange={onOpenChange}>
      {editingNode?.type === "action" && (
        <EditActionDialogContent node={editingNode} />
      )}
      {editingNode?.type === "status" && (
        <EditStatusDialogContent node={editingNode} />
      )}
    </Dialog.Root>
  );
};

const WorkflowEditorActions = () => {
  const { isEditing, selectedNodes, startEditingNode, deleteNodes } =
    useWorkflowEditorContext();

  if (!isEditing) {
    return null;
  }

  const actions = [];
  if (selectedNodes.length > 0) {
    actions.push(
      <Button
        variant="soft"
        color="gray"
        size="1"
        onClick={() => deleteNodes(selectedNodes.map((node) => node.id))}
      >
        <IconTrash />
        Delete
      </Button>
    );
  }
  if (selectedNodes.length === 1) {
    actions.push(
      <Button
        variant="soft"
        size="1"
        onClick={() => startEditingNode(selectedNodes[0].id)}
      >
        <IconPencil />
        Edit
      </Button>
    );
  }
  if (selectedNodes.length === 0) {
    actions.push(<AddNodeMenu />);
  }

  return (
    <Flex align="center" gap="2">
      {actions}
    </Flex>
  );
};

const AddNodeMenu = () => {
  const { recordTypeId, addNode } = useWorkflowEditorContext();
  const addStatusNode = () =>
    addNode(createStatusNode({ x: 0, y: -200 }), true);
  const addActionNode = () =>
    addNode(
      createActionNode({
        recordTypeId,
        operation: ActionOperation.create({}),
        position: { x: 0, y: -200 },
      }),
      true
    );
  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger>
        <Button size="1">
          <IconPlus />
          Add step
        </Button>
      </DropdownMenu.Trigger>
      <DropdownMenu.Content>
        <DropdownMenu.Item onClick={addActionNode}>
          <IconStatusChange />
          Action
        </DropdownMenu.Item>
        <DropdownMenu.Item onClick={addStatusNode}>
          <IconCircle />
          Status
        </DropdownMenu.Item>
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
};
