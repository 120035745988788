import { recordAndLinkTypesQueryOptions } from "@/state/queries/recordLinkTypes";
import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute("/_app/$tenantSlug/records")({
  beforeLoad: () => {
    return {
      getTitle: () => "Records",
    };
  },
  loader: ({ context }) => {
    if (!context.tenant) {
      return;
    }
    context.queryClient.ensureQueryData(
      recordAndLinkTypesQueryOptions(context.tenant.tenantId)
    );
  },
});
