import Form from "@/components/forms";
import { useEditDocument } from "@/state/mutations/documents/editDocument";
import { Document } from "@/state/queries/documents";
import { Dialog, Flex } from "@radix-ui/themes";
import { useForm } from "@tanstack/react-form";
import { zodValidator } from "@tanstack/zod-form-adapter";
import { PropsWithChildren, useState } from "react";
import { z } from "zod";

interface EditDocumentFormInputs {
  name: string;
  description: string;
  owner: string;
  processId: string;
  // Can only be set if document is in Draft status
  file: File | undefined;
}

export const EditDocumentMetadataAction: React.FC<
  PropsWithChildren<{
    document: Document;
    enableFileEdit: boolean;
  }>
> = ({ document, enableFileEdit, children }) => {
  const [open, setOpen] = useState(false);
  const {
    mutate: editDocument,
    error,
    isPending,
    reset: resetMutation,
  } = useEditDocument();

  const form = useForm<EditDocumentFormInputs, any>({
    defaultValues: {
      name: document.title,
      description: document.description ?? "",
      owner: document.owner,
      processId: document.processId ?? "",
      file: undefined,
    },
    validatorAdapter: zodValidator(),
    validators: {
      onChange: z.object({
        name: z.string().min(1, { message: "Document name is required" }),
        description: z.string(),
        owner: z.string(),
        processId: z.string(),
      }),
    },
    onSubmit: ({ value }) => {
      editDocument(
        {
          id: document.id,
          name: value.name,
          description: value.description,
          owner: value.owner,
          processId: value.processId,
          file: value.file,
        },
        { onSuccess: () => setOpen(false) }
      );
    },
  });

  const handleOpenChange = (open: boolean) => {
    setOpen(open);
    form.reset();
    resetMutation();
  };

  return (
    <Dialog.Root open={open} onOpenChange={handleOpenChange}>
      <Dialog.Trigger>{children}</Dialog.Trigger>
      <Dialog.Content maxWidth="450px">
        <Dialog.Title>Edit document</Dialog.Title>
        <Form.Root reactForm={form}>
          <Flex direction="column" gap="4">
            <Form.Fields>
              <Form.TextField<EditDocumentFormInputs>
                reactFormField={{ form, name: "name" }}
                fieldProps={{ label: "Document title", required: true }}
                placeholder="Enter document title"
              />
              <Form.TextArea<EditDocumentFormInputs>
                reactFormField={{ form, name: "description" }}
                fieldProps={{ label: "Description" }}
              />
              <Form.UserSelect<EditDocumentFormInputs>
                reactFormField={{ form, name: "owner" }}
                fieldProps={{ label: "Owner" }}
              />
              <Form.ProcessSelect<EditDocumentFormInputs>
                reactFormField={{ form, name: "processId" }}
                fieldProps={{ label: "Parent Process" }}
              />
              {enableFileEdit && (
                <Form.FileUpload<EditDocumentFormInputs>
                  reactFormField={{ form, name: "file" }}
                  fieldProps={{ label: "Update document file" }}
                />
              )}
            </Form.Fields>
            <Form.Footer
              form={form}
              error={error}
              isPending={isPending}
              buttonText="Submit"
              errorPrefix="Failed to edit document: "
            />
          </Flex>
        </Form.Root>
      </Dialog.Content>
    </Dialog.Root>
  );
};
