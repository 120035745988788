import { useRecordAndLinkTypes } from "@/state/queries/recordLinkTypes";
import { Flex, Select } from "@radix-ui/themes";
import { DeepKeys, useField } from "@tanstack/react-form";
import { PropsWithChildren } from "react";
import { FormField } from "../FormField";
import { hasError } from "../utils";
import { BaseSelectProps } from "./BaseSelect";

export interface RecordTypeSelectProps<TData> extends BaseSelectProps<TData> {}

export function RecordTypeSelect<TData>(
  props: PropsWithChildren<RecordTypeSelectProps<TData>>
) {
  const { reactFormField, fieldProps, rootProps, triggerProps } = props;

  const field = useField<TData, DeepKeys<TData>>(reactFormField);
  const { data: recordTypes } = useRecordAndLinkTypes();

  const submissionAttempts = reactFormField.form.useStore(
    (form) => form.submissionAttempts
  );
  const error = hasError(field.state.meta, submissionAttempts);
  return (
    <FormField
      reactForm={reactFormField.form}
      reactFormField={field}
      {...fieldProps}
    >
      <Select.Root
        name={field.name as string}
        value={field.state.value as string}
        onValueChange={(value) => {
          field.handleChange(value as any);
        }}
        size={fieldProps.size ?? "3"}
        {...rootProps}
      >
        <Select.Trigger
          style={{
            boxShadow: error ? "inset 0 0 0 2px var(--red-a7)" : undefined,
            width: "100%",
          }}
          {...triggerProps}
        />
        <Select.Content>
          {recordTypes?.recordTypes.map((recordType) => (
            <Select.Item key={recordType.id} value={recordType.id}>
              <Flex align="center" gap="2">
                {recordType.name}
              </Flex>
            </Select.Item>
          ))}
        </Select.Content>
      </Select.Root>
    </FormField>
  );
}
