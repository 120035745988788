import { useRecordTypeById } from "@/state/queries/recordLinkTypes";
import { ActionOperation } from "@/types/actions";
import { Flex, RadioCards, Text } from "@radix-ui/themes";
import { DeepKeys, useField } from "@tanstack/react-form";
import { FormField } from "../../../forms/FormField";
import { BaseFieldProps } from "../../../forms/types";

export interface ActionOperationSelectProps<TData>
  extends BaseFieldProps<TData> {
  recordTypeId: string;
}

export function ActionOperationSelect<TData>(
  props: ActionOperationSelectProps<TData>
) {
  const { reactFormField, fieldProps, recordTypeId } = props;
  const field = useField<TData, DeepKeys<TData>>(reactFormField);
  const { data: recordType } = useRecordTypeById(recordTypeId);

  const value = field.state.value as ActionOperation;

  const handleValueChange = (value: string) => {
    let operation: ActionOperation;
    if (value === "create") {
      operation = ActionOperation.create({});
    } else if (value === "update") {
      operation = ActionOperation.update({});
    } else if (value === "delete") {
      operation = ActionOperation.delete({});
    } else {
      throw new Error(`Invalid operation: ${value}`);
    }
    field.handleChange(operation as any);
  };

  const recordTypeName = recordType?.name ?? "record";

  return (
    <FormField
      reactForm={reactFormField.form}
      reactFormField={field}
      {...fieldProps}
    >
      <RadioCards.Root
        defaultValue="1"
        value={value.type}
        onValueChange={handleValueChange}
        columns="3"
      >
        <RadioCards.Item value="create">
          <Flex direction="column" width="100%">
            <Text weight="bold">Create</Text>
            <Text size="1">Create a new {recordTypeName}</Text>
          </Flex>
        </RadioCards.Item>
        <RadioCards.Item value="update">
          <Flex direction="column" width="100%">
            <Text weight="bold">Update</Text>
            <Text size="1">Update a {recordTypeName}</Text>
          </Flex>
        </RadioCards.Item>
        <RadioCards.Item value="delete">
          <Flex direction="column" width="100%">
            <Text weight="bold">Delete</Text>
            <Text size="1">Delete a {recordTypeName}</Text>
          </Flex>
        </RadioCards.Item>
      </RadioCards.Root>
    </FormField>
  );
}
