import NonIdealState from "@/components/NonIdealState";
import { PermissionedButton } from "@/components/PermissionedButton";
import { AddControlAction } from "@/components/processes/AddControlAction";
import { EditControlAction } from "@/components/processes/EditControlAction";
import { RemoveControlAction } from "@/components/processes/RemoveControlAction";
import TabTitle from "@/components/TabTitle";
import { Process, ProcessControl, useProcess } from "@/state/queries/processes";
import { Box, Flex, Heading } from "@radix-ui/themes";
import { IconChecklist, IconPencil } from "@tabler/icons-react";
import { createFileRoute } from "@tanstack/react-router";
import ReactMarkdown from "react-markdown";

export const Route = createFileRoute(
  "/_app/$tenantSlug/processes/$processId/controls"
)({
  beforeLoad: () => ({
    getTitle: () => "Controls",
  }),
  component: ControlsPage,
});

function ControlsPage() {
  const { processId } = Route.useParams();
  const { data: process } = useProcess(processId);
  return (
    <Box maxWidth="var(--container-2)">
      <TabTitle title="Controls">
        {process && <AddControlAction process={process} />}
      </TabTitle>
      <Box pt="4">
        {!process?.controls.length && (
          <NonIdealState
            icon={<IconChecklist />}
            title="No controls"
            description="No controls have been added to this process yet"
          />
        )}
        {process?.controls.map((control) => (
          <ProcessControlComponent process={process} control={control} />
        ))}
      </Box>
    </Box>
  );
}

function ProcessControlComponent({
  process,
  control,
}: {
  process: Process;
  control: ProcessControl;
}) {
  return (
    <Box pb="4">
      <Flex align="center" justify="between">
        <Heading size="3">
          {control.clause} {control.title}
        </Heading>
        <Flex gap="2">
          <EditControlAction process={process} control={control}>
            <PermissionedButton
              size="1"
              variant="soft"
              permission="write.processes"
            >
              <IconPencil />
              Edit
            </PermissionedButton>
          </EditControlAction>
          <RemoveControlAction
            processId={process.id}
            frameworkId={control.frameworkId}
            clause={control.clause}
          />
        </Flex>
      </Flex>
      <ReactMarkdown>{control.language}</ReactMarkdown>
    </Box>
  );
}
