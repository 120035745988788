import Form from "@/components/forms";
import { useChangePassword } from "@/state/mutations/profiles/changePassword";
import { Dialog, Grid } from "@radix-ui/themes";
import { useForm } from "@tanstack/react-form";
import { zodValidator } from "@tanstack/zod-form-adapter";
import { ReactNode, useState } from "react";
import { z } from "zod";

const schema = z
  .object({
    currentPassword: z
      .string()
      .min(1, { message: "Current password is required" }),
    password: z
      .string()
      .min(8, { message: "Password must be at least 8 characters" }),
    confirmPassword: z.string(),
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: "Passwords must match",
    path: ["confirmPassword"],
  });

interface ChangePasswordFormInputs {
  currentPassword: string;
  password: string;
  confirmPassword: string;
}

export const ChangePasswordAction = ({ children }: { children: ReactNode }) => {
  const [open, setOpen] = useState(false);
  const {
    mutate: changePassword,
    reset,
    isPending,
    error,
  } = useChangePassword();

  const form = useForm<ChangePasswordFormInputs, any>({
    defaultValues: {
      currentPassword: "",
      password: "",
      confirmPassword: "",
    },
    validatorAdapter: zodValidator(),
    validators: {
      onChange: schema,
    },
    onSubmit: ({ value }) => {
      changePassword(
        {
          password: value.password,
          currentPassword: value.currentPassword,
        },
        { onSuccess: () => setOpen(false) }
      );
    },
  });

  const handleOpenChange = (open: boolean) => {
    setOpen(open);
    form.reset();
    reset();
  };

  return (
    <Dialog.Root open={open} onOpenChange={handleOpenChange}>
      <Dialog.Trigger>{children}</Dialog.Trigger>

      <Dialog.Content>
        <Dialog.Title>Change Password</Dialog.Title>

        <Form.Root reactForm={form}>
          <Form.Fields>
            <Form.TextField<ChangePasswordFormInputs>
              type="password"
              reactFormField={{ form, name: "currentPassword" }}
              fieldProps={{
                label: "Current password",
                required: true,
              }}
              placeholder="Enter current password"
            />

            <Grid columns="2" gap="4">
              <Form.TextField<ChangePasswordFormInputs>
                type="password"
                reactFormField={{ form, name: "password" }}
                fieldProps={{
                  label: "New password",
                  required: true,
                }}
                placeholder="Enter new password"
              />

              <Form.TextField<ChangePasswordFormInputs>
                type="password"
                reactFormField={{ form, name: "confirmPassword" }}
                fieldProps={{
                  label: "Confirm password",
                  required: true,
                }}
                placeholder="Confirm password"
              />
            </Grid>
          </Form.Fields>

          <Form.Footer
            form={form}
            error={error}
            isPending={isPending}
            buttonText="Change Password"
            errorPrefix="Failed to change password: "
          />
        </Form.Root>
      </Dialog.Content>
    </Dialog.Root>
  );
};
