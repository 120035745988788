import WorkflowsTable from "@/components/tables/WorkflowsTable";
import TabTitle from "@/components/TabTitle";
import { CreateWorkflowAction } from "@/components/workflows/CreateWorkflowAction";
import { useWorkflowByProcess } from "@/state/queries/workflows";
import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute(
  "/_app/$tenantSlug/processes/$processId/workflows"
)({
  beforeLoad: () => ({
    getTitle: () => "Workflows",
  }),
  component: ProcessWorkflows,
});

function ProcessWorkflows() {
  const { processId, tenantSlug } = Route.useParams();
  const { data: workflows } = useWorkflowByProcess(processId);

  return (
    <>
      <TabTitle title="Workflows">
        <CreateWorkflowAction processId={processId} />
      </TabTitle>
      <WorkflowsTable workflows={workflows ?? []} tenantSlug={tenantSlug} />
    </>
  );
}
