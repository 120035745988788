import { useTenantContext } from "@/contexts/TenantContext";
import { Json } from "@/database/generated.types";
import { supabase } from "@/supabaseClient";
import { RecordViewConfig } from "@/types/recordViews";
import { useMutation, useQueryClient } from "@tanstack/react-query";

type UpdateViewInput = Pick<
  RecordViewConfig,
  "id" | "name" | "viewType" | "defaultFilter"
>;

const updateView = async (input: UpdateViewInput, tenantId: string) => {
  const { error } = await supabase
    .from("record_views")
    .update({
      name: input.name,
      view_type: input.viewType as unknown as Json,
      default_filter: input.defaultFilter as unknown as Json,
    })
    .eq("id", input.id)
    .eq("tenant_id", tenantId);

  if (error) {
    throw error;
  }
};

export const useUpdateView = () => {
  const queryClient = useQueryClient();
  const tenant = useTenantContext();

  return useMutation({
    mutationFn: (input: UpdateViewInput) => {
      if (!tenant.tenant) {
        throw new Error("Tenant not found");
      }
      return updateView(input, tenant.tenant.tenantId);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["recordViews", tenant.tenant?.tenantId],
      });
    },
  });
};
