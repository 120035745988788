import { PermissionedButton } from "@/components/PermissionedButton";
import { useDeleteDocument } from "@/state/mutations/documents/deleteDocument";
import { Document } from "@/state/queries/documents";
import { AlertDialog, Button, Flex, Text } from "@radix-ui/themes";
import { IconTrash } from "@tabler/icons-react";
import { useState } from "react";
import { FormError } from "../forms/FormError";

export const DeleteDocumentAction: React.FC<{
  document: Document;
  onSuccess?: () => void;
}> = ({ document, onSuccess }) => {
  const [open, setOpen] = useState(false);
  const {
    mutate: deleteDocument,
    isPending,
    error,
    reset,
  } = useDeleteDocument();

  const onSubmit = () => {
    deleteDocument(
      { id: document.id, tenantId: document.tenantId },
      {
        onSuccess: () => {
          setOpen(false);
          onSuccess?.();
        },
      }
    );
  };

  const onOpenChange = (open: boolean) => {
    setOpen(open);
    if (!open) {
      reset();
    }
  };

  return (
    <AlertDialog.Root open={open} onOpenChange={onOpenChange}>
      <AlertDialog.Trigger>
        <PermissionedButton
          permission="write.documents"
          variant="soft"
          color="gray"
        >
          <IconTrash />
          Delete
        </PermissionedButton>
      </AlertDialog.Trigger>
      <AlertDialog.Content maxWidth="450px">
        <AlertDialog.Title>Delete document</AlertDialog.Title>
        <AlertDialog.Description>
          <Flex direction="column" gap="2">
            <Text>
              Are you sure? This document will no longer be available and any
              associated data will be deleted, along with past revisions.
            </Text>
            {error && <FormError message={error.message} />}
          </Flex>
        </AlertDialog.Description>

        <Flex gap="3" mt="4" justify="end">
          <AlertDialog.Cancel>
            <Button variant="soft" color="gray">
              Cancel
            </Button>
          </AlertDialog.Cancel>
          <Button color="red" loading={isPending} onClick={onSubmit}>
            Delete document
          </Button>
        </Flex>
      </AlertDialog.Content>
    </AlertDialog.Root>
  );
};
