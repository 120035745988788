import PageTitle from "@/components/PageTitle";
import { PermissionedButton } from "@/components/PermissionedButton";
import { AddProcessAction } from "@/components/processes/AddProcessAction";
import { DeleteProcessAction } from "@/components/processes/DeleteProcessAction";
import { EditProcessAction } from "@/components/processes/EditProcessAction";
import { SearchFilter } from "@/components/SearchFilter";
import { BaseAgGrid } from "@/components/tables/BaseAgGrid";
import { UserRenderer } from "@/components/tables/renderers";
import { useTenantContext } from "@/contexts/TenantContext";
import { Process, useProcesses } from "@/state/queries/processes";
import { Flex } from "@radix-ui/themes";
import { IconEdit } from "@tabler/icons-react";
import { createFileRoute, Link } from "@tanstack/react-router";
import {
  ColDef,
  ICellRendererParams,
  RowSelectedEvent,
  RowSelectionOptions,
} from "ag-grid-community";
import { useState } from "react";

export const Route = createFileRoute("/_app/$tenantSlug/processes/")({
  beforeLoad: () => {
    return {
      getTitle: () => "",
    };
  },
  component: ProcessesIndex,
});

function ProcessesIndex() {
  const [filter, setFilter] = useState("");
  const [selectedProcesses, setSelectedProcesses] = useState<Process[]>([]);
  const resetSelectedProcesses = () => setSelectedProcesses([]);

  return (
    <Flex direction="column" gap="2">
      <PageTitle
        title="Processes"
        description="Define processes to control business activities and map to compliance requirements."
      />
      <Flex justify="between" align="center" py="2">
        <SearchFilter
          filter={filter}
          setFilter={setFilter}
          placeholder="Search processes"
        />
        <ProcessActions
          selectedProcesses={selectedProcesses}
          resetSelectedProcesses={resetSelectedProcesses}
        />
      </Flex>
      <ProcessesTable
        onSelectionChange={setSelectedProcesses}
        filterText={filter}
      />
    </Flex>
  );
}

const ProcessActions: React.FC<{
  selectedProcesses: Process[];
  resetSelectedProcesses: () => void;
}> = ({ selectedProcesses, resetSelectedProcesses }) => {
  let actions = [];
  if (selectedProcesses.length === 1) {
    actions.push(
      <EditProcessAction key="edit" process={selectedProcesses[0]}>
        <PermissionedButton
          permission="write.processes"
          variant="soft"
          color="gray"
        >
          <IconEdit />
          Edit
        </PermissionedButton>
      </EditProcessAction>
    );
    actions.push(
      <DeleteProcessAction
        key="delete"
        process={selectedProcesses[0]}
        resetSelectedProcesses={resetSelectedProcesses}
      />
    );
  } else if (selectedProcesses.length === 0) {
    actions.push(<AddProcessAction key="add" />);
  }
  return <Flex gap="2">{actions}</Flex>;
};

function ProcessesTable({
  onSelectionChange,
  filterText,
}: {
  onSelectionChange: (processes: Process[]) => void;
  filterText: string;
}) {
  const { tenant } = useTenantContext();
  const canEditProcesses = tenant.userPermissions.has("write.processes");

  const { tenantSlug } = Route.useParams();
  const { data: processes, isLoading } = useProcesses();
  const columns: ColDef<Process>[] = [
    {
      field: "name",
      headerName: "Name",
      width: 200,
      cellRenderer: (props: ICellRendererParams) => (
        <Link
          to="/$tenantSlug/processes/$processId"
          params={{
            tenantSlug,
            processId: props.data.id,
          }}
        >
          {props.data.name}
        </Link>
      ),
    },
    {
      field: "id",
      headerName: "ID",
      width: 100,
    },
    {
      field: "ownedBy",
      headerName: "Owner",
      cellRenderer: UserRenderer,
      width: 180,
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
    },
  ];
  const handleRowSelected = (event: RowSelectedEvent<Process>) => {
    const selectedRows = event.api.getSelectedRows();
    onSelectionChange(selectedRows);
  };

  const rowSelection: RowSelectionOptions = {
    mode: "singleRow",
    enableClickSelection: true,
    enableSelectionWithoutKeys: true,
  };

  return (
    <BaseAgGrid<Process>
      loading={isLoading}
      rowData={processes}
      columnDefs={columns}
      quickFilterText={filterText}
      rowSelection={canEditProcesses ? rowSelection : undefined}
      onRowSelected={handleRowSelected}
    />
  );
}
