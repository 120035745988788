import GroupName from "@/components/GroupName";
import UserName from "@/components/UserName";
import { Action, ActionOperation, ActionPermissions } from "@/types/actions";
import { meridianNanoid } from "@/utils/meridianNanoid";
import { Flex, IconButton, Separator, Text } from "@radix-ui/themes";
import {
  IconCirclePlus2,
  IconLock,
  IconLockOpen2,
  IconPlus,
  IconStatusChange,
  IconTrashX,
} from "@tabler/icons-react";
import {
  Node,
  NodeProps,
  NodeToolbar,
  Position,
  XYPosition,
} from "@xyflow/react";
import { useMemo } from "react";
import { useWorkflowEditorContext } from "../WorkflowEditorContext";
import BaseNode from "./BaseNode";
import { createStatusNode } from "./StatusNode";

export type ActionNodeData = Omit<Action, "id">;
export type ActionNodeType = Node<ActionNodeData, "action">;
interface CreateActionNodeParams {
  recordTypeId: string;
  operation: ActionOperation;
  position?: XYPosition;
}
export const createActionNode = ({
  recordTypeId,
  operation,
  position,
}: CreateActionNodeParams): ActionNodeType => {
  return {
    id: meridianNanoid(),
    type: "action",
    data: {
      recordTypeId,
      operation: operation ?? ActionOperation.create({ endStatus: "" }),
      name: "New action",
      permissions: ActionPermissions.noFilter({}),
      form: {
        fields: [],
      },
    },
    position: {
      x: 0,
      y: 0,
      ...position,
    },
  };
};

export default function ActionNode(props: NodeProps<ActionNodeType>) {
  const { id, data, positionAbsoluteX, positionAbsoluteY } = props;
  const { addNode, addEdge, getInboundEdges, getOutboundEdges } =
    useWorkflowEditorContext();

  const permissions = ActionPermissions.visit(data.permissions, {
    noFilter: () => (
      <Flex align="center" gap="1">
        <IconLockOpen2 color="var(--gray-11)" />
        <Text size="1">All users can execute</Text>
      </Flex>
    ),
    userFilter: (userFilter) => {
      return (
        <Flex align="center" gap="1">
          <IconLock color="var(--gray-11)" />
          {/* TODO Handle multiple selected users properly */}
          <Text size="1" color="gray">
            Users:
          </Text>
          {userFilter.userIds.map((userId) => (
            <UserName userId={userId} size="1" color="gray" />
          ))}
        </Flex>
      );
    },
    groupFilter: (groupFilter) => {
      return (
        <Flex align="center" gap="1">
          <IconLock color="var(--gray-11)" />
          <Text size="1" color="gray">
            Groups:
          </Text>
          {groupFilter.groupIds.map((groupId) => (
            <GroupName groupId={groupId} size="1" color="gray" />
          ))}
        </Flex>
      );
    },
  });

  const shouldShowNodeToolbar = useMemo(() => {
    const outboundEdges = getOutboundEdges(id);
    return (
      (ActionOperation.isCreate(data.operation) &&
        outboundEdges.length === 0) ||
      (ActionOperation.isUpdate(data.operation) && outboundEdges.length === 0)
    );
  }, [id, data.operation, getOutboundEdges]);

  return (
    <>
      <BaseNode
        {...props}
        baseNodeProps={{
          title: data.name,
          icon: ActionOperation.visit(data.operation, {
            create: () => <IconCirclePlus2 />,
            update: () => <IconStatusChange />,
            delete: () => <IconTrashX />,
          }),
          hideTopHandle: ActionOperation.isCreate(data.operation),
          hideBottomHandle: ActionOperation.isDelete(data.operation),
        }}
      >
        <>
          <Separator size="4" />

          <Flex direction="column" flexGrow="1" gap="1" p="2">
            {permissions}
          </Flex>
        </>
      </BaseNode>
      {shouldShowNodeToolbar && (
        <NodeToolbar position={Position.Bottom}>
          <IconButton
            onClick={() => {
              const statusNode = createStatusNode({
                x: positionAbsoluteX,
                y: positionAbsoluteY + 100,
              });
              addNode(statusNode, true);
              addEdge({
                id: meridianNanoid(),
                source: id,
                target: statusNode.id,
              });
            }}
          >
            <IconPlus />
          </IconButton>
        </NodeToolbar>
      )}
    </>
  );
}

