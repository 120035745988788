import { CreateOrUpdateGroupAction } from "@/components/groups/CreateOrUpdateGroupAction";
import { DeleteGroupAction } from "@/components/groups/DeleteGroupAction";
import { SearchFilter } from "@/components/SearchFilter";
import { BaseAgGrid } from "@/components/tables/BaseAgGrid";
import {
  TenantGroup,
  useTenantGroupsWithUserData,
} from "@/state/queries/tenantGroups";
import { TenantUser } from "@/state/queries/tenantUsers";
import { Flex } from "@radix-ui/themes";
import { createFileRoute } from "@tanstack/react-router";
import {
  ColDef,
  ICellRendererParams,
  RowSelectedEvent,
} from "ag-grid-community";
import { useState } from "react";

export const Route = createFileRoute(
  "/_app/$tenantSlug/settings/organization/members/groups"
)({
  beforeLoad: () => ({ getTitle: () => "Groups" }),
  component: Groups,
});

function Groups() {
  const { data: groups, isLoading } = useTenantGroupsWithUserData();
  const [filter, setFilter] = useState("");
  const [selectedGroups, setSelectedGroups] = useState<TenantGroup[]>([]);

  const groupActions = (
    <Flex align="center" gap="2">
      {selectedGroups.length === 1 && (
        <DeleteGroupAction
          group={selectedGroups[0]}
          resetSelectedGroups={() => setSelectedGroups([])}
        />
      )}
      {selectedGroups.length === 1 && (
        <CreateOrUpdateGroupAction
          operation="update"
          group={selectedGroups[0]}
        />
      )}
      {!selectedGroups.length && (
        <CreateOrUpdateGroupAction operation="create" />
      )}
    </Flex>
  );

  const columnDefs: ColDef<TenantGroup>[] = [
    { field: "name", headerName: "Name", width: 250 },
    {
      field: "users",
      headerName: "Members",
      flex: 1,
      cellRenderer: ({
        value,
      }: ICellRendererParams<TenantGroup, TenantUser[]>) =>
        value?.map((user) => user.fullName ?? user.email).join(", "),
    },
  ];

  const handleRowSelected = (event: RowSelectedEvent<TenantGroup>) => {
    const selectedRows = event.api.getSelectedRows();
    setSelectedGroups(selectedRows);
  };

  return (
    <>
      <Flex justify="between" align="center" py="2">
        <SearchFilter
          filter={filter}
          setFilter={setFilter}
          placeholder="Search groups"
        />
        {groupActions}
      </Flex>
      <BaseAgGrid
        loading={isLoading}
        columnDefs={columnDefs}
        rowData={groups ?? []}
        quickFilterText={filter}
        getRowId={({ data }) => data.id}
        rowSelection={{
          mode: "singleRow",
          enableClickSelection: true,
          enableSelectionWithoutKeys: true,
        }}
        onRowSelected={handleRowSelected}
      />
    </>
  );
}
