import { useTenantContext } from "@/contexts/TenantContext";
import { supabase } from "@/supabaseClient";
import { RecordField } from "@/types/recordFields";
import { maybeConvertApiError } from "@/utils/requests";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export interface AddRecordTypeFieldRequest {
  tenantId: string;
  recordTypeId: string;
  field: RecordField;
}

const addRecordTypeField = async (input: AddRecordTypeFieldRequest) => {
  const { error } = await supabase.functions.invoke("record-types/fields", {
    method: "POST",
    body: input,
  });

  if (error) {
    const apiError = await maybeConvertApiError(error);
    return Promise.reject(apiError);
  }
};

export const useAddRecordField = () => {
  const queryClient = useQueryClient();
  const tenant = useTenantContext();

  return useMutation({
    mutationFn: ({
      recordTypeId,
      field,
    }: {
      recordTypeId: string;
      field: RecordField;
    }) => {
      if (!tenant.tenant) {
        throw new Error("Tenant not found");
      }
      return addRecordTypeField({
        recordTypeId,
        field,
        tenantId: tenant.tenant.tenantId,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["recordAndLinkTypes", tenant.tenant?.tenantId],
      });
    },
  });
};
