import { useTenantContext } from "@/contexts/TenantContext";
import { Json } from "@/database/generated.types";
import { supabase } from "@/supabaseClient";
import { WorkflowDefinition } from "@/types/workflows";
import { useMutation, useQueryClient } from "@tanstack/react-query";

interface UpdateWorkflowDefinitionInput {
  tenantId: string;
  workflowId: string;
  workflowDefinition: WorkflowDefinition;
}

const updateWorkflowDefinition = async (
  input: UpdateWorkflowDefinitionInput
) => {
  const { error } = await supabase
    .from("workflows")
    .update({
      // Supabase typings suck: https://github.com/supabase/postgres-meta/issues/676
      workflow: input.workflowDefinition as unknown as Json,
    })
    .eq("id", input.workflowId)
    .eq("tenant_id", input.tenantId);

  if (error) {
    throw error;
  }
};

export const useUpdateWorkflowDefinition = () => {
  const queryClient = useQueryClient();
  const tenant = useTenantContext();

  return useMutation({
    mutationFn: (input: Omit<UpdateWorkflowDefinitionInput, "tenantId">) => {
      if (!tenant.tenant) {
        throw new Error("Tenant not found");
      }
      return updateWorkflowDefinition({
        ...input,
        tenantId: tenant.tenant.tenantId,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["workflows", tenant.tenant?.tenantId],
      });
    },
  });
};
