import { useTenantContext } from "@/contexts/TenantContext";
import { THEME_COLOR } from "@/theme";
import { Box, Button, ButtonProps, Flex, Text } from "@radix-ui/themes";
import { Link, useLocation } from "@tanstack/react-router";
import React, { PropsWithChildren } from "react";
interface SidebarButtonProps extends ButtonProps {
  label: string;
  icon: React.ReactElement;
  to?: string;
  exactMatchUrl?: boolean;
}

const SidebarButton: React.FC<PropsWithChildren<SidebarButtonProps>> =
  React.forwardRef((props, ref) => {
    const { tenant } = useTenantContext();
    const location = useLocation();
    const { label, icon, to, exactMatchUrl } = props;

    const toWithTenantSlug = to ? `/${tenant.slug}${to}` : undefined;

    const isActive =
      toWithTenantSlug &&
      (exactMatchUrl
        ? location.pathname === toWithTenantSlug
        : location.pathname.startsWith(toWithTenantSlug));

    const element = (
      <Flex align="center" gap="1" py="2px" ml={!isActive ? "1" : undefined}>
        <Box height="var(--space-4)" width="var(--space-4)">
          {icon &&
            React.cloneElement(icon, {
              style: {
                color: isActive ? "var(--accent-11)" : "var(--gray-11)",
              },
            })}
        </Box>
        <Text
          weight={isActive ? "bold" : "medium"}
          style={{ lineHeight: "1.2" }}
        >
          {label}
        </Text>
      </Flex>
    );
    return (
      <Button
        {...props}
        asChild={!!to}
        ref={ref as any}
        variant="ghost"
        size="2"
        color={isActive ? THEME_COLOR : "gray"}
        data-state={isActive ? "open" : "closed"}
        style={{
          justifyContent: "flex-start",
          borderLeft: isActive
            ? "var(--space-1) solid var(--accent-11)"
            : undefined,
        }}
      >
        {toWithTenantSlug ? (
          <Link to={toWithTenantSlug}>{element}</Link>
        ) : (
          element
        )}
      </Button>
    );
  });

export default SidebarButton;
