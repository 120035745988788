import EmptyPage from "@/components/EmptyPage";
import MeridianTabNav from "@/components/MeridianTabNav";
import PageTitle from "@/components/PageTitle";
import { DeleteRecordTypeAction } from "@/components/record-types/DeleteRecordTypeAction";
import {
  recordAndLinkTypesQueryOptions,
  useRecordTypeById,
} from "@/state/queries/recordLinkTypes";
import { Box, Flex } from "@radix-ui/themes";
import {
  IconBrush,
  IconFile,
  IconId,
  IconListDetails,
} from "@tabler/icons-react";
import { createFileRoute, Outlet } from "@tanstack/react-router";

export const Route = createFileRoute(
  "/_app/$tenantSlug/settings/organization/record-types/$recordTypeId"
)({
  beforeLoad: async ({ context, params }) => {
    if (!context.tenant) {
      return { getTitle: () => "Record Type" };
    }
    const recordTypes = await context.queryClient.ensureQueryData(
      recordAndLinkTypesQueryOptions(context.tenant.tenantId)
    );
    const recordType = recordTypes.recordTypes.find(
      (rt) => rt.id === params.recordTypeId
    );
    return {
      getTitle: () => recordType?.pluralName ?? "Record Type",
    };
  },
  component: RecordType,
});

function RecordType() {
  const { tenantSlug, recordTypeId } = Route.useParams();
  const { data: recordType } = useRecordTypeById(recordTypeId);
  const navigate = Route.useNavigate();

  if (!recordType) {
    return <EmptyPage icon={<IconFile />} title="Record type not found" />;
  }

  return (
    <Flex direction="column" gap="2">
      <PageTitle
        tag="Record type"
        title={recordType.pluralName ?? "Record Type"}
        description={recordType.description}
      >
        <DeleteRecordTypeAction
          recordType={recordType}
          onDelete={() => {
            navigate({
              to: "/$tenantSlug/settings/organization/record-types",
              params: {
                tenantSlug,
              },
            });
          }}
        />
      </PageTitle>
      <Box>
        <MeridianTabNav<"/$tenantSlug/settings/organization/record-types/$recordTypeId">
          childRouteParams={{ tenantSlug, recordTypeId }}
          links={[
            {
              to: "/$tenantSlug/settings/organization/record-types/$recordTypeId/",
              label: "Fields",
              icon: <IconListDetails />,
            },
            {
              to: "/$tenantSlug/settings/organization/record-types/$recordTypeId/general",
              label: "General",
              icon: <IconId />,
            },
            {
              to: "/$tenantSlug/settings/organization/record-types/$recordTypeId/appearance",
              label: "Appearance",
              icon: <IconBrush />,
            },
          ]}
        />
        <Outlet />
      </Box>
    </Flex>
  );
}
