import { createClient } from "@supabase/supabase-js";
import { Database } from "./database/generated.types";

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_ANON_KEY;

export const supabase = createClient<Database>(
  supabaseUrl ?? "",
  supabaseAnonKey ?? ""
);

/**
 * Defining separate client here so that we could have record storage in
 * a separate per-tenant database in the future. Also typing as "any" because
 * the record schema is user-defined, so more specific typings aren't possible.
 */
export const supabaseRecords = createClient<any>(
  supabaseUrl ?? "",
  supabaseAnonKey ?? ""
);
