import { useTenantContext } from "@/contexts/TenantContext";
import { supabase } from "@/supabaseClient";
import { useMutation, useQueryClient } from "@tanstack/react-query";

interface RemoveProcessControlInput {
  processId: string;
  frameworkId: string;
  clause: string;
}

const removeControl = async (
  input: RemoveProcessControlInput,
  tenantId: string
) => {
  const { error } = await supabase
    .from("controls")
    .delete()
    .eq("process_id", input.processId)
    .eq("framework_id", input.frameworkId)
    .eq("clause", input.clause)
    .eq("tenant_id", tenantId);

  if (error) {
    throw error;
  }
};

export const useRemoveControl = () => {
  const queryClient = useQueryClient();
  const tenant = useTenantContext();

  return useMutation({
    mutationFn: (input: RemoveProcessControlInput) => {
      if (!tenant.tenant) {
        throw new Error("Tenant not found");
      }
      return removeControl(input, tenant.tenant.tenantId);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["processes", tenant.tenant?.tenantId],
      });
    },
  });
};
