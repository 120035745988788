import { supabase } from "@/supabaseClient";
import { queryOptions, useQuery } from "@tanstack/react-query";

export interface UserInfo {
  id: string;
  email: string;
  name: string;
  tenantRoles: { [tenantId: string]: string };
}

const fetchUser = async () => {
  const [
    { data: userData, error: userError },
    { data: sessionData, error: sessionError },
  ] = await Promise.all([supabase.auth.getUser(), supabase.auth.getSession()]);

  if (userError) {
    throw userError;
  }
  if (sessionError) {
    throw sessionError;
  }

  const { data: profileData } = await supabase
    .schema("tenants")
    .from("user_profiles")
    .select("full_name")
    .eq("id", userData.user?.id)
    .single();

  const token = sessionData.session?.access_token;
  if (!userData.user || !token) {
    return undefined;
  }

  const jwtPayload = JSON.parse(atob(token.split(".")[1]));
  const tenantRoles = jwtPayload.tenant_roles;

  return {
    id: userData.user.id,
    email: userData.user.email ?? "",
    name:
      profileData?.full_name ??
      userData.user.user_metadata?.full_name ??
      userData.user.email ??
      "",
    tenantRoles,
  };
};

export const userQueryOptions = queryOptions({
  queryKey: ["user"],
  queryFn: fetchUser,
  staleTime: Infinity,
  retry: false,
});

export const useUser = () => {
  return useQuery(userQueryOptions);
};

export const useUserIsLoaded = () => {
  const user = useUser();
  return user.isFetched && user.data !== undefined;
};
