import { useTenantContext } from "@/contexts/TenantContext";
import { supabase } from "@/supabaseClient";
import { useMutation, useQueryClient } from "@tanstack/react-query";

const deleteGroup = async (groupId: string, tenantId: string) => {
  const { error } = await supabase
    .schema("tenants")
    .from("tenant_groups")
    .delete()
    .eq("id", groupId)
    .eq("tenant_id", tenantId);

  if (error) {
    throw error;
  }
};

export const useDeleteGroup = () => {
  const queryClient = useQueryClient();
  const tenant = useTenantContext();

  return useMutation({
    mutationFn: (groupId: string) => {
      if (!tenant.tenant) {
        throw new Error("Tenant not found");
      }
      return deleteGroup(groupId, tenant.tenant.tenantId);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["tenantGroups", tenant.tenant?.tenantId],
      });
      queryClient.invalidateQueries({
        queryKey: ["userGroups", tenant.tenant?.tenantId],
      });
    },
  });
};
