import { useTenantContext } from "@/contexts/TenantContext";
import { supabase } from "@/supabaseClient";
import { maybeConvertApiError } from "@/utils/requests";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export interface DeleteRecordTypeBody {
  tenantId: string;
  recordTypeId: string;
}

const deleteRecordType = async (input: DeleteRecordTypeBody) => {
  const { error } = await supabase.functions.invoke("record-types", {
    method: "DELETE",
    body: input,
  });

  if (error) {
    const apiError = await maybeConvertApiError(error);
    return Promise.reject(apiError);
  }
};

export const useDeleteRecordType = () => {
  const queryClient = useQueryClient();
  const tenant = useTenantContext();

  return useMutation({
    mutationFn: (recordTypeId: string) => {
      if (!tenant.tenant) {
        throw new Error("Tenant not found");
      }
      return deleteRecordType({
        recordTypeId,
        tenantId: tenant.tenant.tenantId,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["recordAndLinkTypes", tenant.tenant?.tenantId],
      });
    },
  });
};
