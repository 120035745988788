import { ReactFormApi } from "@tanstack/react-form";

import Form from "@/components/forms";
import { FormFieldLabel } from "@/components/forms/FormField";
import { RecordFieldTypeLabel } from "@/components/record-types/RecordFieldTypeLabel";
import { RecordFieldBaseType } from "@/types/recordFields";
import { Flex, TextField } from "@radix-ui/themes";
import { FormApi } from "@tanstack/react-form";
import { ActionConfiguration } from "./EditActionDialogContent";
import { FormFieldTypeConfiguration } from "./FormFieldTypeConfiguration";

export const ActionFormFieldConfiguration = ({
  form,
  index,
}: {
  form: FormApi<ActionConfiguration> & ReactFormApi<ActionConfiguration>;
  index: number;
}) => {
  return (
    <Form.Fields pl="1" pr="2" key={index}>
      <Form.TextField<ActionConfiguration>
        reactFormField={{
          form,
          name: `form.fields[${index}].id`,
        }}
        fieldProps={{ label: "ID", required: true, size: "2" }}
        required
      />
      <Form.TextField<ActionConfiguration>
        reactFormField={{
          form,
          name: `form.fields[${index}].name`,
        }}
        fieldProps={{ label: "Name", required: true, size: "2" }}
        required
      />

      <Form.Switch<ActionConfiguration>
        reactFormField={{
          form,
          name: `form.fields[${index}].required`,
        }}
        fieldProps={{ label: "Required?", size: "2" }}
      />

      <form.Field
        name={`form.fields[${index}].type`}
        children={(field) => {
          return (
            <Flex direction="column" gap="1">
              <FormFieldLabel name={field.name} label="Field Type" />
              <TextField.Root disabled>
                <TextField.Slot>
                  <RecordFieldTypeLabel
                    type={field.state.value.type as RecordFieldBaseType}
                  />
                </TextField.Slot>
              </TextField.Root>
            </Flex>
          );
        }}
      />

      <FormFieldTypeConfiguration form={form} index={index} />
    </Form.Fields>
  );
};
