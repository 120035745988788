import { useTenantContext } from "@/contexts/TenantContext";
import { supabase } from "@/supabaseClient";
import { meridianNanoid } from "@/utils/meridianNanoid";
import { useMutation, useQueryClient } from "@tanstack/react-query";

interface UpdateOrgLogoOptions {
  file: File;
  tenantId: string;
}

async function updateOrgLogo({ file, tenantId }: UpdateOrgLogoOptions) {
  const fileExt = file.name.split(".").pop();
  const filePath = `${tenantId}/${meridianNanoid()}.${fileExt}`;

  const { error: uploadError } = await supabase.storage
    .from("org_logos")
    .upload(filePath, file);

  if (uploadError) {
    throw uploadError;
  }

  const { error: updateError } = await supabase
    .schema("tenants")
    .from("tenants")
    .update({ logo_path: filePath })
    .eq("id", tenantId);

  if (updateError) {
    throw updateError;
  }

  return filePath;
}

export function useUpdateOrgLogo() {
  const { tenant } = useTenantContext();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (file: File) =>
      updateOrgLogo({ file, tenantId: tenant.tenantId }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["tenantLogo", tenant.tenantId],
      });
    },
  });
}
