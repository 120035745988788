import Form from "@/components/forms";
import { useEditControl } from "@/state/mutations/processes/editControl";
import { Process, ProcessControl } from "@/state/queries/processes";
import { Dialog } from "@radix-ui/themes";
import { useForm } from "@tanstack/react-form";
import { PropsWithChildren, useState } from "react";

interface EditControlActionProps {
  process: Process;
  control: ProcessControl;
}

interface EditControlFormInputs {
  title: string;
  language: string;
}

export const EditControlAction: React.FC<
  PropsWithChildren<EditControlActionProps>
> = ({ process, control, children }) => {
  const [open, setOpen] = useState(false);
  const { mutate: editControl, error, reset, isPending } = useEditControl();

  const handleOpenChange = (open: boolean) => {
    setOpen(open);
    form.reset();
    reset();
  };

  const form = useForm({
    defaultValues: {
      title: control.title,
      language: control.language,
    },
    onSubmit: ({ value }) => {
      editControl(
        {
          processId: process.id,
          frameworkId: control.frameworkId,
          clause: control.clause,
          title: value.title,
          language: value.language,
        },
        { onSuccess: () => setOpen(false) }
      );
    },
  });

  return (
    <Dialog.Root open={open} onOpenChange={handleOpenChange}>
      <Dialog.Trigger>{children}</Dialog.Trigger>

      <Dialog.Content maxWidth="600px">
        <Dialog.Title>Edit control</Dialog.Title>
        <Form.Root reactForm={form}>
          <Form.Fields>
            <Form.TextField<EditControlFormInputs>
              reactFormField={{ form, name: "title" }}
              fieldProps={{
                label: "Title",
                required: true,
              }}
            />

            <Form.TextArea<EditControlFormInputs>
              reactFormField={{ form, name: "language" }}
              fieldProps={{
                label: "Language",
                required: true,
              }}
              rows={12}
            />
          </Form.Fields>

          <Form.Footer
            form={form}
            error={error}
            isPending={isPending}
            buttonText="Save changes"
            errorPrefix="Failed to edit control: "
          />
        </Form.Root>
      </Dialog.Content>
    </Dialog.Root>
  );
};
