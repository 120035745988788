import { createFileRoute, redirect } from "@tanstack/react-router";

export const Route = createFileRoute("/_app/$tenantSlug/processes/$processId/")({
  beforeLoad: ({ params }) => {
    throw redirect({
      to: `/${params.tenantSlug}/processes/${params.processId}/controls`,
      replace: true,
    });
  },
});
