import { MultiSelect } from "@/components/multiselect/MultiSelect";
import { useRecordTypeById } from "@/state/queries/recordLinkTypes";
import { useRecords } from "@/state/queries/records";
import { RecordFieldValues } from "@/types/records";
import { DeepKeys, useField } from "@tanstack/react-form";
import { PropsWithChildren } from "react";
import { FormField } from "../FormField";
import { BaseFieldProps } from "../types";

export interface RecordMultiSelectProps<TData> extends BaseFieldProps<TData> {
  recordTypeId: string;
}

export function RecordMultiSelect<TData>(
  props: PropsWithChildren<RecordMultiSelectProps<TData>>
) {
  const { reactFormField, fieldProps } = props;
  const field = useField<TData, DeepKeys<TData>>(reactFormField);
  const { data: recordType } = useRecordTypeById(props.recordTypeId);
  const { data: records } = useRecords(props.recordTypeId);

  const titleFieldId = recordType?.titleFieldId;

  const options = Object.values(records ?? {}).map((record) => ({
    label: titleFieldId ? `${record[titleFieldId]} (${record.id})` : record.id,
    value: record.id,
    data: record,
  }));

  const value = (field.state.value as string[]) ?? [];

  return (
    <FormField
      reactForm={reactFormField.form}
      reactFormField={field}
      {...fieldProps}
    >
      <MultiSelect<RecordFieldValues>
        selectedValues={value}
        onChange={(value) => field.setValue(value as any)}
        options={options}
      />
    </FormField>
  );
}
