import { ExecutableAction } from "@/hooks/useActionTypes";
import { useCanExecuteAction } from "@/hooks/useCanExecuteAction";
import { ActionOperation } from "@/types/actions";
import { Button, ButtonProps, Tooltip } from "@radix-ui/themes";
import { IconPencil, IconPlus, IconTrash } from "@tabler/icons-react";

export interface ActionButtonProps {
  action: ExecutableAction;
  onClick: () => void;
}

export const ActionButton: React.FC<ActionButtonProps> = ({ action, onClick }) => {
  const { hasExecutePermission, message } = useCanExecuteAction(action);

  const buttonProps: ButtonProps = {
    onClick,
    disabled: !hasExecutePermission,
  };
  let icon: React.ReactNode | undefined;

  ActionOperation.visit(action.operation, {
    create: () => {
      icon = <IconPlus />;
    },
    update: () => {
      buttonProps["variant"] = "soft";
      icon = <IconPencil />;
    },
    delete: () => {
      buttonProps["variant"] = "soft";
      buttonProps["color"] = "red";
      icon = <IconTrash />;
    },
  });

  let button = (
    <Button {...buttonProps}>
      {icon}
      {action.name}
    </Button>
  );

  if (!hasExecutePermission) {
    button = (
      <Tooltip side="bottom" content={message} maxWidth="250px">
        {button}
      </Tooltip>
    );
  }

  return button;
};
