import { Flex, Spinner, Text } from "@radix-ui/themes";
import { CSSProperties, PropsWithChildren } from "react";
import { FormError } from "./FormError";
import { FormFieldProps, GenericFieldApi, GenericFormApi } from "./types";
import { hasError } from "./utils";

export const FormField: React.FC<PropsWithChildren<FormFieldProps>> = ({
  reactForm,
  reactFormField,
  label,
  labelChildren,
  required,
  helperText,
  children,
  style,
}) => {
  return (
    <FormFieldWrapper style={style}>
      <FormFieldLabel
        name={reactFormField.name}
        label={label}
        required={required}
      >
        {labelChildren}
      </FormFieldLabel>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
      {children}
      <FormFieldValidation form={reactForm} field={reactFormField} />
    </FormFieldWrapper>
  );
};

export const FormFieldWrapper: React.FC<
  PropsWithChildren<{ style?: CSSProperties }>
> = ({ children, style }) => {
  return (
    <Flex direction="column" gap="1" style={style}>
      {children}
    </Flex>
  );
};

interface FormFieldLabelProps {
  name: string;
  label?: string;
  required?: boolean;
}

export const FormFieldLabel: React.FC<
  PropsWithChildren<FormFieldLabelProps>
> = ({ name, label, required, children }) => {
  const requiredAsterisk = required ? <Text color="red"> *</Text> : null;
  return (
    <Flex align="center" gap="3">
      <label htmlFor={name}>
        <Text size="3" weight="bold">
          {label}
        </Text>
        {requiredAsterisk}
      </label>
      {children}
    </Flex>
  );
};

export const FormHelperText: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <Text size="2" color="gray">
      {children}
    </Text>
  );
};

const FormFieldValidation: React.FC<{
  form: GenericFormApi<any>;
  field: GenericFieldApi;
}> = ({ form, field }) => {
  const submissionAttempts = form.useStore((form) => form.submissionAttempts);
  let firstError;
  if (hasError(field.state.meta, submissionAttempts)) {
    firstError = <FormError message={field.state.meta.errors[0]?.toString()} />;
  }
  return (
    <Spinner loading={field.state.meta.isValidating}>
      {firstError
        ? firstError
        : field.state.meta.isValidating
          ? "Validating..."
          : ""}
    </Spinner>
  );
};
