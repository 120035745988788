import { PermissionedButton } from "@/components/PermissionedButton";
import { useRemoveControl } from "@/state/mutations/processes/removeControl";
import { AlertDialog, Button, Flex, Text } from "@radix-ui/themes";
import { IconTrash } from "@tabler/icons-react";
import { useState } from "react";
import { FormError } from "../forms/FormError";

interface RemoveControlProps {
  processId: string;
  frameworkId: string;
  clause: string;
}

export const RemoveControlAction: React.FC<RemoveControlProps> = ({
  processId,
  frameworkId,
  clause,
}) => {
  const [open, setOpen] = useState(false);
  const { mutate: removeControl, isPending, error, reset } = useRemoveControl();

  const onSubmit = () => {
    removeControl(
      { processId, frameworkId, clause },
      {
        onSuccess: () => {
          setOpen(false);
        },
      }
    );
  };

  const onOpenChange = (open: boolean) => {
    setOpen(open);
    if (!open) {
      reset();
    }
  };

  return (
    <AlertDialog.Root open={open} onOpenChange={onOpenChange}>
      <AlertDialog.Trigger>
        <PermissionedButton
          permission="write.processes"
          variant="soft"
          color="gray"
          size="1"
          iconButton
        >
          <IconTrash />
        </PermissionedButton>
      </AlertDialog.Trigger>
      <AlertDialog.Content maxWidth="450px">
        <AlertDialog.Title>Remove control</AlertDialog.Title>
        <AlertDialog.Description>
          <Flex direction="column" gap="2">
            <Text>
              Are you sure? This control will be removed from the process and
              the associated language will be deleted.
            </Text>
            {error && <FormError message={error.message} />}
          </Flex>
        </AlertDialog.Description>

        <Flex gap="3" mt="4" justify="end">
          <AlertDialog.Cancel>
            <Button variant="soft" color="gray">
              Cancel
            </Button>
          </AlertDialog.Cancel>
          <Button color="red" loading={isPending} onClick={onSubmit}>
            Remove control
          </Button>
        </Flex>
      </AlertDialog.Content>
    </AlertDialog.Root>
  );
};
