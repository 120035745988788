import { useTenantContext } from "@/contexts/TenantContext";
import { useRecordTypeById } from "@/state/queries/recordLinkTypes";
import { useRecord } from "@/state/queries/records";
import { SYSTEM_RECORD_FIELD_IDS } from "@/types/recordFieldConstants";
import { Flex, HoverCard, Text } from "@radix-ui/themes";
import { Link } from "@tanstack/react-router";
import React from "react";
import { RecordFieldsDataList } from "./RecordFieldsDataList";

interface RecordLinkProps {
  recordTypeId: string;
  recordId: string;
}

export const RecordLink: React.FC<RecordLinkProps> = ({
  recordTypeId,
  recordId,
}) => {
  const { tenant } = useTenantContext();
  const { data: recordType } = useRecordTypeById(recordTypeId);
  const { data: record } = useRecord(recordTypeId, recordId);

  const tenantSlug = tenant.slug;

  let hoverCardContent;
  if (recordType && record) {
    hoverCardContent = (
      <Flex direction="column" gap="2">
        <Text weight="bold">{record?.id}</Text>
        <RecordFieldsDataList
          recordType={recordType}
          record={record}
          // Just show built-in fields for now
          displayOptions={{
            fieldsToShow: SYSTEM_RECORD_FIELD_IDS,
          }}
        />
      </Flex>
    );
  }

  return (
    <HoverCard.Root openDelay={500}>
      <HoverCard.Trigger>
        <Link
          to="/$tenantSlug/records/$recordTypeId/$recordId"
          params={{ tenantSlug, recordTypeId, recordId }}
        >
          {recordId}
        </Link>
      </HoverCard.Trigger>
      <HoverCard.Content>{hoverCardContent}</HoverCard.Content>
    </HoverCard.Root>
  );
};
