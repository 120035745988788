import { useTenantContext } from "@/contexts/TenantContext";
import { supabase } from "@/supabaseClient";
import { RecordFilter, RecordViewConfig, ViewType } from "@/types/recordViews";
import { queryOptions, useQuery } from "@tanstack/react-query";
import { useMemo } from "react";

const fetchRecordViews = async (
  tenantId: string
): Promise<RecordViewConfig[]> => {
  const { data, error } = await supabase
    .from("record_views")
    .select("*")
    .eq("tenant_id", tenantId);

  if (error) {
    throw error;
  }

  return (data || []).map((view) => ({
    id: view.id,
    name: view.name,
    recordTypeId: view.record_type_id,
    // TODO: Validate these
    defaultFilter:
      (view.default_filter as unknown as RecordFilter) ?? undefined,
    viewType: view.view_type as unknown as ViewType,
  }));
};

export const recordViewsQueryOptions = (tenantId: string) =>
  queryOptions({
    queryKey: ["recordViews", tenantId],
    queryFn: () => fetchRecordViews(tenantId),
    staleTime: 1000 * 60 * 5, // 5 minutes
    retry: false,
  });

export const useRecordViews = () => {
  const { tenant } = useTenantContext();
  return useQuery(recordViewsQueryOptions(tenant.tenantId));
};

export const useRecordViewsByRecordType = (recordTypeId: string) => {
  const { data: views, ...rest } = useRecordViews();

  const filteredViews = useMemo(
    () => views?.filter((view) => view.recordTypeId === recordTypeId),
    [views, recordTypeId]
  );

  return {
    ...rest,
    data: filteredViews,
  };
};

export const useRecordViewById = (viewId: string) => {
  const { data: views, ...rest } = useRecordViews();
  return {
    ...rest,
    data: views?.find((view) => view.id === viewId),
  };
};
