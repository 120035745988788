import Form from "@/components/forms";
import { useUpdateRecordTypeTitleField } from "@/state/mutations/record-types/updateRecordTypeTitleField";
import { RecordFieldType } from "@/types/recordFields";
import { RecordType } from "@/types/recordTypes";
import { Dialog, Select } from "@radix-ui/themes";
import { useForm } from "@tanstack/react-form";
import { zodValidator } from "@tanstack/zod-form-adapter";
import { PropsWithChildren, useState } from "react";
import { z } from "zod";

type FormInputs = {
  titleFieldId: string;
};

export const UpdateRecordTypeTitleFieldAction: React.FC<
  PropsWithChildren<{
    recordType: RecordType;
  }>
> = ({ recordType, children }) => {
  const [open, setOpen] = useState(false);

  const {
    mutate: updateTitleField,
    error,
    isPending,
    reset,
  } = useUpdateRecordTypeTitleField();

  const form = useForm<FormInputs, any>({
    defaultValues: {
      titleFieldId: recordType.titleFieldId || "",
    },
    validatorAdapter: zodValidator(),
    validators: {
      onChange: z.object({
        titleFieldId: z.string().min(1, { message: "Title field is required" }),
      }),
    },
    onSubmit: ({ value }) => {
      updateTitleField(
        {
          recordTypeId: recordType.id,
          titleFieldId: value.titleFieldId,
        },
        {
          onSuccess: () => setOpen(false),
        }
      );
    },
  });

  const handleOpenChange = (open: boolean) => {
    setOpen(open);
    reset();
    form.reset();
  };

  return (
    <Dialog.Root open={open} onOpenChange={handleOpenChange}>
      <Dialog.Trigger>{children}</Dialog.Trigger>
      <Dialog.Content>
        <Dialog.Title>Change title field</Dialog.Title>
        <Form.Root reactForm={form}>
          <Form.Select<FormInputs>
            reactFormField={{ form, name: "titleFieldId" }}
            fieldProps={{ label: "Title Field", required: true }}
          >
            <Select.Content>
              {recordType.fields
                // TODO: Filter to short text fields when we distinguish them
                .filter((field) => RecordFieldType.isText(field.type))
                .map((field) => (
                  <Select.Item key={field.id} value={field.id}>
                    {field.name}
                  </Select.Item>
                ))}
            </Select.Content>
          </Form.Select>
          <Form.Footer
            form={form}
            error={error}
            isPending={isPending}
            submitButtonProps={{ children: "Save" }}
          />
        </Form.Root>
      </Dialog.Content>
    </Dialog.Root>
  );
};
