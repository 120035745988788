import { Flex } from "@radix-ui/themes";
import { FormApi } from "@tanstack/react-form";
import { PropsWithChildren } from "react";

export interface FormRootProps {
  reactForm: FormApi<any, any>;
}

export const FormRoot: React.FC<PropsWithChildren<FormRootProps>> = ({
  reactForm,
  children,
}) => {
  return (
    <form
      autoComplete="off"
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
        reactForm.handleSubmit();
      }}
    >
      <Flex direction="column" gap="4">
        {children}
      </Flex>
    </form>
  );
};
