import Form from "@/components/forms";
import { ExecutableAction } from "@/hooks/useActionTypes";
import { useExecuteAction } from "@/state/mutations/actions/executeAction";
import { useRecordTypeById } from "@/state/queries/recordLinkTypes";
import { useUser } from "@/state/queries/user";
import {
  ActionFormField,
  ActionFormFieldTypeData,
  BooleanFormField,
  DateFormField,
  FileFormField,
  LinkFormField,
  NumberFormField,
  TextFormField,
  TimestampFormField,
  UserIdFormField,
  ValuesFormField,
} from "@/types/actionFormField";
import { ActionOperation } from "@/types/actions";
import { RecordFieldType } from "@/types/recordFields";
import { RecordFieldValues } from "@/types/records";
import { RecordType } from "@/types/recordTypes";
import {
  getActionDefaultValues,
  postProcessActionValues,
} from "@/utils/actions";
import { Dialog, Select, Text } from "@radix-ui/themes";
import { useForm } from "@tanstack/react-form";
import { zodValidator } from "@tanstack/zod-form-adapter";
import { z } from "zod";
import { FormFieldBaseProps, GenericFormApi } from "../forms/types";

export const ActionDialog: React.FC<{
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  action: ExecutableAction;
  recordType: RecordType;
  selectedRecord: RecordFieldValues | undefined;
  onActionExecuted: (action: ExecutableAction) => void;
}> = ({
  action,
  recordType,
  selectedRecord,
  isOpen,
  setIsOpen,
  onActionExecuted,
}) => {
  const { data: currentUser } = useUser();

  const {
    mutate: executeAction,
    error: executeActionError,
    isPending,
    reset: resetMutation,
  } = useExecuteAction();

  const form = useForm<any, any>({
    defaultValues: getActionDefaultValues(
      action,
      recordType,
      currentUser?.id ?? "",
      selectedRecord
    ),
    validatorAdapter: zodValidator(),
    // TODO Generate validators from action fields
    validators: {
      onChange: z.object({}),
    },
    onSubmit: ({ value }) => {
      executeAction(
        {
          action,
          recordType,
          recordId: selectedRecord?.id,
          fieldValues: postProcessActionValues(action, value),
        },
        {
          onSuccess: () => {
            onActionExecuted(action);
            setIsOpen(false);
          },
        }
      );
    },
  });

  const handleOpenChange = (open: boolean) => {
    setIsOpen(open);
    form.reset();
    resetMutation();
  };

  const formFields = action.form.fields.map((field) => (
    <ActionFormFieldComponent
      key={field.id}
      form={form}
      recordTypeId={action.recordTypeId}
      field={field}
    />
  ));

  const isDeleteAction = ActionOperation.isDelete(action.operation);

  let description = action.description;
  if (isDeleteAction && !description) {
    description =
      "Are you sure you want to delete this record? Data associated with this record will be removed from the system.";
  }

  return (
    <Dialog.Root open={isOpen} onOpenChange={handleOpenChange}>
      <Dialog.Content>
        <Dialog.Title>{action.name}</Dialog.Title>
        <Dialog.Description mb="2">
          <Text color="gray">{description}</Text>
        </Dialog.Description>
        <Form.Root reactForm={form}>
          <Form.Fields
            style={{
              overflowY: "scroll",
              maxHeight: "400px",
              paddingLeft: "var(--space-1)",
              paddingBottom: "var(--space-1)",
              paddingRight: "var(--space-4)",
              marginLeft: "calc(var(--space-1) * -1)",
            }}
          >
            {formFields}
          </Form.Fields>
          <Form.Footer
            form={form}
            error={executeActionError}
            isPending={isPending}
            buttonText={isDeleteAction ? "Delete" : "Submit"}
            errorPrefix="Failed to execute action: "
            submitButtonProps={{
              color: isDeleteAction ? "red" : undefined,
            }}
          />
        </Form.Root>
      </Dialog.Content>
    </Dialog.Root>
  );
};

const ActionFormFieldComponent: React.FC<{
  form: GenericFormApi<any>;
  recordTypeId: string;
  field: ActionFormField;
}> = ({ form, recordTypeId, field }) => {
  const { data: recordType } = useRecordTypeById(recordTypeId);
  const recordTypeField = recordType?.fields.find((f) => f.id === field.id);

  // TODO: Figure out what to do for fields that have been removed from the record type
  if (!recordTypeField) {
    return null;
  }

  const reactFormField = { form, name: field.id };
  const fieldProps: FormFieldBaseProps = {
    label: field.name,
    required: field.required,
    helperText: recordTypeField.description,
  };

  return ActionFormFieldTypeData.visit(field.type, {
    number: (value: NumberFormField) => {
      return (
        <Form.TextField
          reactFormField={reactFormField}
          fieldProps={fieldProps}
          type="number"
          placeholder="123"
        />
      );
    },
    boolean: (value: BooleanFormField) => {
      return (
        <Form.Switch reactFormField={reactFormField} fieldProps={fieldProps} />
      );
    },
    text: (value: TextFormField) => {
      const props = {
        reactFormField,
        fieldProps,
        rows: value.multiline ? 4 : undefined,
        placeholder: "Enter value",
      };
      return value.multiline ? (
        <Form.TextArea {...props} />
      ) : (
        <Form.TextField {...props} />
      );
    },
    date: (value: DateFormField) => {
      return (
        <Form.TextField
          reactFormField={reactFormField}
          fieldProps={fieldProps}
          type="date"
        />
      );
    },
    timestamp: (value: TimestampFormField) => {
      return (
        <Form.TextField
          reactFormField={reactFormField}
          fieldProps={fieldProps}
          type="datetime-local"
        />
      );
    },
    values: (value: ValuesFormField) => {
      let options: string[] = [];
      let allowMultiple;
      if (recordTypeField && RecordFieldType.isValues(recordTypeField.type)) {
        options = recordTypeField.type.values.options.map((o) => o.value);
        allowMultiple = recordTypeField.type.values.allowMultiple;
      }
      if (allowMultiple) {
        return (
          <Form.MultiSelect
            reactFormField={reactFormField}
            fieldProps={fieldProps}
            options={options.map((option) => ({
              label: option,
              value: option,
              data: option,
            }))}
          />
        );
      }

      return (
        <Form.Select reactFormField={reactFormField} fieldProps={fieldProps}>
          <Select.Content>
            {options.map((option) => (
              <Select.Item key={option} value={option}>
                {option}
              </Select.Item>
            ))}
          </Select.Content>
        </Form.Select>
      );
    },
    file: (value: FileFormField) => {
      if (!recordTypeField || !RecordFieldType.isFile(recordTypeField.type)) {
        return null;
      }

      const allowMultiple = recordTypeField.type.file.allowMultiple;
      return (
        <Form.FileDropzone
          multiple={allowMultiple}
          reactFormField={reactFormField}
          fieldProps={fieldProps}
        />
      );
    },
    link: (value: LinkFormField) => {
      // TODO: Figure out how to handle this
      if (!recordTypeField || !RecordFieldType.isLink(recordTypeField.type)) {
        return null;
      }

      const allowMultiple = recordTypeField.type.link.allowMultiple;
      if (allowMultiple) {
        return (
          <Form.RecordMultiSelect
            reactFormField={reactFormField}
            fieldProps={fieldProps}
            recordTypeId={recordTypeField.type.link.linkedRecordTypeId}
          />
        );
      }

      return (
        <Form.RecordSelect
          reactFormField={reactFormField}
          fieldProps={fieldProps}
          recordTypeId={recordTypeField.type.link.linkedRecordTypeId}
        />
      );
    },
    userId: (value: UserIdFormField) => {
      // TODO: Figure out how to handle this
      if (!recordTypeField || !RecordFieldType.isUserId(recordTypeField.type)) {
        return null;
      }

      const allowMultiple = recordTypeField.type.userId.allowMultiple;
      if (allowMultiple) {
        return (
          <Form.UserMultiSelect
            reactFormField={reactFormField}
            fieldProps={fieldProps}
          />
        );
      }

      return (
        <Form.UserSelect
          reactFormField={reactFormField}
          fieldProps={fieldProps}
        />
      );
    },
  });
};
