import { useTenantContext } from "@/contexts/TenantContext";
import { supabase } from "@/supabaseClient";
import { useMutation, useQueryClient } from "@tanstack/react-query";

interface ChangeNameParams {
  userId: string;
  tenantId: string;
  fullName: string;
}

export const changeName = async ({
  userId,
  tenantId,
  fullName,
}: ChangeNameParams) => {
  const { error } = await supabase
    .schema("tenants")
    .from("user_profiles")
    .update({ full_name: fullName })
    .eq("id", userId)
    .eq("tenant_id", tenantId);

  if (error) {
    throw error;
  }
};

export const useChangeName = () => {
  const queryClient = useQueryClient();
  const tenant = useTenantContext();
  if (!tenant.tenant) {
    throw new Error("Tenant not found");
  }
  return useMutation({
    mutationFn: (params: Omit<ChangeNameParams, "tenantId">) =>
      changeName({ ...params, tenantId: tenant.tenant.tenantId }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["tenantUsers", tenant.tenant.tenantId],
      });
      queryClient.invalidateQueries({ queryKey: ["user"] });
    },
  });
};
