import { StatusIconType } from "@/types/status";
import {
  IconAlertCircle,
  IconAlertCircleFilled,
  IconCircle,
  IconCircleCheck,
  IconCircleCheckFilled,
  IconCircleDashed,
  IconCircleDashedCheck,
  IconCircleDashedMinus,
  IconCircleDashedX,
  IconCircleFilled,
  IconCircleX,
  IconCircleXFilled,
  IconProps,
} from "@tabler/icons-react";
import React from "react";

export const STATUS_ICONS: Record<StatusIconType, React.ReactNode> = {
  // Progress states
  dashed: <IconCircleDashed />,
  circle: <IconCircle />,
  circleFilled: <IconCircleFilled />,

  // Approval states
  dashedCheck: <IconCircleDashedCheck />,
  check: <IconCircleCheck />,
  checkFilled: <IconCircleCheckFilled />,

  // Canceled states
  dashedCross: <IconCircleDashedX />,
  cross: <IconCircleX />,
  crossFilled: <IconCircleXFilled />,

  // Warning states
  dashedMinus: <IconCircleDashedMinus />,
  alert: <IconAlertCircle />,
  alertFilled: <IconAlertCircleFilled />,
};

const DEFAULT_ICON = <IconCircle />;

export interface StatusIconProps extends IconProps {
  icon?: StatusIconType;
}
export const StatusIcon: React.FC<StatusIconProps> = ({ icon, ...props }) => {
  if (!icon) {
    return React.cloneElement(DEFAULT_ICON, props);
  }
  if (!STATUS_ICONS[icon]) {
    return React.cloneElement(DEFAULT_ICON, props);
  }
  const iconElement = STATUS_ICONS[icon];
  return React.cloneElement(iconElement as any, props);
};
