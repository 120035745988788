import { useTenantContext } from "@/contexts/TenantContext";
import { supabase } from "@/supabaseClient";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export interface DeleteWorkflowBody {
  tenantId: string;
  workflowId: string;
}

const deleteWorkflow = async (input: DeleteWorkflowBody) => {
  const { error } = await supabase
    .from("workflows")
    .delete()
    .eq("id", input.workflowId)
    .eq("tenant_id", input.tenantId);

  if (error) {
    throw error;
  }
};

export const useDeleteWorkflow = () => {
  const queryClient = useQueryClient();
  const tenant = useTenantContext();

  return useMutation({
    mutationFn: (workflowId: string) => {
      if (!tenant.tenant) {
        throw new Error("Tenant not found");
      }
      return deleteWorkflow({
        workflowId,
        tenantId: tenant.tenant.tenantId,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["workflows", tenant.tenant?.tenantId],
      });
    },
  });
};
