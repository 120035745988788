import { Button, Flex, Text } from "@radix-ui/themes";
import { IconUpload } from "@tabler/icons-react";
import { DeepKeys, useField } from "@tanstack/react-form";
import { FormField } from "./FormField";
import { BaseFieldProps } from "./types";
import { hasError } from "./utils";

export interface BaseFileUploadProps<TData> extends BaseFieldProps<TData> {
  /**
   * The file type to accept.
   */
  accept?: string;
}

export function BaseFileUpload<TData>(props: BaseFileUploadProps<TData>) {
  const { reactFormField, fieldProps } = props;

  const field = useField<TData, DeepKeys<TData>>(reactFormField);

  const filename = (field.state.value as any)?.name;

  const submissionAttempts = reactFormField.form.useStore(
    (form) => form.submissionAttempts
  );
  const error = hasError(field.state.meta, submissionAttempts);
  return (
    <FormField
      reactForm={reactFormField.form}
      reactFormField={field}
      {...fieldProps}
    >
      <input
        type="file"
        id={field.name as string}
        name={field.name as string}
        onBlur={field.handleBlur}
        onChange={(e) => field.handleChange(e.target.files?.[0] as any)}
        hidden
        accept={props.accept}
      />
      <Flex align="center" gap="2">
        <Button
          variant={error ? "outline" : "soft"}
          size="2"
          color={error ? "red" : undefined}
          asChild
        >
          <label htmlFor={field.name as string} style={{ cursor: "pointer" }}>
            <IconUpload />
            Upload file
          </label>
        </Button>
        {filename && <Text size="3">{filename}</Text>}
      </Flex>
    </FormField>
  );
}
