import { customAlphabet } from "nanoid";

export const customNanoid = customAlphabet(
  "0123456789abcdefghijklmnopqrstuvwxyz",
  12
);

/**
 * Generates a 12-character nanoid using the custom alphabet. Matches the alphabet
 * and length of the IDs generated by the backend.
 */
export const meridianNanoid = () => customNanoid();
