import Form from "@/components/forms";
import { useAddRecordField } from "@/state/mutations/record-types/addRecordField";
import { useEditRecordField } from "@/state/mutations/record-types/editRecordField";
import { RecordField, RecordFieldType } from "@/types/recordFields";
import { Dialog, Flex, Grid, Separator } from "@radix-ui/themes";
import { IconPencil, IconPlus } from "@tabler/icons-react";
import { useForm } from "@tanstack/react-form";
import { zodValidator } from "@tanstack/zod-form-adapter";
import { useState } from "react";
import { z } from "zod";
import { PermissionedButton } from "../PermissionedButton";
import { GenericFormApi } from "../forms/types";

type FieldFormInputs = RecordField;

type Props = {
  mode: "add" | "edit";
  recordTypeId: string;
  recordField?: RecordField;
};

export const AddOrEditFieldAction: React.FC<Props> = ({
  mode,
  recordTypeId,
  recordField,
}) => {
  const [open, setOpen] = useState(false);

  const {
    mutate: addRecordField,
    error: addError,
    isPending: isAddPending,
    reset: resetAddMutation,
  } = useAddRecordField();

  const {
    mutate: editRecordField,
    error: editError,
    isPending: isEditPending,
    reset: resetEditMutation,
  } = useEditRecordField();

  const defaultValues =
    mode === "edit"
      ? recordField!
      : {
          name: "",
          id: "",
          type: RecordFieldType.text({}),
          description: "",
        };

  const form = useForm<FieldFormInputs, any>({
    defaultValues,
    validatorAdapter: zodValidator(),
    validators: {
      onChange: z.object({
        name: z.string().min(1, { message: "Field name is required" }),
        id: z
          .string()
          .min(1, { message: "Field id is required" })
          .regex(/^[a-z][a-z_]*$/, {
            message: "ID must be lowercase alphabetical with underscores",
          }),
        description: z.string(),
      }),
    },
    onSubmit: ({ value }) => {
      if (mode === "add") {
        addRecordField(
          {
            recordTypeId,
            field: value,
          },
          {
            onSuccess: () => setOpen(false),
          }
        );
      } else {
        editRecordField(
          {
            recordTypeId,
            fieldId: recordField!.id,
            field: value,
          },
          {
            onSuccess: () => setOpen(false),
          }
        );
      }
    },
  });

  const handleOpenChange = (open: boolean) => {
    setOpen(open);
    mode === "add" ? resetAddMutation() : resetEditMutation();
    form.reset();
  };

  return (
    <Dialog.Root open={open} onOpenChange={handleOpenChange}>
      <Dialog.Trigger>
        <PermissionedButton
          variant={mode === "edit" ? "soft" : "solid"}
          permission="write.processes"
        >
          {mode === "add" ? <IconPlus /> : <IconPencil />}
          {mode === "add" ? "Add field" : "Edit field"}
        </PermissionedButton>
      </Dialog.Trigger>
      <Dialog.Content>
        <Dialog.Title>
          {mode === "add" ? "Add field" : "Edit field"}
        </Dialog.Title>
        <Form.Root reactForm={form}>
          <Grid columns="2" gap="4">
            <Form.FieldTypeSelect<FieldFormInputs>
              reactFormField={{ form, name: "type" }}
              fieldProps={{
                label: "Type",
                required: true,
                style: { gridColumn: "span 2" },
              }}
            />
            <form.Subscribe
              selector={(state) => [state.values.type]}
              children={([type]) => <FieldOptions form={form} type={type} />}
            />

            <Separator size="4" my="1" style={{ gridColumn: "span 2" }} />

            <Form.TextField<FieldFormInputs>
              reactFormField={{ form, name: "name" }}
              fieldProps={{ label: "Name", required: true }}
              placeholder="Field name"
            />
            <Form.TextField<FieldFormInputs>
              reactFormField={{ form, name: "id" }}
              fieldProps={{ label: "ID", required: true }}
              placeholder="field_id"
              disabled={mode === "edit"}
            />
            <Form.TextArea<FieldFormInputs>
              reactFormField={{ form, name: "description" }}
              fieldProps={{
                label: "Description",
                style: { gridColumn: "span 2" },
              }}
              placeholder="Describe this field"
            />
          </Grid>

          <Form.Footer
            form={form}
            error={mode === "add" ? addError : editError}
            isPending={mode === "add" ? isAddPending : isEditPending}
            buttonText={mode === "add" ? "Add field" : "Save changes"}
            errorPrefix={`Failed to ${mode} field: `}
          />
        </Form.Root>
      </Dialog.Content>
    </Dialog.Root>
  );
};

const FieldOptions: React.FC<{
  form: GenericFormApi<FieldFormInputs>;
  type: RecordFieldType;
}> = ({ form, type }) => {
  const options = RecordFieldType.visit(type, {
    link: () => (
      <>
        <Form.RecordTypeSelect
          reactFormField={{ form, name: "type.link.linkedRecordTypeId" }}
          fieldProps={{ label: "Linked record type", required: true }}
        />
        <Form.Switch
          reactFormField={{ form, name: "type.link.allowMultiple" }}
          fieldProps={{
            label: "Allow multiple",
            helperText: "Allow users to select multiple linked records?",
          }}
        />
      </>
    ),
    text: () => null,
    number: () => null,
    values: () => (
      <>
        <Form.Switch
          reactFormField={{ form, name: "type.values.allowMultiple" }}
          fieldProps={{
            label: "Allow multiple",
            helperText: "Allow users to select multiple options?",
          }}
        />
        <Form.Options<FieldFormInputs>
          reactFormField={{ form, name: "type.values.options" }}
          fieldProps={{ label: "Options", required: true }}
        />
      </>
    ),
    date: () => null,
    timestamp: () => null,
    boolean: () => null,
    file: () => (
      <Form.Switch
        reactFormField={{ form, name: "type.file.allowMultiple" }}
        fieldProps={{
          label: "Allow multiple",
          helperText: "Allow users to upload multiple files?",
        }}
      />
    ),
    userId: () => (
      <Form.Switch
        reactFormField={{ form, name: "type.userId.allowMultiple" }}
        fieldProps={{
          label: "Allow multiple",
          helperText: "Allow selecting multiple users?",
        }}
      />
    ),
    id: () => null,
    status: () => null,
  });
  if (options === null) {
    return null;
  }
  return (
    <Flex direction="column" gap="2" style={{ gridColumn: "span 2" }}>
      {options}
    </Flex>
  );
};
