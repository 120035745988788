import { Card, Flex, Heading, Inset } from "@radix-ui/themes";
import React, { PropsWithChildren } from "react";

interface DetailCardProps {
  icon?: React.ReactNode;
  title: React.ReactNode;
  /**
   * Actions to be displayed on the right side of the card header.
   * Should typically consist only of IconButtons.
   */
  actions?: React.ReactNode;
}

const DetailCard: React.FC<PropsWithChildren<DetailCardProps>> = ({
  icon,
  title,
  actions,
  children,
}) => {
  return (
    <Card>
      <Inset>
        <Flex direction="column">
          <Flex
            align="center"
            justify="between"
            p="3"
            height="40px"
            style={{
              borderBottom: "1px solid var(--gray-5)",
            }}
          >
            <Flex align="center" gap="1">
              {icon}
              <Heading size="2">{title}</Heading>
            </Flex>
            <Flex align="center" gap="3">
              {actions}
            </Flex>
          </Flex>
          <Flex align="center" p="3">
            {children}
          </Flex>
        </Flex>
      </Inset>
    </Card>
  );
};

export default DetailCard;
