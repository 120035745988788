import PageTitle from "@/components/PageTitle";
import { SearchFilter } from "@/components/SearchFilter";
import WorkflowsTable from "@/components/tables/WorkflowsTable";
import { CreateWorkflowAction } from "@/components/workflows/CreateWorkflowAction";
import { useWorkflows } from "@/state/queries/workflows";
import { Flex } from "@radix-ui/themes";
import { createFileRoute } from "@tanstack/react-router";
import { useState } from "react";

export const Route = createFileRoute(
  "/_app/$tenantSlug/settings/organization/workflows/"
)({
  beforeLoad: () => ({ getTitle: () => "" }),
  component: Workflows,
});

function Workflows() {
  const { tenantSlug } = Route.useParams();
  const { data: workflows } = useWorkflows();
  const [filter, setFilter] = useState("");

  return (
    <Flex direction="column" gap="2">
      <PageTitle
        title="Workflows"
        description="Define workflows to govern each step of work in Meridian."
      />
      <Flex justify="between" align="center" py="2">
        <SearchFilter
          filter={filter}
          setFilter={setFilter}
          placeholder="Search workflows"
        />
        <CreateWorkflowAction />
      </Flex>
      <WorkflowsTable
        workflows={workflows ?? []}
        tenantSlug={tenantSlug}
        filter={filter}
      />
    </Flex>
  );
}
