import { useTenantContext } from "@/contexts/TenantContext";
import { supabase } from "@/supabaseClient";
import { useQuery } from "@tanstack/react-query";

const fetchTenantLogoUrl = async (tenantId: string) => {
  const { data: tenant } = await supabase
    .schema("tenants")
    .from("tenants")
    .select("logo_path")
    .eq("id", tenantId)
    .single();

  if (!tenant?.logo_path) {
    return null;
  }

  const { data } = await supabase.storage
    .from("org_logos")
    .createSignedUrl(tenant.logo_path, 3600);

  return data?.signedUrl ?? null;
};

export const useTenantLogoUrl = () => {
  const { tenant } = useTenantContext();

  return useQuery({
    queryKey: ["tenantLogo", tenant.tenantId],
    queryFn: async () => {
      return fetchTenantLogoUrl(tenant.tenantId);
    },
    staleTime: 1000 * 60 * 55, // Refetch 5 minutes before expiry
    retry: 3,
  });
};
