import { useTenantContext } from "@/contexts/TenantContext";
import { Button, ButtonProps, IconButton, Tooltip } from "@radix-ui/themes";

type Permission =
  | "read.documents"
  | "write.documents"
  | "read.processes"
  | "write.processes"
  | "read.users"
  | "read.design_plans"
  | "write.groups"
  | "write.organization";

interface PermissionedButtonProps extends ButtonProps {
  permission: Permission;
  iconButton?: boolean;
  showDisabled?: boolean;
}

export const PermissionedButton = ({
  permission,
  iconButton,
  showDisabled,
  ...props
}: PermissionedButtonProps) => {
  const { tenant } = useTenantContext();
  const hasPermission = tenant.userPermissions.has(permission);

  const button = iconButton ? (
    <IconButton disabled={!hasPermission} {...props} />
  ) : (
    <Button disabled={!hasPermission} {...props} />
  );

  if (!hasPermission) {
    if (showDisabled) {
      return (
        <Tooltip content="You do not have permission to perform this action.">
          {button}
        </Tooltip>
      );
    } else {
      return null;
    }
  }

  return button;
};
