import NonIdealState from "@/components/NonIdealState";
import { FieldIcon } from "@/components/record-types/RecordFieldTypeLabel";
import { useRecordTypeById } from "@/state/queries/recordLinkTypes";
import { RecordField, RecordFieldBaseType } from "@/types/recordFields";
import { recordFieldTypeToActionFormField } from "@/utils/actions";
import { closestCenter, DndContext, DragEndEvent } from "@dnd-kit/core";
import {
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import {
  Box,
  Button,
  DropdownMenu,
  Flex,
  IconButton,
  ScrollArea,
  Text,
} from "@radix-ui/themes";
import {
  IconArrowLeft,
  IconChevronDown,
  IconGripVertical,
  IconX,
} from "@tabler/icons-react";
import { FormApi, ReactFormApi, useField } from "@tanstack/react-form";
import { useMemo, useState } from "react";
import { useWorkflowEditorContext } from "../WorkflowEditorContext";
import { ActionFormFieldConfiguration } from "./ActionFormFieldConfiguration";
import { ActionConfiguration } from "./EditActionDialogContent";

export const ActionFormTab = ({
  form,
}: {
  form: FormApi<ActionConfiguration> & ReactFormApi<ActionConfiguration>;
}) => {
  const field = useField<ActionConfiguration, "form.fields">({
    form,
    name: "form.fields",
  });
  const [selectedItem, setSelectedItem] = useState<number | undefined>(
    field.state.value.length > 0 ? 0 : undefined
  );

  const operation = form.state.values.operation;

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (over && active.id !== over.id) {
      const oldIndex = parseInt(active.id.toString());
      const newIndex = parseInt(over.id.toString());

      const newFields = [...field.state.value];
      const [movedItem] = newFields.splice(oldIndex, 1);
      newFields.splice(newIndex, 0, movedItem);

      field.setValue(newFields);
      setSelectedItem(newIndex);
    }
  };

  const handleRemoveField = (index: number) => {
    const newFields = [...field.state.value];
    newFields.splice(index, 1);
    field.setValue(newFields);
    setSelectedItem(
      newFields.length > 0 ? Math.min(index, newFields.length - 1) : undefined
    );
  };

  const selectedFieldIds = field.state.value.map((item) => item.recordFieldId);

  const handleAddField = (recordField: RecordField) => {
    field.pushValue({
      id: recordField.id,
      recordFieldId: recordField.id,
      name: recordField.name,
      required: false,
      description: "",
      type: recordFieldTypeToActionFormField(recordField.type, operation),
    });
    setSelectedItem(field.state.value.length - 1);
  };

  return (
    <Flex gap="3" style={{ borderBottom: "var(--line-border)" }}>
      <Flex
        direction="column"
        gap="2"
        pr="1"
        pt="3"
        style={{ borderRight: "var(--line-border)" }}
      >
        <Flex justify="between" align="center" px="2">
          <Text weight="bold">Fields</Text>
          <AddFieldDropdownMenu
            selectedFieldIds={selectedFieldIds}
            onSelect={handleAddField}
          />
        </Flex>
        <ScrollArea
          style={{
            height: 350,
            width: 180,
          }}
        >
          <DndContext
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
          >
            <SortableContext
              items={field.state.value.map((_, i) => i.toString())}
              strategy={verticalListSortingStrategy}
            >
              <Flex direction="column" p="2">
                {field.state.value.map((item, index) => (
                  <SortableFieldItem
                    key={index}
                    item={item}
                    index={index}
                    isSelected={selectedItem === index}
                    onSelect={setSelectedItem}
                    onRemove={handleRemoveField}
                  />
                ))}
              </Flex>
            </SortableContext>
          </DndContext>
        </ScrollArea>
      </Flex>
      <Box flexGrow="1" pt="3">
        <ScrollArea
          style={{
            height: 350,
            paddingLeft: "var(--space-1)",
            paddingRight: "var(--space-2)",
          }}
        >
          {selectedItem === undefined && (
            <NonIdealState
              icon={<IconArrowLeft />}
              title="Add fields"
              description="Start adding fields to capture information from users"
            />
          )}
          {selectedItem !== undefined && (
            <ActionFormFieldConfiguration form={form} index={selectedItem} />
          )}
        </ScrollArea>
      </Box>
    </Flex>
  );
};

const AddFieldDropdownMenu = ({
  selectedFieldIds,
  onSelect,
}: {
  selectedFieldIds: string[];
  onSelect: (recordField: RecordField) => void;
}) => {
  const { recordTypeId } = useWorkflowEditorContext();
  const { data: recordType } = useRecordTypeById(recordTypeId);

  const availableFields = useMemo(() => {
    return (
      recordType?.fields
        .filter((field) => field.origin !== "system")
        .filter((field) => !selectedFieldIds.includes(field.id)) ?? []
    );
  }, [recordType, selectedFieldIds]);

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger>
        <Button type="button" variant="soft" size="1">
          Add <IconChevronDown />
        </Button>
      </DropdownMenu.Trigger>
      <DropdownMenu.Content>
        {availableFields.map((field) => (
          <DropdownMenu.CheckboxItem
            key={field.id}
            onSelect={() => onSelect(field)}
          >
            <FieldIcon type={field.type.type as RecordFieldBaseType} />
            {field.name}
          </DropdownMenu.CheckboxItem>
        ))}
        {availableFields.length === 0 && (
          <DropdownMenu.Item color="gray">
            No fields available
          </DropdownMenu.Item>
        )}
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
};

const SortableFieldItem = ({
  item,
  index,
  isSelected,
  onSelect,
  onRemove,
}: {
  item: { name: string };
  index: number;
  isSelected: boolean;
  onSelect: (index: number) => void;
  onRemove: (index: number) => void;
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id: index.toString(),
    });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    cursor: "pointer",
    backgroundColor: isSelected ? "var(--gray-3)" : "transparent",
    borderRadius: "var(--radius-1)",
  };

  return (
    <Flex ref={setNodeRef} align="center" p="2" gap="2" style={style}>
      <Flex align="center" {...attributes} {...listeners} style={{}}>
        <IconGripVertical style={{ cursor: "grab", color: "var(--gray-9)" }} />
      </Flex>
      <Text size="2" onClick={() => onSelect(index)} style={{ flexGrow: 1 }}>
        {item.name}
      </Text>
      <IconButton
        type="button"
        onClick={() => onRemove(index)}
        variant="ghost"
        color="gray"
        size="1"
      >
        <IconX />
      </IconButton>
    </Flex>
  );
};
