import { useAuth } from "@/contexts/AuthContext";
import { useTenantContext } from "@/contexts/TenantContext";
import { useTenants } from "@/state/queries/tenants";
import { useUser } from "@/state/queries/user";
import {
  Avatar,
  Box,
  DropdownMenu,
  Flex,
  Heading,
  Inset,
  Separator,
  Text,
} from "@radix-ui/themes";
import { IconLogout, IconSettings, IconUserCircle } from "@tabler/icons-react";
import { Link, useNavigate } from "@tanstack/react-router";
import React from "react";
import SidebarButton from "./SidebarButton";

const UserMenu: React.FC = () => {
  const { tenant } = useTenantContext();
  const { data: user } = useUser();
  const { logout } = useAuth();
  const { refetch: refetchTenants } = useTenants();
  const navigate = useNavigate();

  const handleLogout = async () => {
    await logout();
    await refetchTenants();
    navigate({ to: "/login" });
  };

  const email = user?.email;
  const name = user?.name;
  const fallback = name?.[0] ?? email?.[0] ?? "U";

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger>
        <SidebarButton icon={<IconUserCircle />} label={name ?? ""} />
      </DropdownMenu.Trigger>
      <DropdownMenu.Content side="right">
        <Flex gap="4">
          <Avatar size="3" radius="full" fallback={fallback} />
          <Box>
            <Heading size="3" as="h3">
              {name}
            </Heading>
            <Text as="div" size="2" color="gray" mb="2">
              {email}
            </Text>
            <Text as="div" size="2"></Text>
          </Box>
        </Flex>
        <Inset side="x" mx="-2">
          <Separator orientation="horizontal" size="4" mb="2" />
        </Inset>
        <DropdownMenu.Item asChild>
          <Link
            to="/$tenantSlug/settings/account"
            params={{ tenantSlug: tenant.slug }}
          >
            <IconSettings /> Settings
          </Link>
        </DropdownMenu.Item>
        <DropdownMenu.Item onClick={handleLogout}>
          <IconLogout />
          Log out
        </DropdownMenu.Item>
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
};

export default UserMenu;
