import { useTenantGroupWithUserData } from "@/state/queries/tenantGroups";
import { Skeleton, Text, TextProps } from "@radix-ui/themes";
import React from "react";

type GroupNameProps = TextProps & {
  groupId: string;
};

const GroupName: React.FC<GroupNameProps> = ({ groupId, ...props }) => {
  const { data, isLoading } = useTenantGroupWithUserData(groupId);

  const displayName = data?.name ?? "Unknown Group";

  return (
    <Skeleton loading={isLoading}>
      <Text {...props}>{displayName}</Text>
    </Skeleton>
  );
};

export default GroupName;
