import { Button, Flex, IconButton, TextField } from "@radix-ui/themes";
import { IconPlus, IconX } from "@tabler/icons-react";
import { DeepKeys, useField } from "@tanstack/react-form";
import { PropsWithChildren } from "react";
import { FormField } from "./FormField";
import { BaseFieldProps } from "./types";

export interface BaseOptionsProps<TData> extends BaseFieldProps<TData> {}

export function BaseOptions<TData>(
  props: PropsWithChildren<BaseOptionsProps<TData>>
) {
  const { reactFormField, fieldProps } = props;

  const field = useField<TData, DeepKeys<TData>>(reactFormField);

  const values = field.state.value as { value: string }[];

  const addOption = () => {
    field.handleChange([...values, { value: "New option" }] as any);
  };

  const updateOption = (index: number, newValue: string) => {
    field.handleChange(
      values.map((v) => (v === values[index] ? { value: newValue } : v)) as any
    );
  };

  const removeOption = (index: number) => {
    field.handleChange(values.filter((v) => v !== values[index]) as any);
  };

  return (
    <FormField
      reactForm={reactFormField.form}
      reactFormField={field}
      labelChildren={
        <Button type="button" size="1" onClick={addOption}>
          <IconPlus />
          Add option
        </Button>
      }
      {...fieldProps}
    >
      <Flex direction="column" py="2" gap="2">
        {values.map((value, index) => (
          <Option
            index={index}
            value={value.value}
            onChange={(newValue) => updateOption(index, newValue)}
            onRemove={removeOption}
          />
        ))}
      </Flex>
    </FormField>
  );
}

const Option: React.FC<{
  index: number;
  value: string;
  onChange: (value: string) => void;
  onRemove: (index: number) => void;
}> = ({ index, value, onChange, onRemove }) => {
  return (
    <Flex align="center" gap="2" key={index}>
      <TextField.Root
        size="2"
        value={value}
        placeholder="Enter option"
        onChange={(e) => onChange(e.target.value)}
        style={{ flexGrow: 1 }}
      />
      <IconButton
        type="button"
        onClick={() => onRemove(index)}
        variant="ghost"
        color="gray"
        size="1"
      >
        <IconX />
      </IconButton>
    </Flex>
  );
};
