import { TextField } from "@radix-ui/themes";
import { IconSearch } from "@tabler/icons-react";

export interface SearchFilterProps extends TextField.RootProps {
  filter: string;
  setFilter: (filter: string) => void;
  placeholder?: string;
}

export const SearchFilter = ({
  filter,
  setFilter,
  ...props
}: SearchFilterProps) => {
  return (
    <TextField.Root
      value={filter}
      onChange={(e) => setFilter(e.target.value)}
      placeholder="Search..."
      style={{ width: "300px" }}
      {...props}
    >
      <TextField.Slot>
        <IconSearch />
      </TextField.Slot>
    </TextField.Root>
  );
};
