import { useTenantContext } from "@/contexts/TenantContext";
import { supabase } from "@/supabaseClient";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export interface UpdateRecordTypeTitleFieldRequest {
  tenantId: string;
  recordTypeId: string;
  titleFieldId: string;
}

const updateRecordTypeTitleField = async (
  input: UpdateRecordTypeTitleFieldRequest
) => {
  const { error } = await supabase
    .from("record_types")
    .update({
      title_field_id: input.titleFieldId,
    })
    .eq("id", input.recordTypeId)
    .eq("tenant_id", input.tenantId);

  if (error) {
    throw error;
  }
};

export const useUpdateRecordTypeTitleField = () => {
  const queryClient = useQueryClient();
  const tenant = useTenantContext();

  return useMutation({
    mutationFn: ({
      recordTypeId,
      titleFieldId,
    }: {
      recordTypeId: string;
      titleFieldId: string;
    }) => {
      if (!tenant.tenant) {
        throw new Error("Tenant not found");
      }
      return updateRecordTypeTitleField({
        recordTypeId,
        titleFieldId,
        tenantId: tenant.tenant.tenantId,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["recordAndLinkTypes", tenant.tenant?.tenantId],
      });
    },
  });
};
