import { useTenantContext } from "@/contexts/TenantContext";
import { supabase } from "@/supabaseClient";
import { useMutation, useQueryClient } from "@tanstack/react-query";

interface CreateGroupInput {
  name: string;
  members: string[];
}

const createGroup = async (input: CreateGroupInput, tenantId: string) => {
  const { data, error } = await supabase
    .schema("tenants")
    .from("tenant_groups")
    .insert({
      tenant_id: tenantId,
      name: input.name,
    })
    .select("id")
    .single();

  if (!data) {
    return;
  }

  if (error) {
    throw error;
  }

  await supabase
    .schema("tenants")
    .from("tenant_group_members")
    .insert(
      input.members.map((member) => ({
        group_id: data.id,
        tenant_id: tenantId,
        user_id: member,
      }))
    );
};

export const useCreateGroup = () => {
  const queryClient = useQueryClient();
  const tenant = useTenantContext();

  return useMutation({
    mutationFn: (input: CreateGroupInput) => {
      if (!tenant.tenant) {
        throw new Error("Tenant not found");
      }
      return createGroup(input, tenant.tenant.tenantId);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["tenantGroups", tenant.tenant?.tenantId],
      });
      queryClient.invalidateQueries({
        queryKey: ["userGroups", tenant.tenant?.tenantId],
      });
    },
  });
};
