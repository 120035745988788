import { KeyedTypeUnion, createTypeUnion } from "keyed-type-union";

export interface TextRecordField {
  formatting?: {
    multiline?: boolean;
  };
}

export interface NumberRecordField {
  constraints?: {
    integer?: boolean;
    min?: number;
    max?: number;
  };
}

export interface ValuesRecordField {
  options: {
    value: string;
  }[];
  allowMultiple?: boolean;
}

export interface StatusRecordField {
  statuses: {
    value: string;
  }[];
}

export interface LinkedRecordField {
  linkedRecordTypeId: string;
  allowMultiple?: boolean;
}

export interface FileRecordField {
  allowMultiple?: boolean;
}

export interface UserIdRecordField {
  allowMultiple?: boolean;
}

export type RecordFieldType = KeyedTypeUnion<{
  id: {};
  text: TextRecordField;
  number: NumberRecordField;
  values: ValuesRecordField;
  status: StatusRecordField;
  link: LinkedRecordField;
  date: {};
  timestamp: {};
  boolean: {};
  file: FileRecordField;
  userId: UserIdRecordField;
}>;
export type RecordFieldBaseType = RecordFieldType["type"];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RecordFieldType = createTypeUnion<RecordFieldType>({
  id: undefined,
  text: undefined,
  number: undefined,
  values: undefined,
  status: undefined,
  link: undefined,
  date: undefined,
  timestamp: undefined,
  boolean: undefined,
  file: undefined,
  userId: undefined,
});

export const recordFieldBaseTypeToFieldType: {
  [type in RecordFieldBaseType]: RecordFieldType;
} = {
  id: RecordFieldType.id({}),
  text: RecordFieldType.text({}),
  number: RecordFieldType.number({}),
  date: RecordFieldType.date({}),
  timestamp: RecordFieldType.timestamp({}),
  boolean: RecordFieldType.boolean({}),
  link: RecordFieldType.link({
    linkedRecordTypeId: "",
  }),
  file: RecordFieldType.file({}),
  values: RecordFieldType.values({
    options: [],
  }),
  status: RecordFieldType.status({
    statuses: [],
  }),
  userId: RecordFieldType.userId({}),
};

export type RecordFieldOrigin = "system" | "custom";

export interface RecordField {
  id: string;

  name: string;

  description: string;

  /**
   * Whether the field was created automatically by the system or by a user.
   * Defaults to "custom".
   */
  origin?: RecordFieldOrigin;

  type: RecordFieldType;
}
