import { useDeleteView } from "@/state/mutations/views/deleteView";
import { AlertDialog, Button, Flex, Text } from "@radix-ui/themes";
import { FormError } from "../forms/FormError";
import { useViewContext } from "./ViewContext";

export const DeleteViewDialog: React.FC<{
  onSuccess?: () => void;
  open: boolean;
  onOpenChange: (open: boolean) => void;
}> = ({ onSuccess, open, onOpenChange }) => {
  const { currentViewConfig } = useViewContext();
  const { mutate: deleteView, isPending, error, reset } = useDeleteView();

  const onSubmit = () => {
    deleteView(
      { id: currentViewConfig.id },
      {
        onSuccess: () => {
          onOpenChange(false);
          onSuccess?.();
        },
      }
    );
  };

  const handleOpenChange = (open: boolean) => {
    onOpenChange(open);
    if (!open) {
      reset();
    }
  };

  return (
    <AlertDialog.Root open={open} onOpenChange={handleOpenChange}>
      <AlertDialog.Content maxWidth="450px">
        <AlertDialog.Title>Delete view</AlertDialog.Title>
        <AlertDialog.Description>
          <Flex direction="column" gap="2">
            <Text>
              Are you sure? This view will be permanently deleted. The records
              in this view will not be affected.
            </Text>
            {error && <FormError message={error.message} />}
          </Flex>
        </AlertDialog.Description>

        <Flex gap="3" mt="4" justify="end">
          <AlertDialog.Cancel>
            <Button variant="soft" color="gray">
              Cancel
            </Button>
          </AlertDialog.Cancel>
          <Button color="red" loading={isPending} onClick={onSubmit}>
            Delete view
          </Button>
        </Flex>
      </AlertDialog.Content>
    </AlertDialog.Root>
  );
};
