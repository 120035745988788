import { PermissionedButton } from "@/components/PermissionedButton";
import { useDeleteRecordFields } from "@/state/mutations/record-types/deleteRecordFields";
import { RecordField } from "@/types/recordFields";
import { AlertDialog, Button, Flex, Text } from "@radix-ui/themes";
import { IconTrash } from "@tabler/icons-react";
import { useState } from "react";
import { FormError } from "../forms/FormError";

export const DeleteFieldsAction: React.FC<{
  recordTypeId: string;
  fields: RecordField[];
  onDelete?: () => void;
}> = ({ recordTypeId, fields, onDelete }) => {
  const [open, setOpen] = useState(false);
  const {
    mutate: deleteRecordFields,
    isPending,
    error,
    reset,
  } = useDeleteRecordFields();

  const onSubmit = () => {
    deleteRecordFields(
      {
        recordTypeId,
        fieldIds: fields.map((field) => field.id),
      },
      {
        onSuccess: () => {
          onDelete?.();
          setOpen(false);
        },
      }
    );
  };

  return (
    <AlertDialog.Root
      open={open}
      onOpenChange={(isOpen) => {
        setOpen(isOpen);
        if (!isOpen) reset();
      }}
    >
      <AlertDialog.Trigger>
        <PermissionedButton
          permission="write.processes"
          variant="soft"
          color="gray"
        >
          <IconTrash />
          Delete {fields.length > 1 ? fields.length : ""} field
          {fields.length === 1 ? "" : "s"}
        </PermissionedButton>
      </AlertDialog.Trigger>
      <AlertDialog.Content maxWidth="450px">
        <AlertDialog.Title>Delete fields</AlertDialog.Title>
        <AlertDialog.Description>
          <Flex direction="column" gap="2">
            <Text>
              Are you sure? These fields will be permanently deleted from this
              record type.
            </Text>
            {error && <FormError message={error.message} />}
          </Flex>
        </AlertDialog.Description>

        <Flex gap="3" mt="4" justify="end">
          <AlertDialog.Cancel>
            <Button variant="soft" color="gray">
              Cancel
            </Button>
          </AlertDialog.Cancel>
          <Button color="red" onClick={onSubmit} loading={isPending}>
            Delete
          </Button>
        </Flex>
      </AlertDialog.Content>
    </AlertDialog.Root>
  );
};
