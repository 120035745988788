import { RecordType } from "@/types/recordTypes";
import {
  RecordFilter,
  RecordViewConfig,
  TableViewConfig,
  ViewType,
} from "@/types/recordViews";
import { isEqual } from "lodash-es";
import React, { useEffect, useMemo } from "react";

const ViewContext = React.createContext<{
  recordType: RecordType;
  currentViewConfig: RecordViewConfig;
  viewConfigHasChanged: boolean;
  resetChanges: () => void;
  setDefaultFilter: (filter?: RecordFilter) => void;
  tableViewConfig: TableViewConfig;
  setTableViewConfig: (config: TableViewConfig) => void;
}>({
  recordType: {} as RecordType,
  currentViewConfig: {} as RecordViewConfig,
  viewConfigHasChanged: false,
  resetChanges: () => {},
  setDefaultFilter: () => {},
  tableViewConfig: {} as TableViewConfig,
  setTableViewConfig: () => {},
});

export const ViewContextProvider: React.FC<{
  children: React.ReactNode;
  initialViewConfig: RecordViewConfig;
  recordType: RecordType;
}> = ({ children, initialViewConfig, recordType }) => {
  const [currentViewConfig, setCurrentViewConfig] =
    React.useState(initialViewConfig);

  useEffect(() => {
    setCurrentViewConfig(initialViewConfig);
  }, [initialViewConfig]);

  const resetChanges = () => {
    setCurrentViewConfig(initialViewConfig);
  };

  const tableViewConfig = useMemo(() => {
    return currentViewConfig.viewType.table;
  }, [currentViewConfig]);

  const viewConfigHasChanged = useMemo(() => {
    return !isEqual(initialViewConfig, currentViewConfig);
  }, [initialViewConfig, currentViewConfig]);

  const setDefaultFilter = (filter?: RecordFilter) => {
    setCurrentViewConfig((currentViewConfig) => ({
      ...currentViewConfig,
      defaultFilter: filter,
    }));
  };

  const setTableViewConfig = (config: TableViewConfig) => {
    setCurrentViewConfig((currentViewConfig) => ({
      ...currentViewConfig,
      viewType: ViewType.table(config),
    }));
  };

  return (
    <ViewContext.Provider
      value={{
        recordType,
        currentViewConfig,
        resetChanges,
        tableViewConfig,
        viewConfigHasChanged,
        setDefaultFilter,
        setTableViewConfig,
      }}
    >
      {children}
    </ViewContext.Provider>
  );
};

export const useViewContext = () => {
  const context = React.useContext(ViewContext);
  if (!context) {
    throw new Error("useView must be used within a ViewProvider");
  }
  return context;
};
