import { ActionPermissions, ActionPermissionsType } from "@/types/actions";
import { Flex, RadioCards, Text } from "@radix-ui/themes";
import { DeepKeys, FieldApi, useField } from "@tanstack/react-form";
import { FormField } from "../../../forms/FormField";
import { BaseFieldProps } from "../../../forms/types";
import { ActionConfiguration } from "../EditActionDialogContent";

export interface ActionPermissionsTypeSelectProps
  extends BaseFieldProps<ActionConfiguration> {}

interface ActionPermissionsItem {
  label: string;
  description: string;
  value: ActionPermissions;
}
const actionsPermissionsItems: {
  [type in ActionPermissionsType]: ActionPermissionsItem;
} = {
  noFilter: {
    label: "No Filter",
    description: "Allow all users to execute this action",
    value: ActionPermissions.noFilter({}),
  },
  userFilter: {
    label: "User Filter",
    description: "Limit action execution to specific users",
    value: ActionPermissions.userFilter({ userIds: [] }),
  },
  groupFilter: {
    label: "Group Filter",
    description: "Limit action execution to specific groups",
    value: ActionPermissions.groupFilter({ groupIds: [] }),
  },
  // TODO Add this in the future
  // formulaFilter: {
  //   label: "Formula (Advanced)",
  //   description: "Limit action execution using a formula",
  //   value: ActionPermissions.formulaFilter({ formula: "" }),
  // },
};

export function ActionPermissionsTypeSelect(
  props: ActionPermissionsTypeSelectProps
) {
  const { reactFormField, fieldProps } = props;
  // TODO: Clean this up
  const field = useField<ActionConfiguration, DeepKeys<ActionConfiguration>>(
    reactFormField
  ) as FieldApi<ActionConfiguration, "permissions">;

  return (
    <FormField
      reactForm={reactFormField.form}
      reactFormField={field}
      {...fieldProps}
    >
      <RadioCards.Root
        columns="3"
        defaultValue="noFilter"
        value={field.state.value.type}
        onValueChange={(value) => {
          field.handleChange(
            actionsPermissionsItems[value as ActionPermissionsType].value
          );
        }}
      >
        {Object.entries(actionsPermissionsItems).map(([type, item]) => (
          <RadioCards.Item key={type} value={type}>
            <Flex direction="column" width="100%">
              <Text weight="bold">{item.label}</Text>
              <Text size="1">{item.description}</Text>
            </Flex>
          </RadioCards.Item>
        ))}
      </RadioCards.Root>
    </FormField>
  );
}
