import {
  FormFieldLabel,
  FormFieldWrapper,
  FormHelperText,
} from "@/components/forms/FormField";
import PageTitle from "@/components/PageTitle";
import { PermissionedButton } from "@/components/PermissionedButton";
import { useTenantContext } from "@/contexts/TenantContext";
import { useUpdateOrgLogo } from "@/state/mutations/organization/updateOrgLogo";
import { useUpdateOrgName } from "@/state/mutations/organization/updateOrgName";
import { useTenantLogoUrl } from "@/state/queries/tenantLogo";
import { Flex, IconButton, Skeleton, Text, TextField } from "@radix-ui/themes";
import {
  IconBuilding,
  IconCheck,
  IconCloudUpload,
  IconLink,
  IconPencil,
  IconX,
} from "@tabler/icons-react";
import { createFileRoute } from "@tanstack/react-router";
import { useCallback, useRef, useState } from "react";

export const Route = createFileRoute(
  "/_app/$tenantSlug/settings/organization/general"
)({
  beforeLoad: () => ({ getTitle: () => "General" }),
  component: OrgGeneralSettings,
});

function OrgGeneralSettings() {
  return (
    <Flex direction="column" gap="4">
      <PageTitle
        title="General"
        description="Change the general settings for your organization in Meridian."
      />
      <Flex direction="column" gap="4" maxWidth="500px">
        <OrgName />
        <OrgLogo />
        <OrgURL />
      </Flex>
    </Flex>
  );
}

function OrgName() {
  const { tenant } = useTenantContext();
  const { mutate: updateOrgName } = useUpdateOrgName();

  const [isEditing, setIsEditing] = useState(false);
  const [name, setName] = useState(tenant.name);

  const handleClickCancel = () => {
    setName(tenant.name);
    setIsEditing(false);
  };

  // Focus the text field when we enter edit mode
  const textFieldRef = useCallback(
    (node: HTMLInputElement) => {
      if (node && isEditing) {
        node.focus();
      }
    },
    [isEditing]
  );

  const handleClick = () => {
    if (!isEditing) {
      setIsEditing(true);
    } else {
      updateOrgName({ name });
      setIsEditing(false);
    }
  };

  return (
    <FormFieldWrapper>
      <FormFieldLabel name="name" label="Organization Name">
        {isEditing && (
          <IconButton
            variant="ghost"
            color="gray"
            size="2"
            onClick={handleClickCancel}
          >
            <IconX />
          </IconButton>
        )}
        <PermissionedButton
          permission="write.organization"
          iconButton
          variant="ghost"
          color={isEditing ? "green" : "gray"}
          size="2"
          onClick={handleClick}
        >
          {isEditing ? <IconCheck /> : <IconPencil />}
        </PermissionedButton>
      </FormFieldLabel>
      <FormHelperText>
        Shown in the sidebar and may be used in templated documents.
      </FormHelperText>
      <TextField.Root
        ref={textFieldRef}
        value={name}
        onChange={(e) => setName(e.target.value)}
        disabled={!isEditing}
      >
        <TextField.Slot>
          <IconBuilding />
        </TextField.Slot>
      </TextField.Root>
    </FormFieldWrapper>
  );
}

function OrgLogo() {
  const { data: logoUrl, isLoading: isLogoLoading } = useTenantLogoUrl();
  const { mutate: updateLogo, isPending } = useUpdateOrgLogo();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      updateLogo(file);
    }
  };

  const handleClick = () => {
    fileInputRef.current?.click();
  };

  return (
    <FormFieldWrapper>
      <FormFieldLabel name="logo" label="Logo" />
      <FormHelperText>
        Displayed in the sidebar and may be used to create templated documents.
      </FormHelperText>
      <Flex align="center" gap="4">
        {isLogoLoading ? (
          <Skeleton style={{ width: "36px", height: "36px" }} />
        ) : logoUrl ? (
          <img
            src={logoUrl}
            alt="Organization Logo"
            style={{ width: "36px", height: "36px" }}
          />
        ) : (
          <Text color="gray">No logo uploaded yet</Text>
        )}
        <PermissionedButton
          permission="write.organization"
          variant="soft"
          size="2"
          onClick={handleClick}
          loading={isPending}
        >
          <IconCloudUpload />
          {isPending ? "Uploading..." : "Upload logo"}
        </PermissionedButton>
        <input
          type="file"
          ref={fileInputRef}
          onChange={handleFileChange}
          accept="image/*"
          hidden
        />
      </Flex>
    </FormFieldWrapper>
  );
}

function OrgURL() {
  const { tenant } = useTenantContext();
  return (
    <FormFieldWrapper>
      <FormFieldLabel name="slug" label="URL" />
      <FormHelperText>
        Please contact Meridian support to change your organization URL.
      </FormHelperText>
      <TextField.Root value={tenant.slug} disabled>
        <TextField.Slot>
          <IconLink /> https://app.meridian.tech/
        </TextField.Slot>
      </TextField.Root>
    </FormFieldWrapper>
  );
}
