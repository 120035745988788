import { useTenantContext } from "@/contexts/TenantContext";
import { Json } from "@/database/generated.types";
import { supabase } from "@/supabaseClient";
import { SYSTEM_RECORD_FIELD_IDS } from "@/types/recordFieldConstants";
import { RecordType } from "@/types/recordTypes";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export interface CreateViewInput {
  name: string;
  recordType: RecordType;
}

const createView = async (input: CreateViewInput, tenantId: string) => {
  const columns = [
    // Always include status as first field
    { fieldId: "status" },
    // Include all other fields, excluding system fields
    ...input.recordType.fields
      .map((field) => ({
        fieldId: field.id,
      }))
      .filter((field) => !SYSTEM_RECORD_FIELD_IDS.includes(field.fieldId)),
  ];

  const { data, error } = await supabase
    .from("record_views")
    .insert({
      name: input.name,
      record_type_id: input.recordType.id,
      tenant_id: tenantId,
      view_type: {
        table: { columns },
      } as unknown as Json,
    })
    .select("id")
    .single();

  if (error) {
    throw error;
  }

  return data;
};

export const useCreateView = () => {
  const queryClient = useQueryClient();
  const tenant = useTenantContext();

  return useMutation({
    mutationFn: (input: CreateViewInput) => {
      if (!tenant.tenant) {
        throw new Error("Tenant not found");
      }
      return createView(input, tenant.tenant.tenantId);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["recordViews", tenant.tenant?.tenantId],
      });
    },
  });
};
