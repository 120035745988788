import { ChangePasswordAction } from "@/components/ChangePasswordAction";
import {
  FormFieldLabel,
  FormFieldWrapper,
  FormHelperText,
} from "@/components/forms/FormField";
import PageTitle from "@/components/PageTitle";
import { useChangeName } from "@/state/mutations/profiles/changeName";
import { useUser } from "@/state/queries/user";
import { Flex, IconButton, TextField } from "@radix-ui/themes";
import {
  IconCheck,
  IconLock,
  IconMail,
  IconPencil,
  IconUser,
  IconX,
} from "@tabler/icons-react";
import { createFileRoute } from "@tanstack/react-router";
import { useCallback, useState } from "react";

export const Route = createFileRoute(
  "/_app/$tenantSlug/settings/account/profile"
)({
  beforeLoad: () => ({ getTitle: () => "Profile" }),
  component: () => <ProfilePage />,
});

function ProfilePage() {
  return (
    <Flex direction="column" gap="4">
      <PageTitle
        title="Profile"
        description="Change the profile settings for your account in Meridian."
      />
      <Flex direction="column" gap="4" maxWidth="500px">
        <UserEmail />
        <UserPassword />
        <UserName />
      </Flex>
    </Flex>
  );
}

function UserName() {
  const { data: user } = useUser();
  const { mutate: updateName } = useChangeName();

  const [isEditing, setIsEditing] = useState(false);
  const [name, setName] = useState(user?.name);

  const handleClickCancel = () => {
    setName(user?.name);
    setIsEditing(false);
  };

  // Focus the text field when we enter edit mode
  const textFieldRef = useCallback(
    (node: HTMLInputElement) => {
      if (node && isEditing) {
        node.focus();
      }
    },
    [isEditing]
  );

  const handleClick = () => {
    if (!isEditing) {
      setIsEditing(true);
    } else if (user) {
      updateName({
        userId: user.id,
        fullName: name,
      });
      setIsEditing(false);
    }
  };

  return (
    <FormFieldWrapper>
      <FormFieldLabel name="name" label="Full Name">
        {isEditing && (
          <IconButton
            variant="ghost"
            color="gray"
            size="2"
            onClick={handleClickCancel}
          >
            <IconX />
          </IconButton>
        )}
        <IconButton
          variant="ghost"
          color={isEditing ? "green" : "gray"}
          size="2"
          onClick={handleClick}
        >
          {isEditing ? <IconCheck /> : <IconPencil />}
        </IconButton>
      </FormFieldLabel>
      <FormHelperText>
        Shown throughout Meridian to help your coworkers identify you.
      </FormHelperText>
      <TextField.Root
        ref={textFieldRef}
        value={name}
        onChange={(e) => setName(e.target.value)}
        disabled={!isEditing}
      >
        <TextField.Slot>
          <IconUser />
        </TextField.Slot>
      </TextField.Root>
    </FormFieldWrapper>
  );
}

function UserEmail() {
  const { data: user } = useUser();
  return (
    <FormFieldWrapper>
      <FormFieldLabel name="email" label="Email" />
      <FormHelperText>
        Your email address is used to log in to Meridian.
      </FormHelperText>
      <TextField.Root value={user?.email} disabled>
        <TextField.Slot>
          <IconMail />
        </TextField.Slot>
      </TextField.Root>
    </FormFieldWrapper>
  );
}

function UserPassword() {
  return (
    <FormFieldWrapper>
      <FormFieldLabel name="password" label="Password">
        <ChangePasswordAction>
          <IconButton variant="ghost" color="gray" size="2">
            <IconPencil />
          </IconButton>
        </ChangePasswordAction>
      </FormFieldLabel>
      <TextField.Root type="password" value="placeholder" disabled>
        <TextField.Slot>
          <IconLock />
        </TextField.Slot>
      </TextField.Root>
    </FormFieldWrapper>
  );
}
