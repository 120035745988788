import { createFileRoute, redirect } from "@tanstack/react-router";

export const Route = createFileRoute(
  "/_app/$tenantSlug/settings/organization/members/"
)({
  beforeLoad: ({ params }) => {
    throw redirect({
      to: `/${params.tenantSlug}/settings/organization/members/users`,
      replace: true,
    });
  },
});
